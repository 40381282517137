import { AppStaticURL } from '@app/app.settings';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { DevicesClass, DeviceTypes } from '@app/core/classes';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BreadcrumbsDataModel } from '@app/core/models/breadcrumbs';
import { RoutingConfig } from '@app/routing/routing.config';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DialogService } from 'primeng/dynamicdialog';
import { DialogIncludesTypes, DialogComponentModel } from '@app/core/models/diallog';
import { NotificationService } from '@app/modules/notification-modules/notification.service';
import { EmailStringValidate, PhoneNumberValidate } from '@app/utils/validator';
import { AuthService } from '@app/modules/auth-modules/auth.service';
import { AccountService } from '@app/modules/account-modules/account.service';
import { AccountStoryService } from '@app/modules/account-modules/account.story';
import { InstrumentsModel, PositionModel } from '@app/modules/fundamentals-modules/fundamentals.models';
import { SignUpModel } from '@app/modules/auth-modules/auth.model';
import { OAuthService, OAuthTypes } from '@app/modules/auth-modules/oauth.service';
import { CitiesService } from '@app/modules/cities-module/cities.service';
import { CityModel } from '@app/modules/fundamentals-modules/fundamentals.models';
import { AuthEventBus, AuthStoryService } from '@app/modules/auth-modules/auth.story';
import { ReplaySubject } from 'rxjs';
import { FileToBase64, ObjectRequestFormatted } from '@app/utils/converter';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import * as moment from 'moment';
import { _deviceType } from '@app/utils/device';
import * as $ from 'jquery';

/**
 * Components
 */
import { SearchCityComponent } from '@app/modules/cities-module/search-city/search-city.component';
import { SearchActivityComponent } from '@app/modules/activity-modules/search-activity/search-activity.component';

export interface BaseErrorModel {
  status: boolean;
  value: string;
}
export interface SignUpErrorModels {
  username: BaseErrorModel;
  email: BaseErrorModel;
  firstName: BaseErrorModel;
  cityId: BaseErrorModel;
  instrumentIds: BaseErrorModel;
  phone: BaseErrorModel;
  birthday: BaseErrorModel;
  password: BaseErrorModel;
}

@UntilDestroy()
@Component({
  selector: 'app-sign-up',
  templateUrl: 'sign-up.component.html',
  styleUrls: [`./sign-up.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SignUpComponent extends DevicesClass implements OnInit {
  public Routing = RoutingConfig;
  public OAuthTypes = OAuthTypes;
  public BreadcrumbsData: BreadcrumbsDataModel = [
    {
      title: 'Регистрация',
      url: `/${RoutingConfig.Login}/${RoutingConfig.SignUp}`,
      active: true
    }
  ];

  /**
   * Base component variables.
   */
  public SignUpForm: FormGroup;
  public SignUpLoading: boolean;
  public SignUpAvatar: string;
  public SignUpUsername: boolean;
  public SignUpExternal: boolean;
  public SignUpCitySubject: ReplaySubject<CityModel> = new ReplaySubject<CityModel>(1);
  public SignUpCityValue: CityModel = null;
  public SignUpActivitySubject: ReplaySubject<any> = new ReplaySubject<any>(1);
  public SignUpActivityPosition: PositionModel = null;
  public SignUpActivityInstruments: InstrumentsModel = [];

  /**
   * Errors view variables
   */
  public ErrorsField: SignUpErrorModels;
  public ErrorsText: string;

  /**
   * Special component variables.
   */
  public fileAcceptString = 'image/x-png,image/gif,image/jpeg';

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    public oauthService: OAuthService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private citiesService: CitiesService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private accountService: AccountService,
    private accountStory: AccountStoryService,
    private authService: AuthService,
    private authStory: AuthStoryService,
    private notificationService: NotificationService,
    private primengConfig: PrimeNGConfig,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;

    /**
     * Build SignUp form.
     */
    this.SignUpForm = this.formBuilder.group({
      avatarPath: [''],
      oauthId: [''],
      positionId: [0, Validators.required],
      cityId: ['', Validators.required],
      instrumentIds: [[], Validators.required],
      firstName: ['', Validators.required],
      lastName: [''],
      username: ['', [Validators.required, Validators.minLength(5)]],
      email: ['', Validators.compose([
        Validators.required, Validators.pattern(EmailStringValidate())
      ])],
      phone: [''],
      password: ['', Validators.required],
      password_repeat: [''],
      processing: [false, Validators.required],
      birthday: [''],
    });

    /**
     * Set of default errors value.
     */
    this.ErrorsField = {
      username: {status: false, value: ''},
      email: {status: false, value: ''},
      firstName: {status: false, value: ''},
      cityId: {status: false, value: ''},
      instrumentIds: {status: false, value: ''},
      phone: {status: false, value: ''},
      birthday: {status: false, value: ''},
      password: {status: false, value: ''}
    };

    /**
     * Form changes control.
     */
    this.onSignUpCtrl();
    this.onSignUpOAuthCtrl();
    this.onCityCtrl();
    this.onActivityCtrl();

    /**
     * Auth Story Bus listener.
     */
    this.onAuthStoryBus();
  }

  onSignUpLoading(status: boolean, delay: number = 0) {
    setTimeout(() => {
      this.SignUpLoading = status;
      this.cdr.detectChanges();
    }, delay);
  }

  onAuthStoryBus(): void {
    this.authStory.AuthEventBus$.pipe(untilDestroyed(this)).subscribe(
      emit => this.onAuthStoryListener(emit)
    );
  }

  onAuthStoryListener(emit: AuthEventBus): void {
    if (emit.event === 'onOAuthSignIn') {
      this.authService.getToken(emit.data.token).subscribe(
        response => {
          this.authService.SignInExternal(response);

          /**
           * Getting of Account User model.
           */
          this.accountService.onIniGetMyInfo();

          /**
           * Redirect URL.
           */
          this.router.navigateByUrl(
            this.route.snapshot.queryParams['redirectUrl'] || RoutingConfig.Root
          ).catch();
        },
        error => {
          this.messageService.add({
            key: 'signUpToast',
            severity:'error',
            summary: 'Ошибка',
            detail: 'Не удалось выполнить вход через социальные сети.',
            life: 30000000
          });
        }
      );
    }

    if (emit.event === 'onOAuthSignUp') {
      this.onSignUpOAuthApply(emit.data);
    }

    if (emit.event === 'onOAuthSignError') {
      this.messageService.add({
        key: 'signUpToast',
        severity:'error',
        summary: 'Ошибка',
        detail: 'Не удалось выполнить вход через социальные сети.',
        life: 30000000
      });
    }
  }

  onSignUpCtrl(): void {
    for (const key of Object.keys(this.ErrorsField)) {
      if (this.SignUpForm.controls.hasOwnProperty(key)) {
        this.SignUpForm.controls[key].valueChanges.pipe(untilDestroyed(this)).subscribe(
          value => {
            this.ErrorsField[key].status = false;
            this.ErrorsText = '';
            this.onToggleViewErrors(false);
          }
        )
      }
    }
    this.SignUpForm.controls.processing.valueChanges.pipe(untilDestroyed(this)).subscribe(
      value => {
        this.ErrorsText = '';
        this.onToggleViewErrors(false);
      }
    )
  }

  onSignUpOAuthCtrl(): void {
    this.route.queryParamMap.subscribe((params: any) => {
      this.onSignUpOAuthApply(params.params || {});
    });
  }

  onSignUpOAuthApply(response: any): void {
    for (const key of Object.keys(response)) {
      if (key === 'OauthId') {
        this.SignUpExternal = true;
        this.SignUpForm.controls.oauthId.setValue(response[key]);
      }
      if (key === 'AvatarPath') {
        this.SignUpForm.controls.avatarPath.setValue(response[key]);
        this.SignUpAvatar = (AppStaticURL + response[key]);
      }
      if (key === 'PositionId') {
        this.SignUpForm.controls.positionId.setValue(response[key]);
      }
      if (key === 'FirstName') {
        this.SignUpForm.controls.firstName.setValue(response[key]);
      }
      if (key === 'LastName') {
        this.SignUpForm.controls.lastName.setValue(response[key]);
      }
      if (key === 'Username') {
        this.SignUpForm.controls.username.setValue(response[key]);
        this.onCheckUsername();
      }
      if (key === 'Email') {
        this.SignUpForm.controls.email.setValue(response[key]);
      }
      // TODO: Add default set of cities
      // if (key === 'CityId') {
      //   this.SignUpForm.controls.cityId.setValue(response[key]);
      // }
    }
    this.cdr.markForCheck();
  }

  onCityCtrl(): void {
    this.SignUpCitySubject.pipe(untilDestroyed(this)).subscribe(
      value => {
        this.SignUpCityValue = value;
        this.SignUpForm.controls.cityId.setValue(value.id);

        this.cdr.markForCheck();
      }
    );
  }

  onActivityCtrl(): void {
    this.SignUpActivitySubject.subscribe(
      value => {
        this.SignUpActivityPosition = value.position;
        this.SignUpForm.controls.positionId.setValue(value.position.id);

        this.SignUpActivityInstruments = value.instruments;
        this.SignUpForm.controls.instrumentIds.setValue(value.instruments.map(item => item.id));

        this.cdr.markForCheck();
      }
    );
  }

  onUploadAvatarHandler(): void {
    const UploadInput = document.getElementById('avatarUpload');
    UploadInput.click();
  }

  onUploadAvatarEvent(files: FileList): void {
    for (const i of Object.keys(files)) {
      const File: File = files[i];

      if ((File.size / 1024) > 1024) {
        return this.messageService.add({
          key: 'signUpToast',
          severity:'error',
          summary: 'Ошибка загрузки',
          detail: 'Размер файла слишком большой',
          life: 30000000
        });
      }

      FileToBase64(File).then((value: string) => {
        this.SignUpAvatar = value;
        this.cdr.markForCheck();
      })
    }
  }

  onUploadAvatarClear(): void {
    this.SignUpAvatar = null;
    this.cdr.markForCheck();
  }

  onToggleViewErrors(show: boolean): void {
    const SignUpErrors = $('.__sign-up__errors');
    show ? SignUpErrors.slideDown(150) : SignUpErrors.slideUp(150);
  }

  onCheckUsername(): void {
    const username = this.SignUpForm.controls.username.value || '';

    this.accountService.username(username).subscribe(
      r => {},
      e => {
        if (e === 409) {
          this.SignUpUsername = true;
          this.ErrorsField.username.value = 'Такой логин уже используется';
          
          this.cdr.markForCheck();
        }
      }
    )
  }

  onCheckInput(key: string): void {
    if (this.SignUpForm.controls.hasOwnProperty(key) && this.SignUpForm.controls[key].value !== '') {
      this.ErrorsField[key].status = !this.SignUpForm.controls[key].valid;
    }
  }

  onCheckPhone(): void {
    this.ErrorsField.phone.status = !this.onContactPhoneValid;
  }

  onMaskPhone($event: any, backspace?: boolean): void {
    const phone: string = this.SignUpForm.controls.phone.value || null;

    if (!phone || phone === '') {
      return;
    }

    if (phone.split('').length === 1) {
      if ($event.inputType === 'deleteContentBackward') {
        this.SignUpForm.controls.phone.setValue(phone.split('').slice(1).join(''));
      } else {
        this.SignUpForm.controls.phone.setValue('7' + phone);
      }
    }
  }

  onCheckFormattedDate(): void {
    const birthday = this.SignUpForm.controls.birthday.value || '';

    if (birthday === '') {
      return;
    }
    if (
      !moment(birthday, 'DD.MM.YYYY').isValid() ||
      moment(birthday, 'DD.MM.YYYY').year() > 2020 ||
      birthday.split('.').join('').length < 8
    ) {
      this.ErrorsField.birthday = {
        status: true,
        value: 'Неправильный формат даты'
      };
      this.cdr.detectChanges();
    }
  }

  onGetCurrentDate(): Date {
    return new Date();
  }

  onSetBirthdayValue(value: string, formatted?: boolean): void {
    this.SignUpForm.controls.birthday.setValue(
      formatted ? moment(value).format('DD.MM.YYYY') : value
    );

    setTimeout(() => {
      const InputDate = <any>document.querySelector('.inputMask__date');

      if (InputDate) {
        InputDate.value = formatted ? moment(value).format('DD.MM.YYYY') : value
      }
    }, 0)
  }

  onCoincidencePassword(): boolean {
    if (this.SignUpExternal) {
      return true;
    }
    return this.SignUpForm.controls.password.value === this.SignUpForm.controls.password_repeat.value;
  }

  onGetFormattedDate(date: string): string {
    return date ? moment(date, 'DD.MM.YYYY').toISOString() : ''
  }

  onSubmitForm(): void {
    /**
     * Loading.
     */
    if (this.SignUpLoading) {
      return;
    }

    /**
     * Validation of Errors Fields.
     */
    for (const field of Object.keys(this.ErrorsField)) {
      if (this.ErrorsField[field].status) {
        this.ErrorsText = 'Проверьте правильность заполненных полей!';
        return this.onToggleViewErrors(true);
      }
    }

    /**
     * Personal processing validation.
     */
    if (!this.SignUpForm.controls.processing.value) {
      this.ErrorsText = 'Необходимо согласиться с обработкой персональных данных!';
      this.onToggleViewErrors(true);
      return;
    }

    /**
     * External SignUp pre-event.
     */
    if (this.SignUpExternal) {
      this.SignUpForm.removeControl('password');
      this.SignUpForm.removeControl('password_repeat');
    }


    /**
     * Client validation.
     */
    if (this.SignUpUsername) {
      this.ErrorsText = 'Проверьте, такой логин уже используется!';
      this.onToggleViewErrors(true);
      return;
    }


    /**
     * Initial validation.
     */
    if (!this.SignUpForm.valid) {
      for (const key of Object.keys(this.SignUpForm.controls)) {
        if (this.ErrorsField.hasOwnProperty(key)) {
          this.ErrorsField[key].status = !this.SignUpForm.controls[key].valid;

          if (key === 'cityId' && this.SignUpForm.controls[key].value === 0) {
            this.ErrorsField[key].status = true;
          }
        }
      }

      this.ErrorsText = 'Заполните все обязательные поля!';
      this.onToggleViewErrors(true);
      return;
    }

    /**
     * Coincidence password.
     */
    if (!this.onCoincidencePassword()) {
      this.ErrorsText = 'Пароли не совпадают!';
      this.ErrorsField.password.status = true;
      this.onToggleViewErrors(true);
      return;
    }

    /**
     * SignUp request API.
     */
    const SignUpValue = this.SignUpForm.getRawValue();

    const SignUpRequest = <SignUpModel>{
      positionId: SignUpValue.positionId,
      instrumentIds: SignUpValue.instrumentIds,
      password: SignUpValue.password,
      firstName: SignUpValue.firstName,
      lastName: SignUpValue.lastName,
      username: SignUpValue.username,
      email: SignUpValue.email,
      phone: SignUpValue.phone.split('').slice(1).join(''),
      birthday: this.onGetFormattedDate(SignUpValue.birthday),
      cityId: SignUpValue.cityId,
    };

    /**
     * Loading.
     */
    this.onSignUpLoading(true);

    /**
     * SignUp from External Social.
     */
    if (SignUpValue.oauthId) {
      this.authService.signUpExternal(Object.assign(SignUpRequest, { oauthId: SignUpValue.oauthId })).subscribe(
        response => {
          this.onSignUpLoading(false);

          /**
           * Update tokens value.
           */
          this.authService.onAuthRequestSuccess(response, true);

          /**
           * Getting of Account User model.
           */
          this.onSignUpAccountUserAPI();

          /**
           * Redirect URL.
           */
          this.router.navigateByUrl(
            this.route.snapshot.queryParams['redirectUrl'] || RoutingConfig.Login + '/' + RoutingConfig.SignSuccess
          ).catch();
        },
        error => this.onErrorsCallback(error)
      );
      return;
    }


    /**
     * SignUp request.
     */
    this.authService.signUp(ObjectRequestFormatted(SignUpRequest)).subscribe(
      response => {
        this.onSignUpLoading(false);

        /**
         * Update tokens value.
         */
        this.authService.onAuthRequestSuccess(response, true);

        /**
         * Getting of Account User model.
         */
        this.onSignUpAccountUserAPI();

        /**
         * Redirect URL.
         */
        this.router.navigateByUrl(
          this.route.snapshot.queryParams['redirectUrl'] || RoutingConfig.Login + '/' + RoutingConfig.SignSuccess
        ).catch();
      },
      error => this.onErrorsCallback(error)
    );
  }

  onSignUpAccountUserAPI(): void {
    this.accountService.getMyInfo().subscribe(
      accountResponse => {
        this.accountStory.AccountEventBus.next({
          event: 'onAccountUpdate',
          data: accountResponse
        });

        /**
         * Hide loading spinner.
         */
        this.onSignUpLoading(false);
      },
      accountError => this.onErrorsCallback(accountError)
    );
  }

  onErrorsCallback(error): void {
    this.onSignUpLoading(false);

    if (error.statusCode === 400) {
      return this.notificationService.onDialog({
        type: DialogIncludesTypes.PopupErrorTemplate,
        header: 'Ошибка',
        content: error.message
      });
    }

    if (error.hasOwnProperty('failures')) {
      for (const key of Object.keys(error.failures)) {
        const Failures = error.failures[key];

        if (this.ErrorsField.hasOwnProperty(key)) {
          this.ErrorsField[key].status = true;
          this.ErrorsField[key].value = Failures;
        }
      }
    }

    if (error.hasOwnProperty('message')) {
      this.ErrorsText = error.message + '!';
      this.onToggleViewErrors(true);
    }
  }

  onViewSearchCities(): void {
    this.dialogService.open(SearchCityComponent, {
      header: 'Выбрать город',
      width: _deviceType() === DeviceTypes.Mobile ? '90%' : '100%',
      styleClass: 'p-dialog__city-search',
      data: <DialogComponentModel>{
        includeType: DialogIncludesTypes.ComponentTemplate,
        includeData: {
          dataInitial: this.SignUpCityValue,
          dataSubject: this.SignUpCitySubject
        }
      }
    })
  }

  onViewSearchActivity(): void {
    this.dialogService.open(SearchActivityComponent, {
      header: 'Выбрать инструмент и должность',
      width: _deviceType() === DeviceTypes.Mobile ? '90%' : '100%',
      styleClass: 'p-dialog__activity-search',
      data: <DialogComponentModel>{
        includeType: DialogIncludesTypes.ComponentTemplate,
        includeData: {
          dataInitial: {
            position: this.SignUpActivityPosition,
            instruments: this.SignUpActivityInstruments
          },
          dataSubject: this.SignUpActivitySubject
        }
      }
    })
  }

  get onContactPhoneValid(): boolean {
    const phone: string = this.SignUpForm.controls.phone.value || null;

    return PhoneNumberValidate(phone);
  }
}
