import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { RoutingConfig } from '@app/routing/routing.config';
import { TokenService } from '@app/core/services/token/token.service';

@Injectable()

export class GuardAuthService implements CanActivate {
  constructor(
    private router: Router,
    private token: TokenService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!!this.token.accessToken && !!this.token.refreshToken) {
      return true;
    }
    this.onRedirectToAuthorisation(route);
  }

  /**
   * Redirect to authorisation page with saving path tree.
   * @param route
   */
  onRedirectToAuthorisation(route: ActivatedRouteSnapshot): void {
    const redirectUrl = route['_routerState']['url'];

    /**
     * Redirect to selected URL.
     */
    this.router.navigateByUrl(
      this.router.createUrlTree(
        [RoutingConfig.Login + '/' + RoutingConfig.SignIn], {
          queryParams: {
            redirectUrl
          }
        }
      )
    ).then();
  }
}
