import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { map, filter, mergeMap} from 'rxjs/operators';
import { untilDestroyed } from "@app/utils/until-destroyed";
import { SeoPageModel } from "./seo.model";
import { CustomerSEO } from '@app/core/constants';

declare var window;

@Injectable()

export class SEOService implements OnDestroy {

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private title: Title,
    private metaTag: Meta
  ) {}

  ngOnDestroy(): void {
  }

  public init(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(_ => this.activatedRoute),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data),
      untilDestroyed(this)

    ).subscribe((event) => {
      const SEOEvent = event.SEO || null;

      if (SEOEvent) {
        const Title = SEOEvent.title ? SEOEvent.title : CustomerSEO.baseTitle;
        const Description = SEOEvent.description ? SEOEvent.description : CustomerSEO.baseDescription;

        this.setPageSchema({
          title: Title,
          description: Description,
          image: '',
          url: ''
        });
      }
    });
  }

  /**
   * Set of custom meta schema.
   * @param pageData: {
   *   title,
   *   description,
   *   image,
   *   url
   * }
   */
  public setPageSchema(pageData: SeoPageModel): void {
    this.title.setTitle(
      CustomerSEO.baseTitle + ' | ' + pageData.title
    );
    this.metaTag.updateTag(
      {
        name: 'title',
        content: CustomerSEO.baseTitle + ' | ' + pageData.title
      }
    );

    this.metaTag.updateTag(
      {
        name: 'description',
        content: pageData.description
      }
    );
    this.metaTag.updateTag(
      {
        name: 'og:description',
        content: pageData.description
      }
    );
    this.metaTag.updateTag(
      {
        name: 'og:image',
        content: pageData.image
      }
    );
    this.metaTag.updateTag(
      {
        name: 'og:image:secure_url',
        content: pageData.image
      }
    );
    this.metaTag.updateTag(
      {
        name: 'og:url',
        content: pageData.url || (<any>window).location.href
      }
    );
  }
}
