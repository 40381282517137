import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { DevicesClass } from '@app/core/classes';
import { Router, ActivatedRoute } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RoutingConfig } from '@app/routing/routing.config';
import { DialogComponentModel } from '@app/core/models/diallog';
import { PrimeNGConfig } from 'primeng/api';

@UntilDestroy()
@Component({
  selector: 'app-sign-confirmed',
  templateUrl: 'sign-confirmed.component.html',
  styleUrls: [`./sign-confirmed.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SignConfirmedComponent extends DevicesClass implements OnInit {
  public Routing = RoutingConfig;
  public SNContent: string;

  @Input() Data: any;

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    public ref: DynamicDialogRef,
    private router: Router,
    private dialogConfig: DynamicDialogConfig,
    private primengConfig: PrimeNGConfig,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;

    this.onSignNeedInputConfig();
  }

  onSignNeedInputConfig(): void {
    const InputConfig = <DialogComponentModel>this.dialogConfig.data || null;

    this.SNContent = <string>InputConfig.includeData || '';
  }

  onRouteToSignIn($event: Event): void {
    $event.preventDefault();

    this.router.navigateByUrl(
      `/${RoutingConfig.Login}/${RoutingConfig.SignIn}?redirectUrl=${location.pathname}`
    ).catch();
    this.onCloseDialog();
  }

  onRouteToSignUp($event: Event): void {
    $event.preventDefault();

    this.router.navigateByUrl(
      `/${RoutingConfig.Login}/${RoutingConfig.SignUp}?redirectUrl=${location.pathname}`
    ).catch();
    this.onCloseDialog();
  }

  onCloseDialog(): void {
    this.ref.close()
  }
}
