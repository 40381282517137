import { AppStaticURL, AppPROD } from '@app/app.settings';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { DevicesClass } from '@app/core/classes/component/devices.class';
import { IBulletinItem, IBulletinDetail } from '@app/modules/bulletins-modules/bulletins.model';
import { RoutingConfig } from '@app/routing/routing.config';
import { BulletinsEventBus, BulletinsStoryService } from '@app/modules/bulletins-modules/bulletins.story';
import { BulletinsService } from '@app/modules/bulletins-modules/bulletins.service';
import { AccountEventBus, AccountStoryService } from '@app/modules/account-modules/account.story';
import { AuthStoryService } from '@app/modules/auth-modules/auth.story';
import { FavoritesService } from '@app/modules/favorites-modules/favorites.service';
import { untilDestroyed } from '@ngneat/until-destroy';
import { YoutubeRequestFormatted } from '@app/utils/converter';
import { Router } from '@angular/router';
import { PrimeNGConfig } from "primeng/api";
import { DialogIncludesTypes } from '@app/core/models/diallog';
import { NotificationService } from '@app/modules/notification-modules/notification.service';
import { IAccountUserInfo } from '@app/modules/account-modules/account.model';


export enum BulletinItemViewTypes {
  Block,          // 0
  List,           // 1
  Detail,         // 2
  Related,        // 3
  ProfileList,    // 4
  ProfileBlock,   // 5
}

export enum BulletinPublishTypes {
  OnModeration, // 0
  Banned,       // 1
  Published,    // 2
  Expired,      // 3
  ClosedByUser  // 4
}

export enum BulletinModerationTypes {
  Law,          // 0
  SiteRules,    // 1
  Spam,         // 2
  Ok,           // 3
  Other         // 4
}

export enum BulletinMessengerTypes {
  Viber,
  Whatsapp,
  Telegram
}

@Component({
  selector: 'shared-bulletin-item',
  templateUrl: 'bulletin-item.component.html',
  styleUrls: [`./bulletin-item.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class BulletinItemComponent extends DevicesClass implements OnInit {
  public BIRouting = RoutingConfig;
  public BITypes = BulletinItemViewTypes;
  public BIPublish = BulletinPublishTypes;
  public BIModeration = BulletinModerationTypes;
  public BIMessenger = BulletinMessengerTypes;
  public StaticURL = AppStaticURL;

  /**
   * Favorites event variables.
   */
  public BIFavLoading: boolean = false;
  public BIFavActive: boolean = false;
  public BIFavId: number = 0;
  public BIUserAuth: IAccountUserInfo = null;

  public playerVars = { cc_lang_pref: 'ru' };
  public player: YT.Player;

  @Input() BulletinView: BulletinItemViewTypes;
  @Input() BulletinItem: IBulletinItem;
  @Input() BulletinDetail: IBulletinDetail;

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    private router: Router,
    private bulletinsService: BulletinsService,
    private bulletinsStoryService: BulletinsStoryService,
    private accountStory: AccountStoryService,
    private authStoryService: AuthStoryService,
    private favoritesService: FavoritesService,
    private primengConfig: PrimeNGConfig,
    private notificationService: NotificationService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;

    /**
     * Set on accountStory emit changes.
     */
    this.onAccountStoryBus();

    /**
     * Set on bulletinsStory emit changes.
     */
    this.onBulletinsStoryBus();
  }

  onAccountStoryBus(): void {
    this.accountStory.AccountEventBus$.pipe(untilDestroyed(this)).subscribe(
      emit => this.onAccountStoryListener(emit)
    );
  }

  onAccountStoryListener(emit: AccountEventBus): void {
    if (emit.event === 'onAccountUpdate') {
      this.BIUserAuth = emit.data;
    }

    if (emit.event === 'onAccountLogout' || emit.event === 'onAccountEmpty') {
      this.BIUserAuth = null;
    }

    this.cdr.markForCheck();
  }

  onBulletinsStoryBus(): void {
    this.bulletinsStoryService.BulletinsEventBus$.pipe(untilDestroyed(this)).subscribe(
      emit => this.onBulletinsStoryListener(emit)
    );
  }

  onBulletinsStoryListener(emit: BulletinsEventBus): void {
    if (emit.event === 'onUpdateFavorites') {
      this.onMapFavoritesBulletins(emit.data)
    }
  }

  onMapFavoritesBulletins(bulletins: Array<IBulletinItem>): void {
    bulletins.map(bulletinFavorite => {
      if (this.BulletinItem) {
        if (bulletinFavorite.itemId === (this.BulletinItem.itemId ||this.BulletinItem.id)) {
          this.BIFavId = bulletinFavorite.id;
          this.BIFavActive = true;
          this.cdr.markForCheck();
        }
      }

      if (this.BulletinDetail) {
        if (bulletinFavorite.itemId === this.BulletinDetail.id) {
          this.BIFavId = bulletinFavorite.id;
          this.BIFavActive = true;
          this.cdr.markForCheck();
        }
      }
    })
  }

  onViewDetailBulletin(): string {
    return AppPROD ? '_target' : '_self';
  }

  onLoadingFavorites(status: boolean): void {
    this.BIFavLoading = status;
    this.cdr.markForCheck();
  }

  onAddToFavorites(): void {
    if (!this.BIUserAuth) {
      return this.authStoryService.AuthEventBus.next({
        event: 'onAuthNeed',
        data: 'Для добавления в избранное'
      });
    }
    this.onLoadingFavorites(true);
    /**
     * Get bulletin ID.
     */
    const Id = this.BulletinItem ?
      this.BulletinItem.itemId || this.BulletinItem.id :
      this.BulletinDetail.id;

    /**
     * Get bulletin Type.
     */
    const Type = this.BulletinItem ?
      this.BulletinItem.type === 'ArtistSearchBand' ? 3 : 4 :
      this.BulletinDetail.type === 'ArtistSearchBand' ? 3 : 4;


    /**
     * Add to favorites request.
     */
    this.favoritesService.addToFavorites(Id, Type).subscribe(
      r => {
        this.BIFavActive = true;
        this.onLoadingFavorites(false);

        this.bulletinsStoryService.BulletinsEventBus.next({
          event: 'onAddToFavorites',
          data: this.BulletinItem
        });
      },
      e => {
        if (e === 400) {
          this.BIFavActive = true;
        }
        this.onLoadingFavorites(false)
      }
    );
  }

  onRemoveFromFavorites(): void {
    if (!this.BIUserAuth) {
      return this.authStoryService.AuthEventBus.next({
        event: 'onAuthNeed',
        data: 'Для добавления в избранное'
      });
    }
    this.onLoadingFavorites(true);

    this.favoritesService.removeFromFavorites(this.BIFavId).subscribe(
      r => {
        this.BIFavActive = false;
        this.onLoadingFavorites(false);

        this.bulletinsStoryService.BulletinsEventBus.next({
          event: 'onRemoveFromFavorites',
          data: this.BIFavId
        });
      },
      e => {
        if (e === 400) {
          this.BIFavActive = false;
        }
        this.onLoadingFavorites(false)
      }
    );
  }

  onRouteToPublicURL(): void {
    const publicUrl = this.BulletinDetail.performerPromoViewModel.publicUrl || null;

    if (publicUrl) {
      window.open(
        `${this.BulletinDetail.performerPromoViewModel.publicUrl}`, '_blank'
      );
    }
  }

  onSetBulletinPlayer(player) {
    this.player = player;
  }

  onBulletinAboutUsText(): any {
    if (this.BulletinDetail.type === 'ArtistSearchBand') {
      const aboutUsText = this.BulletinDetail.artistAboutViewModel.aboutUsText || null;
      return aboutUsText ? aboutUsText.split('\n') : null;
    }

    if (this.BulletinDetail.type === 'BandSearchArtist') {
      const aboutUsText = this.BulletinDetail.bandAboutViewModel.aboutUsText || null;
      return aboutUsText ? aboutUsText.split('\n') : null;
    }
  }

  onBulletinRequirements(): any {
    if (this.BulletinDetail.type === 'ArtistSearchBand') {
      const requirements = this.BulletinDetail.artistRequestViewModel.requirements || null;
      return requirements ? requirements.split('\n') : null;
    }
    if (this.BulletinDetail.type === 'BandSearchArtist') {
      const requirements = this.BulletinDetail.bandRequestViewModel.requirements || null;
      return requirements ? requirements.split('\n') : null;
    }
  }

  onBulletinYouTube(): any {
    const youTubeUrl = this.BulletinDetail.performerPromoViewModel.youTubeUrl || null;
    return youTubeUrl ? YoutubeRequestFormatted(youTubeUrl) : 0;
  }

  onSoundCloudTrackIds(): any {
    const soundCloudTracks = this.BulletinDetail.performerPromoViewModel.soundCloudTracks || null;
    return soundCloudTracks ? soundCloudTracks : [];
  }

  getSoundCloudTrackIds(track: string): string {
    return `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${track}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`
  }

  onRouteToSignIn($event: Event): void {
    $event.preventDefault();

    this.router.navigateByUrl(
      `/${RoutingConfig.Login}/${RoutingConfig.SignIn}?redirectUrl=${location.pathname}`
    ).catch();
  }

  onRouteToSignUp($event: Event): void {
    $event.preventDefault();

    this.router.navigateByUrl(
      `/${RoutingConfig.Login}/${RoutingConfig.SignUp}?redirectUrl=${location.pathname}`
    ).catch();
  }

  onShowBulletinPhone(): void {
    if (!this.BIUserAuth) {
      return this.authStoryService.AuthEventBus.next({
        event: 'onAuthNeed',
        data: 'Для просмотра телефона'
      });
    }

    if (!this.BIUserAuth.emailConfirmed) {
      return this.authStoryService.AuthEventBus.next({
        event: 'onAuthConfirmed'
      });
    }

    this.bulletinsStoryService.BulletinsEventBus.next({
      event: 'onShowPhone',
      data: this.BulletinDetail
    });
  }

  onRouteToSocialLinkURL(path: string, id: string) {
    if (!this.BIUserAuth) {
      return this.authStoryService.AuthEventBus.next({
        event: 'onAuthNeed',
        data: 'Для просмотра телефона'
      });
    }

    if (!this.BIUserAuth.emailConfirmed) {
      return this.authStoryService.AuthEventBus.next({
        event: 'onAuthConfirmed'
      });
    }

    if (path && id) {
      id = id.split('/').length > 1 ? id.split('/').splice(-1).join('') : id;
      window.open(path + id, '_blank');
    }
  }

  onRouteToMessengerLinkURL(messenger: BulletinMessengerTypes, status): void {
    if (!this.BIUserAuth.emailConfirmed) {
      return this.authStoryService.AuthEventBus.next({
        event: 'onAuthConfirmed'
      });
    }

    if (!status || messenger === this.BIMessenger.Telegram) {
      return;
    }
    this.bulletinsService.bulletinDetailPhone(this.BulletinDetail.id).subscribe(
      value => {
        if (!value) {
          return this.notificationService.onDialog({
            type: DialogIncludesTypes.PopupErrorTemplate,
            header: 'Мессенджер',
            content: 'Не удалось открыть выбранный мессенджер. Попробуйте воспользоватья другими способами для связи, указанные в объявлении.'
          });
        }

        if (messenger === this.BIMessenger.Whatsapp) {
          window.open(`https://wa.me/+7${value}`, '_blank');
        }

        if (messenger === this.BIMessenger.Viber) {
          window.open(`viber://chat?number=+7${value}`, '_blank');
        }
      },
      error => {
        this.notificationService.onDialog({
          type: DialogIncludesTypes.PopupErrorTemplate,
          header: 'Мессенджер',
          content: 'Не удалось открыть выбранный мессенджер. Попробуйте воспользоватья другими способами для связи, указанные в объявлении.'
        });
      }
    );
  }

  onStopBulletinPublication(): void {
    this.bulletinsStoryService.BulletinsEventBus.next({
      event: 'onStopPublication',
      data: this.BulletinItem.id || this.BulletinDetail.id
    });
  }

  onActivateBulletinPublication(): void {
    this.bulletinsStoryService.BulletinsEventBus.next({
      event: 'onActivatePublication',
      data: this.BulletinItem.id || this.BulletinDetail.id
    });
  }

  onEditBulletinPublication(): void {
    if (this.BulletinItem.type === 'ArtistSearchBand') {
      this.router.navigateByUrl(`/${RoutingConfig.Bulletins}/${RoutingConfig.Edit}/${RoutingConfig.Artist}/${this.BulletinItem.id}`).catch();
    }

    if (this.BulletinItem.type === 'BandSearchArtist') {
      this.router.navigateByUrl(`/${RoutingConfig.Bulletins}/${RoutingConfig.Edit}/${RoutingConfig.Band}/${this.BulletinItem.id}`).catch();
    }
  }

  get onBulletinPublish(): number {
    if (this.BulletinDetail.bulletinPublishStatus === 'OnModeration') {
      return this.BIPublish.OnModeration;
    }

    if (this.BulletinDetail.bulletinPublishStatus === 'Banned') {
      return this.BIPublish.Banned;
    }

    if (this.BulletinDetail.bulletinPublishStatus === 'Published') {
      return this.BIPublish.Published;
    }

    if (this.BulletinDetail.bulletinPublishStatus === 'Expired') {
      return this.BIPublish.Expired;
    }

    if (this.BulletinDetail.bulletinPublishStatus === 'ClosedByUser') {
      return this.BIPublish.ClosedByUser;
    }
  }

  get onBulletinPublishList(): number {
    if (this.BulletinItem.status === 'OnModeration') {
      return this.BIPublish.OnModeration;
    }

    if (this.BulletinItem.status === 'Banned') {
      return this.BIPublish.Banned;
    }

    if (this.BulletinItem.status === 'Published') {
      return this.BIPublish.Published;
    }

    if (this.BulletinItem.status === 'Expired') {
      return this.BIPublish.Expired;
    }

    if (this.BulletinItem.status === 'ClosedByUser') {
      return this.BIPublish.ClosedByUser;
    }
  }

  get onBulletinModeration(): number {
    if (this.BulletinDetail.moderationResult === 'Law') {
      return this.BIModeration.Law;
    }

    if (this.BulletinDetail.moderationResult === 'SiteRules') {
      return this.BIModeration.SiteRules;
    }

    if (this.BulletinDetail.moderationResult === 'Spam') {
      return this.BIModeration.Spam;
    }

    if (this.BulletinDetail.moderationResult === 'Ok') {
      return this.BIModeration.Ok;
    }

    if (this.BulletinDetail.moderationResult === 'Other') {
      return this.BIModeration.Other;
    }
  }
}
