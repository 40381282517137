import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/**
 * Services
 */
import { MusicianService } from './musician.service';


@NgModule({
  declarations: [],
  exports: [],
  imports: [
    CommonModule
  ],
  providers: [
    MusicianService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})

export class MusicianModule {}
