import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FundamentalsModule } from '@app/modules/fundamentals-modules/fundamentals.module'
import { InstrumentsModule } from '@app/modules/instruments-modules/instruments.module';

/**
 * Components
 */
import { SearchActivityComponent } from './search-activity/search-activity.component';

/**
 * Vendors
 */
import { TooltipModule } from 'primeng/tooltip';
import { ChipModule } from 'primeng/chip';

/**
 * Directives
 */
import { ValidationModule } from '@app/core/directives';


@NgModule({
  declarations: [
    SearchActivityComponent
  ],
  exports: [
    SearchActivityComponent
  ],
  entryComponents: [
    SearchActivityComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FundamentalsModule,
    InstrumentsModule,
    TooltipModule,
    ValidationModule,
    ChipModule
  ],
  providers: [],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})

export class ActivityModule {}
