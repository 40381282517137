<div class="sign-need__popup">
  <div class="row">
    <div class="sign-need__row-text">
      <div class="grid align-items-center flex-column justify-content-center text-center">
        <span class="pi pi-lock"></span>
        <p class="mt-4 mb-4">
          {{SNContent}} <a (click)="onRouteToSignIn($event)">войдите на сайт</a>,
          используя Ваш логин и пароль,<br/>
          или <a (click)="onRouteToSignUp($event)">зарегистрируйтесь</a>
        </p>
      </div>
    </div>

    <div class="sign-need__row-links">
      <button class="ui-buttons ui-buttons__blue-large" (click)="onCloseDialog()" pRipple>
        Закрыть
      </button>
    </div>
  </div>
</div>
