import { Pipe, PipeTransform } from '@angular/core';
type DeclinationTypes = 'days' | 'month' | 'year';

@Pipe({
  name: 'declination'
})

export class DeclinationPipe implements PipeTransform {
  DeclinationDays = ['день', 'дня', 'дней'];

  constructor() {
  }

  transform(num: number, type: DeclinationTypes): string {
    if (type === 'days') {
      return num + ' ' + this.declinationDay(Math.abs(num));
    }
  }

  declinationDay(num: number): string {
    const cases = [2, 0, 1, 1, 1, 2];

    return this.DeclinationDays [
      (num % 100 > 4 && num % 100 < 20) ?
        2 : cases[(num % 10 < 5) ? num % 10 : 5]
    ];
  }
}
