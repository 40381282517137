import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { AccountRoutes } from './account.routes';
import { AccountService } from '@app/modules/account-modules/account.service';
import { BulletinsService } from '@app/modules/bulletins-modules/bulletins.service';

/**
 * Components
 */
import { AccountShellComponent } from './account-shell/account-shell.component';
import { BulletinsComponent } from './bulletins/bulletins.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { DiscountComponent } from './discount/discount.component';
import { FavoritesComponent } from './favorites/favorites.component';
import { NotificationComponent } from './notification/notification.component';
import { PointsComponent } from './points/points.component';
import { RecoveryPasswordComponent } from './recovery-password/recovery-password.component';
import { ProfileComponent } from './profile/profile.component';
import { SettingsComponent } from './settings/settings.component';

/**
 * Shared
 */
import { BreadcrumbsModule } from '@app/shared/breadcrumbs/breadcrumbs.module';
import { AccountMenuModule } from '@app/shared/account-menu/account-menu.module';
import { BulletinItemModule } from '@app/shared/bulletin-item/bulletin-item.module';
import { NotificationItemModule } from '@app/shared/notification-item/notification-item.module';
import { NewsItemModule } from '@app/shared/news-item/news-item.module';
import { SkeletonItemModule } from '@app/shared/skeleton-item/skeleton-item.module';

/**
 * Vendors
 */
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TooltipModule } from 'primeng/tooltip';
import { ChipModule } from 'primeng/chip';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE, DateAdapter } from '@angular/material/core';
import { NgxMaskModule } from 'ngx-mask';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { InputMaskModule } from 'primeng/inputmask';

/**
 * Adapters
 */
import { DatepickerAdapter } from '@app/core/adapters';

/**
 * Validators
 */
import { InputValidators } from '@app/core/validators/input.validator';

@NgModule({
  declarations: [
    AccountShellComponent,
    BulletinsComponent,
    ChangePasswordComponent,
    ConfirmEmailComponent,
    DiscountComponent,
    FavoritesComponent,
    NotificationComponent,
    PointsComponent,
    RecoveryPasswordComponent,
    ProfileComponent,
    SettingsComponent
  ],
  exports: [
    AccountShellComponent,
    BulletinsComponent,
    ChangePasswordComponent,
    ConfirmEmailComponent,
    DiscountComponent,
    FavoritesComponent,
    NotificationComponent,
    PointsComponent,
    RecoveryPasswordComponent,
    ProfileComponent,
    SettingsComponent
  ],
  imports: [
    RouterModule.forChild(AccountRoutes),
    CommonModule,
    ReactiveFormsModule,
    BreadcrumbsModule,
    AccountMenuModule,
    BulletinItemModule,
    NewsItemModule,
    SkeletonItemModule,
    NotificationItemModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMaskModule.forRoot(),
    TooltipModule,
    ChipModule,
    ToastModule,
    ConfirmDialogModule,
    InputMaskModule
  ],
  providers: [
    DialogService,
    DynamicDialogRef,
    DynamicDialogConfig,
    MessageService,
    AccountService,
    BulletinsService,
    ConfirmationService,
    InputValidators,
    {provide: MAT_DATE_LOCALE, useValue: 'ru-RU'},
    {provide: DateAdapter, useClass: DatepickerAdapter},
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})

export class AccountModule {}
