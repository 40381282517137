import { Pipe, PipeTransform } from '@angular/core';
import { FundamentalsService } from '@app/modules/fundamentals-modules/fundamentals.service';
import { GenreModel } from '@app/modules/fundamentals-modules/fundamentals.models';


@Pipe({
  name: 'bulletinGenre'
})

export class BulletinGenrePipe implements PipeTransform {
  constructor(
    private fundamentalsService: FundamentalsService
  ) {}

  transform(genreId: number): string {
    const genresDependencies = this.fundamentalsService.genresDependencies || [];

    const Genres = <GenreModel>genresDependencies.filter(
      item => item.id === genreId
    )[0] || null;

    return Genres ? Genres.name : '';
  }
}
