<div class="account-shell__content">
  <div class="row animated fadeIn faster delay-0_3s">
    <div class="grid align-items-center justify-content-center">
      <div class="ui-tabs">
        <div class="ui-tabs__link" (click)="onSetTabValue(TVTypes.Bulletins)"
          ngClass="{{NTabsValue === TVTypes.Bulletins ? 'active' : ''}}">
          <p>Объявления</p>
        </div>
        <!--<div class="ui-tabs__link" (click)="onSetTabValue(TVTypes.News)"
          ngClass="{{NTabsValue === TVTypes.News ? 'active' : ''}}">
          <p>Новости</p>
        </div>-->
      </div>
    </div>
  </div>

  <div class="row animated fadeIn faster" *ngIf="NLoading">
    <div class="pg-wrapper">
      <div class="account-shell__content-wrapper">
        <shared-skeleton-item [SkeletonView]="SVTypes.Profile"></shared-skeleton-item>
      </div>
    </div>
  </div>

  <div class="row animated fadeIn faster" *ngIf="!NLoading">
    <div class="row" *ngIf="NBulletinsResults.length">
      <div class="account-shell__events">
        <div class="pg-wrapper">
          <div class="account-shell__content-wrapper">
            <div class="grid align-items-center"
              ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

              <div class="col-3 col-sm-12">
                <div class="ui-checkbox">
                  <input id="selectAll" type="checkbox" [checked]="NBulletinsSelectedAll"
                    (change)="onSetSelectedAllBulletins($event)">

                  <label for="selectAll">
                    <span class="ui-checkbox__text">Выбрать все уведомления</span>
                  </label>
                </div>
              </div>
              <div class="col-auto col-sm-12 margin-left-auto">
                <div class="grid align-items-center">

                  <div class="col-auto col-sm-7"
                    ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : ''}}">

                    <div class="account-shell__events-icons remove-selected" (click)="onDeleteSelectedBulletins()"
                      ngClass="{{this.NBulletinsSelected.length ? '' : 'disabled'}} {{(deviceValue | async) === DTypes.Mobile ? 'pl-0' : ''}}">

                      <span class="mr-icon mr-icon-trash"></span>
                      <span class="mr-text">Удалить выбранные</span>
                    </div>
                  </div>

                  <div class="col-auto col-sm-5 pl-2"
                    ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4 pl-0' : ''}}">

                    <div class="account-shell__events-icons remove-all" (click)="onDeleteAllBulletins()"
                      ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'pl-0' : ''}}">

                      <span class="mr-icon mr-icon-trash"></span>
                      <span class="mr-text">Удалить все</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="NTabsValue === TVTypes.Bulletins">
      <div class="pg-wrapper">
        <div class="notification__result" *ngIf="!NBulletinsResults.length">
          <div class="notification__result-empty">
            <div class="grid align-items-center justify-content-center flex-column">
              <div class="mr-icon mr-icon-other"></div>
              <h5 class="mt-6">У вас пока нет новых уведомлений...</h5>
            </div>
          </div>
        </div>
        <div class="account-shell__content-wrapper">
          <div class="notification__result" *ngIf="NBulletinsResults.length">
            <ng-container *ngFor="let result of NBulletinsResults;">
              <div class="grid align-items-center justify-content-center"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

                <div class="notification__result-checkbox"
                  ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'order-1 mt-2 mb-4' : ''}}">

                  <div class="ui-checkbox">
                    <input id="{{result.itemId}}" type="checkbox" [checked]="result.selected"
                      (change)="onSetSelectedBulletin(result)">

                    <label for="{{result.itemId}}">
                      <span class="ui-checkbox__text" *ngIf="(deviceValue | async) === DTypes.Mobile">Выбрать</span>
                    </label>
                  </div>
                </div>

                <div class="notification__result-content"
                  ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'order-0' : ''}}">

                  <shared-notification-item [NotificationItem]="result"></shared-notification-item>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<p-confirmDialog #default [key]="'default'" [appendTo]="'body'" [styleClass]="'p-dialog__confirm'">
  <p-footer>
    <button class="ui-buttons ui-buttons__light-small" (click)="default.reject()">Отменить</button>
    <button class="ui-buttons ui-buttons__blue-small" (click)="default.accept()">Удалить</button>
  </p-footer>
</p-confirmDialog>
