import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DevicesClass } from '@app/core/classes';
import { BreadcrumbsDataModel } from '@app/core/models/breadcrumbs';
import { RoutingConfig } from '@app/routing/routing.config';

@UntilDestroy()
@Component({
  selector: 'app-account-points',
  templateUrl: 'points.component.html',
  styleUrls: [`./points.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class PointsComponent extends DevicesClass implements OnInit {
  public BreadcrumbsData: BreadcrumbsDataModel = [
    {
      title: 'Личный кабинет',
      url: '/' + RoutingConfig.Profile
    },
    {
      title: 'Мои баллы',
      url: '/' + RoutingConfig.ProfilePoints,
      active: true
    }
  ];

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }
}
