/**
 * Available routing URL's of customer.
 */
export const RoutingConfig = {
  /**
   * Root path.
   */
  Root: '',

  /**
   * Shell path.
   */
  ShellRoot: '',

  /**
   * Base pages
   */
  Login               : 'login',
  Bulletins           : 'bulletins',
  Market              : 'market',
  Calendar            : 'calendar',
  Loyalty             : 'loyalty',
  Profile             : 'profile',
  News                : 'news',
  Pages               : 'pages',

  /**
   * Login Routing
   */
  SignIn              : 'sign-in',
  SignUp              : 'sign-up',
  SignOAuth           : 'sign-oauth',
  SignRemind          : 'sign-remind',
  SignSuccess         : 'sign-success',

  /**
   * Bulletins Routing
   */
  Search              : 'search',
  Detail              : 'detail',
  Create              : 'create',
  Edit                : 'edit',
  Artist              : 'artist',
  Band                : 'band',
  Success             : 'success',

  /**
   * Profile Routing
   */
  ProfileAccount      : 'account',
  ProfileBulletins    : 'bulletins',
  ProfileFavorites    : 'favourites',
  ProfileNotify       : 'notifications',
  ProfileChangePass   : 'change-password',
  ProfileRecoveryPass : 'recovery-password',
  ProfileConfirmEmail : 'confirm',
  ProfileResetPass    : 'reset-password',
  ProfilePoints       : 'points',
  ProfileDiscount     : 'discount',
  ProfileSettings     : 'settings',

  /**
   * Other Routing
   */
  Announcement        : 'announcement',

  /**
   * Static Pages Routing
   */
  Confidential        : 'confidential',
  Rules               : 'rules',
  Contacts            : 'contacts',
  BecomePartner       : 'become-partner',
  Technical           : 'technical',
  Advertisement       : 'advertisement',

  /**
   * Errors
   */
  Page404             : '404'
};
