import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NewsItemComponent } from './news-item.component';

/**
 * Pipes
 */
import { PipesCoreModule } from '@app/core/pipes/pipes.module';

/**
 * Services
 */
import { NewsService } from '@app/modules/news-modules/news.service';

@NgModule({
  declarations: [
    NewsItemComponent
  ],
  exports: [
    NewsItemComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    PipesCoreModule
  ],
  providers: [
    NewsService
  ]
})

export class NewsItemModule {
  constructor() {}
}
