import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DevicesClass } from '@app/core/classes';
import { BreadcrumbsDataModel } from '@app/core/models/breadcrumbs';
import { RoutingConfig } from '@app/routing/routing.config';
import { BulletinsService } from '@app/modules/bulletins-modules/bulletins.service';
import { IBulletinItem } from '@app/modules/bulletins-modules/bulletins.model';
import { BulletinItemViewTypes } from '@app/shared/bulletin-item/bulletin-item.component';
import { BulletinsStoryService } from '@app/modules/bulletins-modules/bulletins.story';
import { FavoritesService } from '@app/modules/favorites-modules/favorites.service';
import { IBlogPostNewsDetail } from '@app/modules/news-modules/news.models';
import { ConfirmationService } from 'primeng/api';
import { NewsViewTypes } from '@app/shared/news-item/news-item.component';
import { ArrayToUrlParams } from '@app/utils/converter';
import { NewsStoryService } from '@app/modules/news-modules/news.story';
import { SkeletonViewTypes } from '@app/shared/skeleton-item/skeleton-item.component';

enum TabsValues {
  Bulletins,    // 0
  News,         // 1
}

@UntilDestroy()
@Component({
  selector: 'app-account-favorites',
  templateUrl: 'favorites.component.html',
  styleUrls: [`./favorites.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FavoritesComponent extends DevicesClass implements OnInit {
  public TVTypes = TabsValues;
  public SVTypes = SkeletonViewTypes;
  public BIVTypes = BulletinItemViewTypes;
  public NVTypes = NewsViewTypes;
  public BreadcrumbsData: BreadcrumbsDataModel = [
    {
      title: 'Личный кабинет',
      url: '/' + RoutingConfig.Profile
    },
    {
      title: 'Избранное',
      url: '/' + RoutingConfig.ProfileFavorites,
      active: true
    }
  ];


  public FTabsValue: TabsValues;
  public FLoading: boolean = true;
  public FBulletinsSelected: Array<IBulletinItem> = [];
  public FBulletinsSelectedAll: boolean;


  /**
   * Bulletins Favorites.
   */
  public FBulletinsResults: Array<IBulletinItem> = [];

  /**
   * News Favorites.
   */
  public FNewsResults: Array<IBlogPostNewsDetail> = [];

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    private bulletinsService: BulletinsService,
    private bulletinsStoryService: BulletinsStoryService,
    private favoritesService: FavoritesService,
    private confirmationService: ConfirmationService,
    private newsStoryService: NewsStoryService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.onSetTabValue(this.TVTypes.Bulletins);
  }

  onTabsValueLoading (status: boolean, delay: number = 0) {
    setTimeout(() => {
      this.FLoading = status;
      this.cdr.detectChanges();
    }, delay);
  }

  onSetTabValue(value: TabsValues): void {
    if (this.FTabsValue === value) {
      return;
    }
    this.onTabsValueLoading(true);

    this.onUploadResetAll();

    if (value === this.TVTypes.Bulletins) {
      this.onUploadFavoritesBulletins();
    }
    if (value === this.TVTypes.News) {
      this.onUploadFavoritesNews();
    }

    this.FTabsValue = value;
  }

  onUploadFavoritesBulletins(): void {
    this.favoritesService.bulletinsFavorites().subscribe(
      response => {
        /**
         * Upload of favorites bulletins.
         */
        this.FBulletinsResults = response.items;
        this.cdr.markForCheck();

        /**
         * Reset of all selected checkbox.
         */
        this.FBulletinsSelectedAll = false;

        /**
         * Set BulletinView in favorites.
         */
        setTimeout(() => {
          this.bulletinsStoryService.BulletinsEventBus.next({
            event: 'onUpdateFavorites',
            data: response.items
          });
        }, 0);

        /**
         * Reset of loading.
         */
        this.onTabsValueLoading(false, 1000);
      },
      error => this.onUploadResetAll()
    );
  }

  onUploadFavoritesNews(): void {
    this.favoritesService.newsFavorites().subscribe(
      response => {
        this.FNewsResults = response.items;
        this.cdr.markForCheck();

        /**
         * Reset of all selected checkbox.
         */
        this.FBulletinsSelectedAll = false;

        /**
         * Set BulletinView in favorites.
         */
        setTimeout(() => {
          this.newsStoryService.NewsEventBus.next({
            event: 'onUpdateFavorites',
            data: response.items
          });
        }, 0);

        /**
         * Reset of loading.
         */
        this.onTabsValueLoading(false, 1000);

      },
      error => this.onUploadResetAll()
    );
  }

  onUploadResetAll(): void {
    this.FBulletinsSelectedAll = false;
    this.cdr.markForCheck();
  }

  onSetSelectedBulletin(bulletin: IBulletinItem): void {
    if (this.FBulletinsSelected.some(bull => bull.itemId === bulletin.itemId)) {
      return this.onUncheckedSelectedBulletin(bulletin);
    }
    bulletin.selected = true;

    this.FBulletinsSelected.push(bulletin);

    this.cdr.markForCheck();
  }

  onSetSelectedAllBulletins($event: Event): void {
    const $target = <any>$event.target || null;

    this.FBulletinsResults.map(bulletin => {
      $target.checked ? this.FBulletinsSelected.push(bulletin) : this.FBulletinsSelected = [];

      bulletin.selected = $target.checked;
    });

    this.FBulletinsSelectedAll = true;
  }

  onUncheckedSelectedBulletin(bulletin: IBulletinItem) {
    if (this.FBulletinsResults) {
      this.FBulletinsResults.map(inst => {
        if (inst.itemId === bulletin.itemId) {
          inst.selected = false;
        }
      });
    }
    this.FBulletinsSelected = this.FBulletinsSelected.filter(bull => {
      return bull.itemId !== bulletin.itemId;
    });
  }

  onDeleteSelectedBulletins(): void {
    console.log(this.FTabsValue);

    if (!this.FBulletinsSelected.length) {
      return;
    }
    this.confirmationService.confirm({
      header: 'Удалить выбранные?',
      message: this.FTabsValue === this.TVTypes.Bulletins ?
        'Выбранные объявления будут удалены из избранных' :
        'Выбранные новости будут удалены из избранных',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.onDeleteSelectedBulletinsAccept();
      }
    });
  }

  onDeleteAllBulletins(): void {
    this.confirmationService.confirm({
      header: 'Удалить все?',
      message: this.FTabsValue === this.TVTypes.Bulletins ?
        'Все объявления будут удалены из избранных' :
        'Все новости будут удалены из избранных',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.onDeleteAllBulletinsAccept();
      }
    });
  }

  onDeleteSelectedBulletinsAccept(): void {
    const ItemIds = this.FBulletinsSelected.map(bulletin => {
      return {
        name: 'ids',
        value: bulletin.id
      }
    });

    this.favoritesService.removeFromFavoritesArray(ArrayToUrlParams(ItemIds)).subscribe(
      response => {
        if (this.FTabsValue === this.TVTypes.Bulletins) {
          this.onUploadFavoritesBulletins();
        }
        if (this.FTabsValue === this.TVTypes.News) {
          this.onUploadFavoritesNews();
        }
      }
    );
  }

  onDeleteAllBulletinsAccept(): void {
    const ItemIds = this.FBulletinsResults.map(bulletin => {
      return {
        name: 'ids',
        value: bulletin.id
      }
    });

    this.favoritesService.removeFromFavoritesArray(ArrayToUrlParams(ItemIds)).subscribe(
      response => {
        if (this.FTabsValue === this.TVTypes.Bulletins) {
          this.onUploadFavoritesBulletins();
        }
        if (this.FTabsValue === this.TVTypes.News) {
          this.onUploadFavoritesNews();
        }
      }
    );
  }
}
