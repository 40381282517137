import { Pipe, PipeTransform } from '@angular/core';
import { FundamentalsService } from '@app/modules/fundamentals-modules/fundamentals.service';
import { CityModel } from '@app/modules/fundamentals-modules/fundamentals.models';


@Pipe({
  name: 'bulletinCity'
})

export class BulletinCityPipe implements PipeTransform {
  constructor(
    private fundamentalsService: FundamentalsService
  ) {}

  transform(cityId: number): string {
    const citiesDependencies = this.fundamentalsService.citiesDependencies || [];

    const City = <CityModel>citiesDependencies.filter(
      item => item.id === cityId
    )[0] || null;

    return City ? City.name : '';
  }
}
