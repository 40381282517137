import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { DevicesClass } from '@app/core/classes';
import { BreadcrumbsDataModel } from '@app/core/models/breadcrumbs';
import { RoutingConfig } from '@app/routing/routing.config';
import { BulletinItemViewTypes } from '@app/shared/bulletin-item/bulletin-item.component';
import { IBulletinItem } from '@app/modules/bulletins-modules/bulletins.model';
import { ConfirmationService } from 'primeng/api';
import { BulletinsService } from '@app/modules/bulletins-modules/bulletins.service';
import { BulletinsEventBus, BulletinsStoryService } from '@app/modules/bulletins-modules/bulletins.story';
import { IAccountUserInfo } from '@app/modules/account-modules/account.model';
import { AccountEventBus, AccountStoryService } from '@app/modules/account-modules/account.story';
import { AuthStoryService } from '@app/modules/auth-modules/auth.story';
import { SkeletonViewTypes } from '@app/shared/skeleton-item/skeleton-item.component';
import { forkJoin } from 'rxjs'

enum TabsValues {
  Active,    // 0
  Expired,   // 1
  Blocked,   // 2
}

@UntilDestroy()
@Component({
  selector: 'app-account-bulletins',
  templateUrl: 'bulletins.component.html',
  styleUrls: [`./bulletins.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class BulletinsComponent extends DevicesClass implements OnInit {
  public TVTypes = TabsValues;
  public SVTypes = SkeletonViewTypes;
  public BIVTypes = BulletinItemViewTypes;
  public BreadcrumbsData: BreadcrumbsDataModel = [
    {
      title: 'Личный кабинет',
      url: '/' + RoutingConfig.Profile
    },
    {
      title: 'Мои объявления',
      url: '/' + RoutingConfig.ProfileBulletins,
      active: true
    }
  ];

  public BUser: IAccountUserInfo;
  public BLoading: boolean = true;
  public BTabsValue: TabsValues;
  public BBulletinsSelected: Array<IBulletinItem> = [];
  public BBulletinsSelectedAll: boolean;

  /**
   * Bulletins Favorites.
   */
  public BBulletinsResults: Array<IBulletinItem> = [];

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    private router: Router,
    private bulletinsService: BulletinsService,
    private bulletinsStoryService: BulletinsStoryService,
    private confirmationService: ConfirmationService,
    private accountStory: AccountStoryService,
    private authStoryService: AuthStoryService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.onAccountStoryBus();
    this.onBulletinsStoryBus();
    this.onSetTabValue(this.TVTypes.Active);
  }

  onAccountStoryBus(): void {
    this.accountStory.AccountEventBus$.pipe(untilDestroyed(this)).subscribe(
      emit => this.onAccountStoryListener(emit)
    );
  }

  onAccountStoryListener(emit: AccountEventBus): void {
    if (emit.event === 'onAccountUpdate') {
      this.BUser = emit.data;
    }
    this.cdr.markForCheck();
  }

  onTabsValueLoading(status: boolean, delay: number = 0) {
    setTimeout(() => {
      this.BLoading = status;
      this.cdr.detectChanges();
    }, delay);
  }

  onSetTabValue(value: TabsValues): void {
    if (this.BTabsValue === value) {
      return;
    }
    this.onTabsValueLoading(true);

    this.onUploadResetAll();

    if (value === this.TVTypes.Active) {
      this.onUploadActiveBulletins();
    }
    if (value === this.TVTypes.Expired) {
      this.onUploadExpiredBulletins();
    }
    if (value === this.TVTypes.Blocked) {
      this.onUploadBlockedBulletins();
    }

    this.BTabsValue = value;
  }

  onBulletinCreate(): void {
    if (!this.BUser.emailConfirmed) {
      return this.authStoryService.AuthEventBus.next({
        event: 'onAuthConfirmed'
      });
    }
    this.router.navigateByUrl(`/${RoutingConfig.Bulletins}/${RoutingConfig.Create}`).catch();
  }

  onBulletinsStoryBus(): void {
    this.bulletinsStoryService.BulletinsEventBus$.pipe(untilDestroyed(this)).subscribe(
      emit => this.onBulletinsStoryListener(emit)
    );
  }

  onBulletinsStoryListener(emit: BulletinsEventBus): void {
    if (emit.event === 'onStopPublication') {
      this.onStopPublicationBulletin(emit.data);
    }
    if (emit.event === 'onActivatePublication') {
      this.onActivatePublicationBulletin(emit.data);
    }
  }

  onUploadActiveBulletins(): void {
    this.bulletinsService.bulletinsActive().subscribe(
      response => {
        /**
         * Upload of favorites bulletins.
         */
        this.BBulletinsResults = response;
        this.cdr.markForCheck();

        /**
         * Reset of all selected checkbox.
         */
        this.BBulletinsSelectedAll = false;

        /**
         * Reset of loading.
         */
        this.onTabsValueLoading(false, 1000);
      }
    )
  }

  onUploadExpiredBulletins(): void {
    this.bulletinsService.bulletinsExpired().subscribe(
      response => {
        /**
         * Upload of favorites bulletins.
         */
        this.BBulletinsResults = response;
        this.cdr.markForCheck();

        /**
         * Reset of all selected checkbox.
         */
        this.BBulletinsSelectedAll = false;

        /**
         * Reset of loading.
         */
        this.onTabsValueLoading(false, 1000);
      }
    )
  }

  onUploadBlockedBulletins(): void {
    this.bulletinsService.bulletinsBlocked().subscribe(
      response => {
        /**
         * Upload of favorites bulletins.
         */
        this.BBulletinsResults = response;
        this.cdr.markForCheck();

        /**
         * Reset of all selected checkbox.
         */
        this.BBulletinsSelectedAll = false;

        /**
         * Reset of loading.
         */
        this.onTabsValueLoading(false, 1000);
      }
    )
  }

  onUploadResetAll(): void {
    this.BBulletinsSelectedAll = false;
    this.cdr.markForCheck();
  }

  onSetSelectedBulletin(bulletin: IBulletinItem): void {
    if (this.BBulletinsSelected.some(bull => bull.id === bulletin.id)) {
      return this.onUncheckedSelectedBulletin(bulletin);
    }
    bulletin.selected = true;

    this.BBulletinsSelected.push(bulletin);

    this.cdr.markForCheck();
  }

  onSetSelectedAllBulletins($event: Event): void {
    const $target = <any>$event.target || null;

    this.BBulletinsResults.map(bulletin => {
      $target.checked ? this.BBulletinsSelected.push(bulletin) : this.BBulletinsSelected = [];

      bulletin.selected = $target.checked;
    });

    this.BBulletinsSelectedAll = true;
  }

  onUncheckedSelectedBulletin(bulletin: IBulletinItem) {
    if (this.BBulletinsResults) {
      this.BBulletinsResults.map(inst => {
        if (inst.id === bulletin.id) {
          inst.selected = false;
        }
      });
    }
    this.BBulletinsSelected = this.BBulletinsSelected.filter(bull => {
      return bull.id !== bulletin.id;
    });
  }

  onDeleteSelectedBulletins(): void {
    if (!this.BBulletinsSelected.length) {
      return;
    }
    this.confirmationService.confirm({
      key: 'default',
      header: 'Удалить выбранные?',
      message: 'Выбранные объявления будут удалены.<br/> Восстановить их будет невозможно.',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.onDeleteSelectedBulletinsAccept();
      }
    });
  }

  onDeleteAllBulletins(): void {
    this.confirmationService.confirm({
      key: 'default',
      header: 'Удалить все?',
      message: 'Все объявления будут удалены.<br/> Восстановить их будет невозможно.',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.onDeleteAllBulletinsAccept();
      }
    });
  }

  onStopPublicationBulletin(id: number): void {
    this.confirmationService.confirm({
      key: 'publication',
      header: 'Снять с публикации?',
      message: 'Вы действительно хотите снять с публикации объявление?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.onStopPublicationBulletinAccept(id);
      }
    });
  }

  onActivatePublicationBulletin(id: number): void {
    this.confirmationService.confirm({
      key: 'activate',
      header: 'Активировать объявление?',
      message: 'Вы действительно хотите повторно активировать объявление?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.onActivatePublicationBulletinAccept(id);
      }
    });
  }

  onDeleteSelectedBulletinsAccept(): void {
    const collectionAPI: any = [];

    this.BBulletinsSelected.map(bulletin => {
      collectionAPI.push(
        this.bulletinsService.bulletinDelete(bulletin.id)
      )
    });

    forkJoin(collectionAPI).subscribe(
      collectionAPI => {
        if (this.BTabsValue === this.TVTypes.Active) {
          this.onUploadActiveBulletins();
        }
        if (this.BTabsValue === this.TVTypes.Expired) {
          this.onUploadExpiredBulletins();
        }
        if (this.BTabsValue === this.TVTypes.Blocked) {
          this.onUploadBlockedBulletins();
        }
      }
    );
  }

  onDeleteAllBulletinsAccept(): void {
    const collectionAPI: any = [];

    this.BBulletinsResults.map(bulletin => {
      collectionAPI.push(
        this.bulletinsService.bulletinDelete(bulletin.id)
      )
    });

    forkJoin(collectionAPI).subscribe(
      collectionAPI => {
        if (this.BTabsValue === this.TVTypes.Active) {
          this.onUploadActiveBulletins();
        }
        if (this.BTabsValue === this.TVTypes.Expired) {
          this.onUploadExpiredBulletins();
        }
        if (this.BTabsValue === this.TVTypes.Blocked) {
          this.onUploadBlockedBulletins();
        }
      }
    );
  }

  onStopPublicationBulletinAccept(id: number): void {
    this.bulletinsService.bulletinStop(id).subscribe(
      response => this.onUploadActiveBulletins(),
      error => {
        console.log(error);
      }
    );
  }

  onActivatePublicationBulletinAccept(id: number): void {
    this.bulletinsService.bulletinActivate(id).subscribe(
      response => this.onUploadExpiredBulletins(),
      error => {
        console.log(error);
      }
    );
  }

  get emptyCupDescription(): string {
    if (this.BTabsValue === this.TVTypes.Active) {
      return 'активных';
    }
    if (this.BTabsValue === this.TVTypes.Expired) {
      return 'завершенных';
    }
    if (this.BTabsValue === this.TVTypes.Blocked) {
      return 'заблокированных';
    }
  }
}
