import { AppApiURL } from '@app/app.settings';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Observable, Subject, Subscription, BehaviorSubject, throwError } from 'rxjs';
import { map, catchError, switchMap} from 'rxjs/operators';
import { HttpRequestToken, HttpRequestFile } from '@app/core/classes';
import { TokenService } from '@app/core/services';
import { AuthTokensRefreshRequest, AuthResponseSuccess } from '@app/modules/auth-modules/auth.model';
import { AuthStoryService } from '@app/modules/auth-modules/auth.story';
import { AccountStoryService } from '@app/modules/account-modules/account.story';

@Injectable()

export class HttpRequestInterceptor implements HttpInterceptor {
  private static accessTokenError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private authStory: AuthStoryService,
    private accountStory: AccountStoryService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = this.tokenService.accessToken || null;
    const skipToken = request.params instanceof HttpRequestToken && request.params.skipToken;
    const skipContentType = request.params instanceof HttpRequestFile && request.params.skipContentType;

    if (!request.headers.has('Content-Type') && !skipContentType) {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json')
      });
    }

    if (accessToken && !skipToken) {
      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + accessToken)
      });
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        /**
         * Refresh token handler.
         */
        if ((accessToken && !skipToken) && (error.status === 401/* || error.status === 403*/)) {
          if (!HttpRequestInterceptor.accessTokenError$.getValue()) {
            /**
             * Trigger for HTTP queue.
             */
            HttpRequestInterceptor.accessTokenError$.next(true);

            return this.handleUnauthorized(request, next);

          } else {
            return this.waitRefresh().pipe(
              switchMap((event: any) => {
                const accessToken = this.tokenService.accessToken || null;

                const newRequest = request.clone({
                  headers: request.headers.set('Authorization', 'Bearer ' + accessToken)
                });
                return next.handle(newRequest);
              })
            );
          }
        }
        /**
         * Return error.
         */
        return throwError(error);
      })
    );
  }



  handleUnauthorized (request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const refreshToken = this.tokenService.refreshToken || null;

    return this.tokensRefresh({refreshToken: refreshToken}).pipe(
      switchMap( (response: AuthResponseSuccess) => {
        /**
         * Set of Access Token.
         * @type string
         */
        this.tokenService.onAccessToken(response.accessToken, this.tokenService.accessTokenRemember);

        /**
         * Set of Refresh Token.
         * @type string
         */
        this.tokenService.onRefreshToken(response.refreshToken, this.tokenService.refreshTokenRemember);

        /**
         * Set of Expires Token.
         * @type string
         */
        this.tokenService.onExpires(response.expires, this.tokenService.expiresRemember);

        /**
         * Trigger for HTTP queue.
         */
        HttpRequestInterceptor.accessTokenError$.next(false);

        /**
         * Clone HTTP request with updated token
         */
        request = request.clone({
          headers: request.headers.set('Authorization', 'Bearer ' + response.accessToken)
        });

        return next.handle(request);
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.authStory.AuthEventBus.next({
            event: 'onAuthLogout'
          });
          this.accountStory.AccountEventBus.next({
            event: 'onAccountLogout'
          })
        }

        this.accountStory.AccountEventBus.next({
          event: 'onAccountEmpty'
        });

        return throwError(error);
      })
    );
  }


  /**
   * Wait until get the new access/refresh token.
   */
  private waitRefresh(): Observable<any> {
    const subject = new Subject<any>();
    const waitToken$: Subscription = HttpRequestInterceptor.accessTokenError$.subscribe((error: boolean) => {
      if(!error) {
        subject.next();
        waitToken$.unsubscribe();
      }
    });
    return subject.asObservable();
  }

  /**
   * Api: v01.
   * Method: Auth Tokens Refresh.
   * @param request
   */
  public tokensRefresh(request: AuthTokensRefreshRequest): Observable<AuthResponseSuccess> {
    return this.http.post<any>(AppApiURL + '/auth/tokens/refresh', request,
      {
        params: new HttpRequestToken(true)
      }
    )
  }
}
