import { Component, Injector, HostListener, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';

export enum DeviceTypes {
  Mobile,   // 0
  Tablet,   // 1
  Desktop,  // 2
}

@UntilDestroy()
@Component({
  template: '',
})

export class DevicesClass {
  public cdr: ChangeDetectorRef;
  public deviceSubject: BehaviorSubject<number> = new BehaviorSubject<any>(0);
  public deviceValue: BehaviorSubject<DeviceTypes> = new BehaviorSubject<DeviceTypes>(DevicesClass.getUserDevice());
  public DTypes = DeviceTypes;

  constructor(injector: Injector) {
    this.cdr = injector.get(ChangeDetectorRef);
    this.onUserDevice();
  }

  @HostListener('window:resize', ['$event'])
  onResizeEvent($event): void {
    this.deviceSubject.next(DevicesClass.getUserDevice());
  }

  onUserDevice(): void {
    this.deviceSubject.pipe(debounceTime(0)).subscribe(
      value => {
        this.deviceValue.next(DevicesClass.getUserDevice());
      }
    );
  }

  private static mobileUserAgent(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  private static getUserDevice(): DeviceTypes {
    if (window.innerWidth < 768) {
      return 0;
    }
    if (window.innerWidth >= 768 && DevicesClass.mobileUserAgent()) {
      return 1;
    }
    if (window.innerWidth >= 768 && !DevicesClass.mobileUserAgent()) {
      return 2;
    }
    return 2;
  }
}
