import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { DevicesClass } from '@app/core/classes';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CitiesService } from '@app/modules/cities-module/cities.service';
import { CitiesModel, CityModel } from '@app/modules/fundamentals-modules/fundamentals.models';
import { CitiesStoryService } from '@app/modules/cities-module/cities.story';
import { FundamentalsService } from '@app/modules/fundamentals-modules/fundamentals.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DialogIncludesTypes, DialogComponentModel } from '@app/core/models/diallog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { debounceTime, map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-search-city',
  templateUrl: 'search-city.component.html',
  styleUrls: [`./search-city.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SearchCityComponent extends DevicesClass implements OnInit {
  /**
   * Base component variables.
   */
  public SCForm: FormGroup;
  public SCResult: CitiesModel = this.fundamentalsService.citiesRecommendations;
  public SCInputConfig: DialogComponentModel = null;
  public SCValue: CityModel = null;
  public SCButtonDisabled: boolean = true;
  public SCTooltipDisabled: boolean = false;

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    private router: Router,
    private citiesService: CitiesService,
    private fundamentalsService: FundamentalsService,
    private citiesStory: CitiesStoryService,
    private formBuilder: FormBuilder,
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.SCForm = this.formBuilder.group({
      city: ['']
    });

    /**
     * Form changes control.
     */
    this.onSearchCityCtrl();

    /**
     * Dialog input config.
     */
    this.onSearchCityInputConfig();
  }

  /**
   * Apply Dialog Input config.
   * @type DialogComponentModel
   */
  onSearchCityInputConfig(): void {
    this.SCInputConfig = <DialogComponentModel>this.dialogConfig.data || null;

    if (this.SCInputConfig && this.SCInputConfig.hasOwnProperty('includeData')) {
      const DataInitial = <CityModel>this.SCInputConfig.includeData.dataInitial || null;

      if (DataInitial) {
        this.onSearchCityPatchForm(DataInitial.id);
      }
    }
  }

  onSearchCityCtrl(): void {
    this.SCForm.valueChanges.pipe(
      untilDestroyed(this),
      map(value => {
        /**
         * Display of tooltip
         */
        value.city === '' ? this.SCTooltipDisabled = false : this.SCTooltipDisabled = true;

        return value;
      }),
      debounceTime(500)
    ).subscribe(value => {
      if (value.city === '') {
        return this.onApplySearchCityResult(
          this.fundamentalsService.citiesRecommendations
        )
      }
      this.onSearchCityAPI(value.city);
    });
  }

  onSearchCityAPI(cityName: string): void {
    this.citiesService.citiesSearch(cityName).subscribe(
      response => this.onApplySearchCityResult(response))
  }

  onSearchCityPatchForm(cityId: number): void {
    const CityValue = this.citiesService.onGetCityByDictionary(cityId);

    if (CityValue && CityValue.name !== '') {
      this.SCValue = CityValue;

      this.SCForm.controls.city.setValue(
        CityValue.name, { emitEvent: false }
      );

      this.SCButtonDisabled = false;
    }
  }

  onViewAllCities($event: Event): void {
    $event.preventDefault();

    this.onApplySearchCityResult(
      this.fundamentalsService.citiesDependencies
    );
  }

  onInputKeyup($event: KeyboardEvent): void {
    if ($event.key === 'Backspace' || $event.key === 'Delete') {
      this.SCButtonDisabled = true;
      this.cdr.markForCheck();
    }
  }

  onApplySearchCityResult(cities: CitiesModel): void {
    this.SCResult = cities.length ? cities : null;
    this.cdr.markForCheck();
  }

  onSubmitForm(): void {
    if (this.SCButtonDisabled || !this.SCValue) {
      return;
    }

    /**
     * Callback if global using.
     * @type PopupTemplate
     */
    if (this.SCInputConfig.includeType === DialogIncludesTypes.PopupTemplate) {
      this.citiesService.onSaveSelectedCity(this.SCValue);

      this.citiesStory.CitiesEventBus.next({
        event: 'onSelectCityApp',
        data: this.citiesService.onGetCityByDictionary(this.SCValue.id) || null
      });
    }

    /**
     * Callback if using in component.
     * @type ComponentTemplate
     */
    if (this.SCInputConfig.includeType === DialogIncludesTypes.ComponentTemplate) {
      this.SCInputConfig.includeData.dataSubject.next(this.SCValue);
    }

    /**
     * Close popup.
     */
    this.dialogRef.close();
  }
}
