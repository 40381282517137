import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, Input } from '@angular/core';
import { DevicesClass } from '@app/core/classes';
import { Router, ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OAuthSignResponse } from '@app/modules/auth-modules/auth.model';
import { StorageService } from '@app/core/services';
import { StorageOAuth } from '@app/core/constants'

@UntilDestroy()
@Component({
  selector: 'app-sign-oauth',
  templateUrl: 'sign-oauth.component.html',
  styleUrls: [`./sign-oauth.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SignOauthComponent extends DevicesClass implements OnInit {
  @Input() Data: any;

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private storage: StorageService              
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.route.queryParamMap.pipe(untilDestroyed(this)).subscribe((params: any) => {
      const oauthParams = <OAuthSignResponse>params.params || null;

      if (oauthParams) {
        this.onOAuthQueryEvents(<OAuthSignResponse>params.params);
      }
    });
  }

  onOAuthQueryEvents(oauthParams: OAuthSignResponse): void {
    if (oauthParams.token && oauthParams.token !== '') {
      this.onOAuthSignIn(oauthParams, StorageOAuth.externalSignIn);
    }

    if (oauthParams.OauthId && oauthParams.OauthId !== '') {
      this.onOAuthSignUp(oauthParams, StorageOAuth.externalSignUp);
    }

    if (oauthParams.error && oauthParams.error !== '') {
      this.onOAuthSignError(oauthParams, StorageOAuth.externalSignError);
    }
  }
  
  onOAuthSignIn(oauthParams: OAuthSignResponse, storageKey: string): void {
    setTimeout(() => {
      this.storage.setStorage({
        key: storageKey,
        value: JSON.stringify(oauthParams)
      }, 'local');

      (<any>window).close()
    }, 1000);
  }

  onOAuthSignUp(oauthParams: OAuthSignResponse, storageKey: string): void {
    setTimeout(() => {
      this.storage.setStorage({
        key: storageKey,
        value: JSON.stringify(oauthParams)
      }, 'local');

      (<any>window).close()
    }, 1000);
  }

  onOAuthSignError(oauthParams: OAuthSignResponse, storageKey: string): void {
    setTimeout(() => {
      this.storage.setStorage({
        key: storageKey,
        value: JSON.stringify(oauthParams)
      }, 'local');

      (<any>window).close()
    }, 1000);
  }
}
