<div class="bulletin-item__block" *ngIf="BulletinView === BITypes.Block">
  <div class="grid align-items-center bulletin-item__block-content">

    <div class="bulletin-item__block-rouble" *ngIf="BulletinItem.isCommercial">
      <span class="mr-icon mr-icon-ribbon-ruble">
        <span class="path1"></span>
        <span class="path2"></span>
      </span>
    </div>

    <div class="col-9 align-self-start">
      <a class="bulletin-item__block-heading"
        routerLink="/{{BIRouting.Bulletins}}/{{BIRouting.Detail}}/{{BulletinItem.itemId || BulletinItem.id}}"
        target="{{onViewDetailBulletin()}}"
        ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'pb-3' : 'pb-2'}}">

        <p>{{BulletinItem | bulletinHeading:'searchType'}}</p>
      </a>

      <div class="bulletin-item__block-row">
        <div class="grid align-self-center flex-wrap">
          <div class="col-auto align-self-center" ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'mb-2' : 'mb-1'}}">
            <p class="__title mr-2">Стиль:</p>
          </div>
          <ng-container *ngFor="let genre of BulletinItem.genreIds">
            <div class="col-auto" ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'mb-2' : 'mb-1'}}">
              <p-chip label="{{genre | bulletinGenre}}" styleClass="bulletin-search__chip"></p-chip>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="bulletin-item__block-row"
        ngClass="{{(deviceValue | async) === DTypes.Desktop ? '' : 'mt-1'}}">
        <div class="grid align-self-center flex-wrap">
          <div class="col-auto mb-2 align-self-center">
            <p class="__title mr-2">Город:</p>
          </div>
          <div class="col-auto mb-2">
            <p>{{BulletinItem.cityId | bulletinCity}}</p>
          </div>
        </div>
      </div>

      <div class="bulletin-item__block-row">
        <div class="grid align-self-center flex-wrap">
          <div class="col-auto mb-2 align-self-center">
            <p class="__title mr-2">Коммерческий проект:</p>
          </div>
          <div class="col-auto mb-2">
            <p>{{BulletinItem.isCommercial ? 'Да' : 'Нет'}}</p>
          </div>
        </div>
      </div>

      <div class="bulletin-item__block-row">
        <div class="grid align-self-center flex-wrap">
          <div class="col-auto mb-2 align-self-center">
            <p class="__title mr-2">Кавер-группа:</p>
          </div>
          <div class="col-auto mb-2">
            <p>{{BulletinItem.isCover ? 'Да' : 'Нет'}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <a class="bulletin-item__block-picture"
        routerLink="/{{BIRouting.Bulletins}}/{{BIRouting.Detail}}/{{BulletinItem.itemId || BulletinItem.id}}"
        target="{{onViewDetailBulletin()}}">
        <div class="mr-icon mr-icon-{{BulletinItem.instrumentId | bulletinPreview}}"></div>
      </a>
    </div>
  </div>
  <div class="grid align-items-center bulletin-item__block-event">
    <div class="col-auto bulletin-item__block-event__date">
      <span class="mr-icon mr-icon-clock"></span>
      <span>{{BulletinItem.created | formatDate}}</span>
    </div>
    <div class="col-auto bulletin-item__block-event__favorites animated fast zoomIn" *ngIf="BIFavLoading">
      <div class="ui-loader__base"></div>
    </div>
    <div class="col-auto bulletin-item__block-event__favorites" *ngIf="!BIFavLoading && !BIFavActive" (click)="onAddToFavorites()">
      <span class="mr-icon mr-icon-favorites"></span>
      <span>В избранное</span>
    </div>
    <div class="col-auto bulletin-item__block-event__favorites" *ngIf="!BIFavLoading && BIFavActive" (click)="onRemoveFromFavorites()">
      <span class="mr-icon mr-icon-favorites-active"></span>
      <span>В избранном</span>
    </div>
  </div>
</div>


<div class="bulletin-item__block related" *ngIf="BulletinView === BITypes.Related">
  <div class="grid align-items-center bulletin-item__block-content">

    <div class="bulletin-item__block-rouble" *ngIf="BulletinItem.isCommercial">
      <span class="mr-icon mr-icon-ribbon-ruble">
        <span class="path1"></span>
        <span class="path2"></span>
      </span>
    </div>

    <div class="col-12 align-self-start">
      <a class="bulletin-item__block-heading"
        routerLink="/{{BIRouting.Bulletins}}/{{BIRouting.Detail}}/{{BulletinItem.itemId || BulletinItem.id}}"
        target="{{onViewDetailBulletin()}}"
        ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'pb-3' : 'pb-2'}}">

        <p>{{BulletinItem | bulletinHeading:'searchType'}}</p>
      </a>

      <div class="bulletin-item__block-row">
        <div class="grid align-self-center flex-wrap">
          <div class="col-auto align-self-center" ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'mb-2' : 'mb-1'}}">
            <p class="__title mr-2">Стиль:</p>
          </div>
          <ng-container *ngFor="let genre of BulletinItem.genreIds">
            <div class="col-auto" ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'mb-2' : 'mb-1'}}">
              <p-chip label="{{genre | bulletinGenre}}" styleClass="bulletin-search__chip"></p-chip>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="bulletin-item__block-row"
        ngClass="{{(deviceValue | async) === DTypes.Desktop ? '' : 'mt-1'}}">
        <div class="grid align-self-center flex-wrap">
          <div class="col-auto mb-2 align-self-center">
            <p class="__title mr-2">Город:</p>
          </div>
          <div class="col-auto mb-2">
            <p>{{BulletinItem.cityId | bulletinCity}}</p>
          </div>
        </div>
      </div>

      <div class="bulletin-item__block-row">
        <div class="grid align-self-center flex-wrap">
          <div class="col-auto mb-2 align-self-center">
            <p class="__title mr-2">Коммерческий проект:</p>
          </div>
          <div class="col-auto mb-2">
            <p>{{BulletinItem.isCommercial ? 'Да' : 'Нет'}}</p>
          </div>
        </div>
      </div>

      <div class="bulletin-item__block-row">
        <div class="grid align-self-center flex-wrap">
          <div class="col-auto mb-2 align-self-center">
            <p class="__title mr-2">Кавер-группа:</p>
          </div>
          <div class="col-auto mb-2">
            <p>{{BulletinItem.isCover ? 'Да' : 'Нет'}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="grid align-items-center bulletin-item__block-event">
    <div class="col-auto bulletin-item__block-event__date">
      <span class="mr-icon mr-icon-clock"></span>
      <span>{{BulletinItem.created | formatDate}}</span>
    </div>
    <div class="col-auto bulletin-item__block-event__favorites animated fast zoomIn" *ngIf="BIFavLoading">
      <div class="ui-loader__base"></div>
    </div>
    <div class="col-auto bulletin-item__block-event__favorites" *ngIf="!BIFavLoading && !BIFavActive" (click)="onAddToFavorites()">
      <span class="mr-icon mr-icon-favorites"></span>
      <span>В избранное</span>
    </div>
    <div class="col-auto bulletin-item__block-event__favorites" *ngIf="!BIFavLoading && BIFavActive" (click)="onRemoveFromFavorites()">
      <span class="mr-icon mr-icon-favorites-active"></span>
      <span>В избранном</span>
    </div>
  </div>
</div>


<div class="bulletin-item__list" *ngIf="BulletinView === BITypes.List">
  <div class="bulletin-item__list-content">
    <a class="bulletin-item__list-heading"
      routerLink="/{{BIRouting.Bulletins}}/{{BIRouting.Detail}}/{{BulletinItem.itemId || BulletinItem.id}}"
      target="{{onViewDetailBulletin()}}"
      ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'pb-3' : 'pb-2'}}">

      <p>{{BulletinItem | bulletinHeading:'searchType'}}</p>
    </a>

    <div class="grid align-items-center flex-wrap">
      <div class="col-auto bulletin-item__list-row mr-1">
        <div class="grid align-self-center flex-wrap">
          <div class="col-auto mb-2 align-self-center">
            <p class="__title mr-2">Стиль:</p>
          </div>
          <ng-container *ngFor="let genre of BulletinItem.genreIds">
            <div class="col-auto mb-2">
              <p-chip label="{{genre | bulletinGenre}}" styleClass="bulletin-search__chip"></p-chip>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="col-auto bulletin-item__list-row mr-4">
        <div class="grid align-self-center flex-wrap">
          <div class="col-auto mb-2 align-self-center">
            <p class="__title mr-2">Город:</p>
          </div>
          <div class="col-auto mb-2">
            <p>{{BulletinItem.cityId | bulletinCity}}</p>
          </div>
        </div>
      </div>

      <div class="col-auto bulletin-item__list-row mr-4">
        <div class="grid align-self-center flex-wrap">
          <div class="col-auto mb-2 align-self-center">
            <p class="__title mr-2">Коммерческий проект:</p>
          </div>
          <div class="col-auto mb-2">
            <p>{{BulletinItem.isCommercial ? 'Да' : 'Нет'}}</p>
          </div>
        </div>
      </div>

      <div class="col-auto bulletin-item__list-row mr-4">
        <div class="grid align-self-center flex-wrap">
          <div class="col-auto mb-2 align-self-center">
            <p class="__title mr-2">Кавер-группа:</p>
          </div>
          <div class="col-auto mb-2">
            <p>{{BulletinItem.isCover ? 'Да' : 'Нет'}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="grid align-items-start justify-content-center flex-column bulletin-item__list-event">
    <div class="col-auto bulletin-item__list-event__date">
      <span class="mr-icon mr-icon-clock"></span>
      <span>{{BulletinItem.created | formatDate}}</span>
    </div>

    <div class="col-auto bulletin-item__list-event__favorites animated fast zoomIn" *ngIf="BIFavLoading">
      <div class="ui-loader__base"></div>
    </div>
    <div class="col-auto bulletin-item__list-event__favorites" *ngIf="!BIFavLoading && !BIFavActive" (click)="onAddToFavorites()">
      <span class="mr-icon mr-icon-favorites"></span>
      <span>В избранное</span>
    </div>
    <div class="col-auto bulletin-item__list-event__favorites" *ngIf="!BIFavLoading && BIFavActive" (click)="onRemoveFromFavorites()">
      <span class="mr-icon mr-icon-favorites-active"></span>
      <span>В избранном</span>
    </div>
  </div>
</div>


<div class="bulletin-item__detail" *ngIf="BulletinView === BITypes.Detail">
  <div class="bulletin-item__detail-favorites" *ngIf="onBulletinPublish === BIPublish.Published && !BIFavActive"
    (click)="onAddToFavorites()">

    <div class="bulletin-item__detail-favorites__background">
      <span class="mr-icon mr-icon-ribbon"></span>
    </div>
    <div class="bulletin-item__detail-favorites__event">
      <span class="mr-icon mr-icon-favorites" *ngIf="!BIFavLoading"></span>
      <div class="ui-loader__base" *ngIf="BIFavLoading"></div>
    </div>
    <div class="bulletin-item__detail-favorites__text"
      ngClass="{{BIFavLoading ? 'hide' : 'view'}}">
      <span class="pt-1">В избранное</span>
    </div>
  </div>

  <div class="bulletin-item__detail-favorites" *ngIf="onBulletinPublish === BIPublish.Published && BIFavActive"
    (click)="onRemoveFromFavorites()">

    <div class="bulletin-item__detail-favorites__background">
      <span class="mr-icon mr-icon-ribbon"></span>
    </div>
    <div class="bulletin-item__detail-favorites__event">
      <span class="mr-icon mr-icon-favorites-active" *ngIf="!BIFavLoading"></span>
      <div class="ui-loader__base" *ngIf="BIFavLoading"></div>
    </div>
    <div class="bulletin-item__detail-favorites__text"
      ngClass="{{BIFavLoading ? 'hide' : 'view'}}">
      <span class="pt-1">В избранном</span>
    </div>
  </div>

  <div class="bulletin-item__detail-info">
    <div class="pg-wrapper">
      <div class="row" ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'pt-6 pb-2' : 'pt-4 pb-2'}}">
        <div class="bulletin-item__detail-heading">
          <h1>{{BulletinDetail | bulletinHeading:'detailType'}}</h1>
        </div>
      </div>

      <div class="row" ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'pb-4' : 'pb-4'}}">
        <div class="grid align-items-center justify-content-center"
          ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-wrap' : ''}}">

          <div class="bulletin-item__detail-icons col-auto">
            <span class="mr-icon mr-icon-clock"></span>
            <span>{{BulletinDetail.bulletinCreateDate | formatDate}}</span>
          </div>
          <div class="bulletin-item__detail-icons col-auto" *ngIf="onBulletinPublish === BIPublish.Published">
            <span class="mr-icon mr-icon-view"></span>
            <span>Просмотры: {{BulletinDetail.viewCount}}</span>
          </div>
          <div class="bulletin-item__detail-icons col-auto" *ngIf="onBulletinPublish === BIPublish.Published">
            <span class="mr-icon mr-icon-favorites"></span>
            <span>В избранном: {{BulletinDetail.favoritesCount}}</span>
          </div>
          <div class="bulletin-item__detail-icons col-auto" *ngIf="onBulletinPublish === BIPublish.Published && BulletinDetail.daysToExpired">
            <span class="mr-icon mr-icon-status-wait"></span>
            <span>Осталось: {{BulletinDetail.daysToExpired | declination:'days'}}</span>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="onBulletinPublish === BIPublish.Banned">
        <div class="bulletin-item__detail-publish status-error">
          <div class="grid align-items-center justify-content-center flex-column">
            <div class="col-10 col-sm-12 text-center" *ngIf="onBulletinModeration === BIModeration.Law">
              <span class="mr-icon mr-icon-status-error mt-2 mb-4"></span>
              <h5>{{BulletinDetail.blockMessage || 'Объявление заблокировано по причине нарушения законодательства.'}}</h5>
            </div>
            <div class="col-10 col-sm-12 text-center" *ngIf="onBulletinModeration === BIModeration.SiteRules">
              <span class="mr-icon mr-icon-status-error mt-2 mb-4"></span>
              <h5>{{BulletinDetail.blockMessage || 'Объявление заблокировано по причине нарушения правил сайта'}}</h5>
            </div>
            <div class="col-10 col-sm-12 text-center" *ngIf="onBulletinModeration === BIModeration.Spam">
              <span class="mr-icon mr-icon-status-error mt-2 mb-4"></span>
              <h5>{{BulletinDetail.blockMessage || 'Объявление заблокировано. Спам.'}}</h5>
            </div>
            <div class="col-10 col-sm-12 text-center" *ngIf="onBulletinModeration === BIModeration.Other">
              <span class="mr-icon mr-icon-status-error mt-2 mb-4"></span>
              <h5>{{BulletinDetail.blockMessage}}</h5>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="onBulletinPublish === BIPublish.Expired">
        <div class="bulletin-item__detail-publish status-warning">
          <div class="grid align-items-center justify-content-center flex-column">
            <div class="col-10 col-sm-12 text-center">
              <span class="mr-icon mr-icon-clock mt-2 mb-4"></span>
              <h5>
                Срок размещения объявления истек.<br/>
                <!--Если объявление еще актуально опубликуйте его повторно и при необходимости обновите информацию.-->
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="onBulletinPublish === BIPublish.ClosedByUser">
        <div class="bulletin-item__detail-publish status-warning">
          <div class="grid align-items-center justify-content-center flex-column">
            <div class="col-10 col-sm-12 text-center">
              <span class="mr-icon mr-icon-info mt-2 mb-4"></span>
              <h5>Закрыто пользователем</h5>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="onBulletinPublish === BIPublish.OnModeration">
        <div class="bulletin-item__detail-publish status-warning">
          <div class="grid align-items-center justify-content-center flex-column">
            <div class="col-10 col-sm-12 text-center">
              <span class="mr-icon mr-icon-status-wait mt-2 mb-4"></span>
              <h5>
                Ваше объявление еще не прошло модерацию.<br/>
                Сразу после успешного прохождения оно станет доступно в поиске.
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="grid align-items-center"
          ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

          <div class="col-3 align-self-end col-sm-12"
            ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'order-1 mt-4' : ''}}">

            <div class="bulletin-item__detail-performer animated fast fadeIn"
              ngClass="{{BulletinDetail.performerPromoViewModel.publicUrl ? 'performer-link' : ''}}"
              (click)="onRouteToPublicURL()">

              <div class="bulletin-item__detail-performer__avatar" *ngIf="BulletinDetail.userPicUrl">
                <img src="{{StaticURL}}{{BulletinDetail.userPicUrl}}" onError="this.src='assets/images/others/balalaika_light.svg'">
              </div>
              <div class="bulletin-item__detail-performer__avatar" *ngIf="!BulletinDetail.userPicUrl">
                <img src="assets/images/others/balalaika_light.svg">
              </div>
              <div class="bulletin-item__detail-performer__heading">
                <h5>{{BulletinDetail | bulletinPerformer}}</h5>
                <p class="txt-caption__large">{{BulletinDetail | bulletinInstrument}}</p>
              </div>
            </div>
          </div>

          <div class="col-9 no-padding col-sm-12"
            ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'order-0 mb-4' : ''}}">
            <div class="bulletin-item__detail-row"
              ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-1' : 'mt-1 mb-1'}}">
              <div class="grid align-self-center flex-wrap">
                <div class="col-auto mb-2 align-self-center">
                  <p class="__title mr-2">Город:</p>
                </div>
                <div class="col-auto mb-2" *ngIf="BulletinDetail.type === 'ArtistSearchBand'">
                  <p>{{BulletinDetail.artistAboutViewModel.cityId | bulletinCity}}</p>
                </div>
                <div class="col-auto mb-2" *ngIf="BulletinDetail.type === 'BandSearchArtist'">
                  <p>{{BulletinDetail.bandAboutViewModel.cityId | bulletinCity}}</p>
                </div>
              </div>
            </div>

            <div class="bulletin-item__detail-row"
              ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-1 mb-2' : 'mt-1 mb-1'}}">
              <div class="grid align-self-center flex-wrap" *ngIf="BulletinDetail.type === 'ArtistSearchBand'">
                <div class="col-auto align-self-center" ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'mb-2' : 'mb-1'}}">
                  <p class="__title mr-2">Стиль:</p>
                </div>
                <ng-container *ngFor="let genre of BulletinDetail.artistAboutViewModel.genreIds">
                  <div class="col-auto" ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mb-2' : 'mb-2'}}">
                    <p-chip label="{{genre | bulletinGenre}}" styleClass="bulletin-detail__chip"></p-chip>
                  </div>
                </ng-container>
              </div>

              <div class="grid align-self-center flex-wrap" *ngIf="BulletinDetail.type === 'BandSearchArtist'">
                <div class="col-auto align-self-center" ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'mb-2' : 'mb-1'}}">
                  <p class="__title mr-2">Стиль:</p>
                </div>
                <ng-container *ngFor="let genre of BulletinDetail.bandAboutViewModel.genreIds">
                  <div class="col-auto" ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mb-2' : 'mb-2'}}">
                    <p-chip label="{{genre | bulletinGenre}}" styleClass="bulletin-detail__chip"></p-chip>
                  </div>
                </ng-container>
              </div>
            </div>

            <div class="row" *ngIf="BulletinDetail.type === 'ArtistSearchBand'"
              ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-3 mb-1' : 'mt-1 mb-3'}}">
              <div class="grid justify-content-between"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

                <div class="col-6 col-sm-12 no-padding"
                  ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'mr-2' : ''}}">

                  <div class="bulletin-item__detail-table">
                    <div class="bulletin-item__detail-table-tr">
                      <div class="bulletin-item__detail-table-td">
                        <p>Опыт/Стаж группы:</p>
                      </div>
                      <div class="bulletin-item__detail-table-td">
                        <p>{{BulletinDetail | bulletinSearchExperience}}</p>
                      </div>
                    </div>
                    <div class="bulletin-item__detail-table-tr">
                      <div class="bulletin-item__detail-table-td">
                        <p>Концертный опыт группы:</p>
                      </div>
                      <div class="bulletin-item__detail-table-td">
                        <p>{{BulletinDetail | bulletinSearchConcertExperience}}</p>
                      </div>
                    </div>
                    <div class="bulletin-item__detail-table-tr">
                      <div class="bulletin-item__detail-table-td">
                        <p>Мой опыт/стаж:</p>
                      </div>
                      <div class="bulletin-item__detail-table-td">
                        <p>{{BulletinDetail | bulletinAboutExperience}}</p>
                      </div>
                    </div>
                    <div class="bulletin-item__detail-table-tr">
                      <div class="bulletin-item__detail-table-td">
                        <p>Концертный опыт:</p>
                      </div>
                      <div class="bulletin-item__detail-table-td">
                        <p>{{BulletinDetail | bulletinAboutConcertExperience}}</p>
                      </div>
                    </div>
                    <div class="bulletin-item__detail-table-tr">
                      <div class="bulletin-item__detail-table-td"></div>
                      <div class="bulletin-item__detail-table-td"></div>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-12 no-padding"
                  ngClass="{{(deviceValue | async) === DTypes.Desktop ? '' : ''}}">

                  <div class="bulletin-item__detail-table"
                    ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'revert' : ''}}">
                    <div class="bulletin-item__detail-table-tr">
                      <div class="bulletin-item__detail-table-td">
                        <p>Есть собственный инструмент:</p>
                      </div>
                      <div class="bulletin-item__detail-table-td">
                        <p>{{BulletinDetail.artistAboutViewModel.hasInstrument ? "да" : "нет"}}</p>
                      </div>
                    </div>
                    <div class="bulletin-item__detail-table-tr">
                      <div class="bulletin-item__detail-table-td">
                        <p>У группы есть собственный материал:</p>
                      </div>
                      <div class="bulletin-item__detail-table-td">
                        <p>{{BulletinDetail.artistRequestViewModel.bandHasPromo ? "да" : "нет"}}</p>
                      </div>
                    </div>
                    <div class="bulletin-item__detail-table-tr">
                      <div class="bulletin-item__detail-table-td">
                        <p>Кавер-группа:</p>
                      </div>
                      <div class="bulletin-item__detail-table-td">
                        <p>{{BulletinDetail.artistRequestViewModel.isCover ? "да" : "нет"}}</p>
                      </div>
                    </div>
                    <div class="bulletin-item__detail-table-tr">
                      <div class="bulletin-item__detail-table-td">
                        <p>Коммерческий проект:</p>
                      </div>
                      <div class="bulletin-item__detail-table-td">
                        <p>{{BulletinDetail.artistRequestViewModel.isComercial ? "да" : "нет"}}</p>
                      </div>
                    </div>
                    <div class="bulletin-item__detail-table-tr">
                      <div class="bulletin-item__detail-table-td">
                        <p>У группы есть репетиционная база:</p>
                      </div>
                      <div class="bulletin-item__detail-table-td">
                        <p>{{BulletinDetail.artistRequestViewModel.bandHasBase ? "да" : "нет"}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="BulletinDetail.type === 'BandSearchArtist'"
              ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'mt-3 mb-1' : 'mt-1'}}">
              <div class="grid justify-content-between"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

                <div class="col-6 col-sm-12 no-padding"
                  ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'mr-2' : ''}}">

                  <div class="bulletin-item__detail-table">
                    <div class="bulletin-item__detail-table-tr">
                      <div class="bulletin-item__detail-table-td">
                        <p>Опыт/Стаж группы:</p>
                      </div>
                      <div class="bulletin-item__detail-table-td">
                        <p>{{BulletinDetail | bulletinAboutExperience}}</p>
                      </div>
                    </div>
                    <div class="bulletin-item__detail-table-tr">
                      <div class="bulletin-item__detail-table-td">
                        <p>Концертный опыт группы:</p>
                      </div>
                      <div class="bulletin-item__detail-table-td">
                        <p>{{BulletinDetail | bulletinAboutConcertExperience}}</p>
                      </div>
                    </div>
                    <div class="bulletin-item__detail-table-tr">
                      <div class="bulletin-item__detail-table-td">
                        <p>Ищем в возрасте:</p>
                      </div>
                      <div class="bulletin-item__detail-table-td">
                        <p>{{BulletinDetail | bulletinAge}}</p>
                      </div>
                    </div>
                    <div class="bulletin-item__detail-table-tr">
                      <div class="bulletin-item__detail-table-td">
                        <p>Опыт/Стаж музыканта:</p>
                      </div>
                      <div class="bulletin-item__detail-table-td">
                        <p>{{BulletinDetail | bulletinSearchExperience}}</p>
                      </div>
                    </div>
                    <div class="bulletin-item__detail-table-tr">
                      <div class="bulletin-item__detail-table-td">
                        <p>Концертный опыт музыканта:</p>
                      </div>
                      <div class="bulletin-item__detail-table-td">
                        <p>{{BulletinDetail | bulletinSearchConcertExperience}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-12 no-padding"
                  ngClass="{{(deviceValue | async) === DTypes.Desktop ? '' : ''}}">

                  <div class="bulletin-item__detail-table"
                    ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'revert' : ''}}">
                    <div class="bulletin-item__detail-table-tr">
                      <div class="bulletin-item__detail-table-td">
                        <p>Есть собственный материал:</p>
                      </div>
                      <div class="bulletin-item__detail-table-td">
                        <p>{{BulletinDetail.bandAboutViewModel.hasPromo ? "да" : "нет"}}</p>
                      </div>
                    </div>
                    <div class="bulletin-item__detail-table-tr">
                      <div class="bulletin-item__detail-table-td">
                        <p>Кавер-группа:</p>
                      </div>
                      <div class="bulletin-item__detail-table-td">
                        <p>{{BulletinDetail.bandAboutViewModel.isCover ? "да" : "нет"}}</p>
                      </div>
                    </div>
                    <div class="bulletin-item__detail-table-tr">
                      <div class="bulletin-item__detail-table-td">
                        <p>Коммерческий проект:</p>
                      </div>
                      <div class="bulletin-item__detail-table-td">
                        <p>{{BulletinDetail.bandAboutViewModel.isCommercial ? "да" : "нет"}}</p>
                      </div>
                    </div>
                    <div class="bulletin-item__detail-table-tr">
                      <div class="bulletin-item__detail-table-td">
                        <p>Есть репетиционная база:</p>
                      </div>
                      <div class="bulletin-item__detail-table-td">
                        <p>{{BulletinDetail.bandAboutViewModel.hasBase ? "да" : "нет"}}</p>
                      </div>
                    </div>
                    <div class="bulletin-item__detail-table-tr">
                      <div class="bulletin-item__detail-table-td">
                        <p>Собственный инструмент у музыканта:</p>
                      </div>
                      <div class="bulletin-item__detail-table-td">
                        <p>{{BulletinDetail.bandRequestViewModel.hasInstrument ? "да" : "нет"}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="bulletin-item__detail-description">
    <div class="pg-wrapper">
      <div class="grid align-items-start justify-content-end">
        <div class="col-9 col-sm-12 col-md-12 no-padding">
          <div class="bulletin-item__detail-description__wrapper">

            <div class="bulletin-item__detail-description__row">
              <div class="grid align-items-start bulletin-item__detail-description__row__content"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

                <div class="col-3 col-sm-12">
                  <div class="bulletin-item__detail-description__row__heading">
                    <div class="grid align-items-baseline">
                      <span class="mr-icon mr-icon-info"></span>
                      <h5 class="ml-2">О себе</h5>
                    </div>
                  </div>
                </div>
                <div class="col-9 col-sm-12">
                  <div class="bulletin-item__detail-description__row__text" *ngIf="!onBulletinAboutUsText()">
                    <p>Информация отсутствует</p>
                  </div>
                  <div class="bulletin-item__detail-description__row__text" *ngIf="onBulletinAboutUsText()">
                    <ng-container *ngFor="let txt of onBulletinAboutUsText()">
                      <p>{{txt}}</p>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>

            <div class="bulletin-item__detail-description__row">
              <div class="grid align-items-start bulletin-item__detail-description__row__content"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

                <div class="col-3 col-sm-12">
                  <div class="bulletin-item__detail-description__row__heading">
                    <div class="grid align-items-baseline">
                      <span class="mr-icon mr-icon-book"></span>
                      <h5 class="ml-2">Требования</h5>
                    </div>
                  </div>
                </div>
                <div class="col-9 col-sm-12">
                  <div class="bulletin-item__detail-description__row__text" *ngIf="!onBulletinRequirements()">
                    <p>Информация отсутствует</p>
                  </div>
                  <div class="bulletin-item__detail-description__row__text" *ngIf="onBulletinRequirements()">
                    <ng-container *ngFor="let txt of onBulletinRequirements()">
                      <p>{{txt}}</p>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>

            <div class="bulletin-item__detail-description__row" *ngIf="onSoundCloudTrackIds().length">
              <div class="grid align-items-start bulletin-item__detail-description__row__content"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

                <div class="col-3 col-sm-12">
                  <div class="bulletin-item__detail-description__row__heading">

                    <div class="grid align-items-baseline">
                      <span class="mr-icon mr-icon-note"></span>
                      <h5 class="ml-2">Аудио</h5>
                    </div>
                  </div>
                </div>
                <div class="col-9 col-sm-12">
                  <div class="bulletin-item__detail-description__row__text">
                    <ng-container *ngFor="let track of onSoundCloudTrackIds()">
                      <div class="frame-insert">
                        <iframe class="mb-4"
                          [src]="getSoundCloudTrackIds(track.trackId) | safe">
                        </iframe>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>

            <div class="bulletin-item__detail-description__row" *ngIf="onBulletinYouTube()">
              <div class="grid align-items-start bulletin-item__detail-description__row__content"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

                <div class="col-3 col-sm-12">
                  <div class="bulletin-item__detail-description__row__heading">
                    <div class="grid align-items-baseline">
                      <span class="mr-icon mr-icon-video"></span>
                      <h5 class="ml-2">Видео</h5>
                    </div>
                  </div>
                </div>
                <div class="col-9 col-sm-12">
                  <div class="bulletin-item__detail-description__row__text">
                    <div class="frame-insert">
                      <youtube-player
                        [videoId]="onBulletinYouTube()"
                        [playerVars]="playerVars"
                        (ready)="onSetBulletinPlayer($event)">
                      </youtube-player>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="pg-wrapper" *ngIf="onBulletinModeration === BIModeration.Ok && onBulletinPublish === BIPublish.Published"
      ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-6' : 'mt-10'}}">

      <div class="grid align-items-start justify-content-end">
        <div class="col-9 col-sm-12 col-md-12 no-padding">
          <div class="bulletin-item__detail-description__wrapper">

            <div class="bulletin-item__detail-description__row no-border">
              <div class="grid align-items-start bulletin-item__detail-description__row__content"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

                <div class="col-6 col-sm-12 border__col"
                  ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4 pb-4 mb-4' : ''}}">

                  <div class="row text-center">
                    <h5>Социальные сети</h5>
                  </div>
                  <div class="row text-center"
                    ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'pt-2 pb-2' : 'pt-4 pb-4'}}">

                    <div class="bulletin-item__detail-description__row__auth" *ngIf="BIUserAuth">
                      <div class="grid h-full align-items-center justify-content-center">
                        <div class="col-auto bulletin-item__detail-description__row__social-link"
                          ngClass="{{BulletinDetail.contactsViewModel.vkUrl ? '' : 'disabled'}}"
                          (click)="onRouteToSocialLinkURL('https://vk.com/', BulletinDetail.contactsViewModel.vkUrl)">
                          <span class="mr-icon mr-icon-vk-color">
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </span>
                        </div>
                        <div class="col-auto bulletin-item__detail-description__row__social-link"
                          ngClass="{{BulletinDetail.contactsViewModel.fbUrl ? '' : 'disabled'}}"
                          (click)="onRouteToSocialLinkURL('https://facebook.com/', BulletinDetail.contactsViewModel.fbUrl)">
                          <span class="mr-icon mr-icon-fb-color">
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </span>
                        </div>
                        <div class="col-auto bulletin-item__detail-description__row__social-link"
                          ngClass="{{BulletinDetail.contactsViewModel.instagramUrl ? '' : 'disabled'}}"
                          (click)="onRouteToSocialLinkURL('https://instagram.com/', BulletinDetail.contactsViewModel.instagramUrl)">
                          <span class="mr-icon mr-icon-inst-color disabled">
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                            <span class="path4"></span>
                          </span>
                        </div>
                        <div class="col-auto bulletin-item__detail-description__row__social-link"
                          ngClass="{{BulletinDetail.contactsViewModel.hasViber ? '' : 'disabled'}}"
                            (click)="onRouteToMessengerLinkURL(BIMessenger.Viber, BulletinDetail.contactsViewModel.hasViber)">
                          <span class="mr-icon mr-icon-viber-color"
                            pTooltip="{{BulletinDetail.contactsViewModel.hasViber ? 'Нажмите, что бы начать общаться в Viber' : ''}}"
                            tooltipStyleClass="p-tooltip__input"
                            tooltipPosition="top">
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </span>
                        </div>
                        <div class="col-auto bulletin-item__detail-description__row__social-link"
                          ngClass="{{BulletinDetail.contactsViewModel.hasWhatsapp ? '' : 'disabled'}}"
                          (click)="onRouteToMessengerLinkURL(BIMessenger.Whatsapp, BulletinDetail.contactsViewModel.hasWhatsapp)">
                          <span class="mr-icon mr-icon-wh-color"
                            pTooltip="{{BulletinDetail.contactsViewModel.hasWhatsapp ? 'Нажмите, что бы начать общаться в WhatsApp' : ''}}"
                            tooltipStyleClass="p-tooltip__input"
                            tooltipPosition="top">
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </span>
                        </div>
                        <div class="col-auto bulletin-item__detail-description__row__social-link"
                          ngClass="{{BulletinDetail.contactsViewModel.hasTelegram ? '' : 'disabled'}}">
                          <span class="mr-icon mr-icon-telegram-color"
                            pTooltip="{{BulletinDetail.contactsViewModel.hasTelegram ? 'Номер телефона привязан к Telegram' : ''}}"
                            tooltipStyleClass="p-tooltip__input"
                            tooltipPosition="top">
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="bulletin-item__detail-description__row__auth-need" *ngIf="!BIUserAuth">
                      <p>Только для зарегистрированных пользователей.</p>
                      <p><a (click)="onRouteToSignUp($event)">регистрация</a> или <a (click)="onRouteToSignIn($event)">вход</a></p>
                    </div>

                  </div>
                </div>

                <div class="col-6 col-sm-12"
                  ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4 mb-4' : ''}}">

                  <div class="row text-center">
                    <h5>Контактная информация</h5>
                  </div>
                  <div class="row text-center"
                    ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'pt-2 pb-2' : 'pt-4 pb-4'}}">

                    <div class="col-6 col-sm-9 col-md-7 margin-auto">
                      <button class="ui-buttons ui-buttons__light-large" (click)="onShowBulletinPhone()">
                        Показать телефон
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="bulletin-item__list" *ngIf="BulletinView === BITypes.ProfileList">
  <div class="grid align-items-start">
    <div class="bulletin-item__list-content profile-content">
      <a class="bulletin-item__list-heading"
        routerLink="/{{BIRouting.Bulletins}}/{{BIRouting.Detail}}/{{BulletinItem.itemId || BulletinItem.id}}"
        target="{{onViewDetailBulletin()}}"
        ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'pb-3' : 'pb-2'}}">

        <p>{{BulletinItem | bulletinHeading:'searchType'}}</p>
      </a>

      <div class="grid align-items-center flex-wrap">
        <div class="col-auto bulletin-item__list-row mr-1">
          <div class="grid align-self-center flex-wrap">
            <div class="col-auto mb-2 align-self-center">
              <p class="__title mr-2">Стиль:</p>
            </div>
            <ng-container *ngFor="let genre of BulletinItem.genreIds">
              <div class="col-auto mb-2">
                <p-chip label="{{genre | bulletinGenre}}" styleClass="bulletin-search__chip"></p-chip>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="grid align-items-center flex-wrap">
        <div class="col-auto bulletin-item__list-row mr-4">
          <div class="grid align-self-center flex-wrap">
            <div class="col-auto mb-2 align-self-center">
              <p class="__title mr-2">Город:</p>
            </div>
            <div class="col-auto mb-2">
              <p>{{BulletinItem.cityId | bulletinCity}}</p>
            </div>
          </div>
        </div>

        <div class="col-auto bulletin-item__list-row mr-4">
          <div class="grid align-self-center flex-wrap">
            <div class="col-auto mb-2 align-self-center">
              <p class="__title mr-2">Коммерческий проект:</p>
            </div>
            <div class="col-auto mb-2">
              <p>{{BulletinItem.isCommercial ? 'Да' : 'Нет'}}</p>
            </div>
          </div>
        </div>

        <div class="col-auto bulletin-item__list-row mr-4">
          <div class="grid align-self-center flex-wrap">
            <div class="col-auto mb-2 align-self-center">
              <p class="__title mr-2">Кавер-группа:</p>
            </div>
            <div class="col-auto mb-2">
              <p>{{BulletinItem.isCover ? 'Да' : 'Нет'}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid align-items-center justify-content-center flex-column bulletin-item__list-event profile-event">
      <div class="col-auto bulletin-item__list-event__date">
        <span class="mr-icon mr-icon-clock"></span>
        <span>{{BulletinItem.created | formatDate}}</span>
      </div>

      <div class="col-auto w-full mt-4"
        *ngIf="onBulletinPublishList === BIPublish.ClosedByUser || onBulletinPublishList === BIPublish.Expired">
        <button class="ui-buttons ui-buttons__light-small w-full" (click)="onActivateBulletinPublication()">
          Активировать
        </button>
      </div>

      <div class="col-auto w-full mt-4" *ngIf="onBulletinPublishList === BIPublish.Published">
        <button class="ui-buttons ui-buttons__blue-small" (click)="onEditBulletinPublication()" pRipple>
          Редактировать
        </button>
      </div>

      <div class="col-auto w-full mt-2 mb-2" *ngIf="onBulletinPublishList === BIPublish.Published">
        <button class="ui-buttons ui-buttons__light-small" (click)="onStopBulletinPublication()">
          Снять с публикации
        </button>
      </div>
    </div>
  </div>
</div>

<div class="bulletin-item__block" *ngIf="BulletinView === BITypes.ProfileBlock">
  <div class="grid align-items-center bulletin-item__block-content profile-content">

    <div class="bulletin-item__block-rouble" *ngIf="BulletinItem.isCommercial">
      <span class="mr-icon mr-icon-ribbon-ruble">
        <span class="path1"></span>
        <span class="path2"></span>
      </span>
    </div>

    <div class="col-9 align-self-start">
      <a class="bulletin-item__block-heading"
        routerLink="/{{BIRouting.Bulletins}}/{{BIRouting.Detail}}/{{BulletinItem.itemId || BulletinItem.id}}"
        target="{{onViewDetailBulletin()}}"
        ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'pb-3' : 'pb-2'}}">

        <p>{{BulletinItem | bulletinHeading:'searchType'}}</p>
      </a>

      <div class="bulletin-item__block-row">
        <div class="grid align-self-center flex-wrap">
          <div class="col-auto align-self-center" ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'mb-2' : 'mb-1'}}">
            <p class="__title mr-2">Стиль:</p>
          </div>
          <ng-container *ngFor="let genre of BulletinItem.genreIds">
            <div class="col-auto" ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'mb-2' : 'mb-1'}}">
              <p-chip label="{{genre | bulletinGenre}}" styleClass="bulletin-search__chip"></p-chip>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="bulletin-item__block-row"
        ngClass="{{(deviceValue | async) === DTypes.Desktop ? '' : 'mt-1'}}">
        <div class="grid align-self-center flex-wrap">
          <div class="col-auto mb-2 align-self-center">
            <p class="__title mr-2">Город:</p>
          </div>
          <div class="col-auto mb-2">
            <p>{{BulletinItem.cityId | bulletinCity}}</p>
          </div>
        </div>
      </div>

      <div class="bulletin-item__block-row">
        <div class="grid align-self-center flex-wrap">
          <div class="col-auto mb-2 align-self-center">
            <p class="__title mr-2">Коммерческий проект:</p>
          </div>
          <div class="col-auto mb-2">
            <p>{{BulletinItem.isCommercial ? 'Да' : 'Нет'}}</p>
          </div>
        </div>
      </div>

      <div class="bulletin-item__block-row">
        <div class="grid align-self-center flex-wrap">
          <div class="col-auto mb-2 align-self-center">
            <p class="__title mr-2">Кавер-группа:</p>
          </div>
          <div class="col-auto mb-2">
            <p>{{BulletinItem.isCover ? 'Да' : 'Нет'}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <a class="bulletin-item__block-picture"
        routerLink="/{{BIRouting.Bulletins}}/{{BIRouting.Detail}}/{{BulletinItem.itemId || BulletinItem.id}}"
        target="{{onViewDetailBulletin()}}">
        <div class="mr-icon mr-icon-{{BulletinItem.instrumentId | bulletinPreview}}"></div>
      </a>
    </div>
  </div>
  <div class="grid align-items-center bulletin-item__block-event profile-event flex-column">
    <div class="col-12 col-sm-8 bulletin-item__block-event__date text-center">
      <span class="mr-icon mr-icon-clock"></span>
      <span>{{BulletinItem.created | formatDate}}</span>
    </div>

    <div class="col-auto col-sm-8 mt-4"
      *ngIf="onBulletinPublishList === BIPublish.ClosedByUser || onBulletinPublishList === BIPublish.Expired">
      <button class="ui-buttons ui-buttons__light-small w-full" (click)="onActivateBulletinPublication()">
        Активировать
      </button>
    </div>

    <div class="col-auto col-sm-8 mt-4" *ngIf="onBulletinPublishList === BIPublish.Published">
      <button class="ui-buttons ui-buttons__blue-small w-full" (click)="onEditBulletinPublication()">
        Редактировать
      </button>
    </div>

    <div class="col-auto col-sm-8 mt-2 mb-2" *ngIf="onBulletinPublishList === BIPublish.Published">
      <button class="ui-buttons ui-buttons__light-small w-full" (click)="onStopBulletinPublication()">
        Снять с публикации
      </button>
    </div>

  </div>
</div>
