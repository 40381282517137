<div class="search-city__wrapper">
  <div class="row">
    <form class="search-city__forms" [formGroup]="SCForm" (ngSubmit)="onSubmitForm()" autocomplete="off">
      <div class="grid align-items-center">
        <div class="col-12 col-sm-10 no-padding">
          <div class="search-city__forms-input">
            <div class="ui-input ui-input__text">
              <div class="ui-input__helper">
                <div class="ui-input__helper-icon">
                  <span class="mr-icon mr-icon-info"></span>
                </div>
              </div>
              <input placeholder="Введите Ваш город" type="text"
                validateHandler="textRU"
                formControlName="city"
                pInputText
                tooltipStyleClass="p-tooltip__input"
                pTooltip="Название города можно вводить только на русском языке"
                tooltipPosition="{{(deviceValue | async) === DTypes.Mobile ? 'bottom' : 'right'}}"
                hideDelay="0"
                tooltipEvent="focus"
                [tooltipDisabled]=SCTooltipDisabled
                (keyup)="onInputKeyup($event)">
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="row">
    <div class="search-city__results ui-scroll-vertical">
      <ng-container *ngFor="let city of SCResult">
        <div class="search-city__results-item">
          <p (click)="onSearchCityPatchForm(city.id)">{{city.name}}</p>
        </div>
      </ng-container>
      <div class="search-city__results-empty" *ngIf="!SCResult">
        <div class="grid align-items-center">
          <span class="mr-icon mr-icon-info"></span>
          <p class="ml-2">Мы не смогли найти город с таким названием.</p>
        </div>
        <div class="grid align-items-center">
          <a class="ml-6" (click)="onViewAllCities($event)">Список всех городов</a>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="search-city__helpers">
      <div class="grid align-items-center"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">
        <div class="col-3 col-sm-12">
          <button class="ui-buttons ui-buttons__blue-large {{SCButtonDisabled ? 'disabled' : ''}}" (click)="onSubmitForm()">
            Применить
          </button>
        </div>
        <div class="col-8 col-sm-12"
          ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-4'}}">
          <div class="grid align-items-center">
            <div class="col-auto mr-3">
              <span class="mr-icon mr-icon-info"></span>
            </div>
            <div class="col-10 col-sm-10">
              <p>
                Если в списке нет Вашего города,
                <strong>начните вводить</strong> его название в поле поиска
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="search-city__helpers-graphic"
        *ngIf="(deviceValue | async) === DTypes.Desktop || (deviceValue | async) === DTypes.Tablet">
        <div class="ui-graphics__balalaika-dark"></div>
      </div>
    </div>
  </div>
</div>
