import { Pipe, PipeTransform } from '@angular/core';
import { FundamentalsService } from '@app/modules/fundamentals-modules/fundamentals.service';
import { InstrumentModel } from '@app/modules/fundamentals-modules/fundamentals.models';


@Pipe({
  name: 'bulletinPreview'
})

export class BulletinPreviewPipe implements PipeTransform {
  constructor(
    private fundamentalsService: FundamentalsService
  ) {}

  transform(instrumentId: number): string {
    if (instrumentId === 0) {
      return 'other';
    }

    const InstrumentsDependencies = this.fundamentalsService.instrumentsDependencies || [];

    const Instrument = <InstrumentModel>InstrumentsDependencies.filter(
      item => item.id === instrumentId
    )[0] || null;

    if (Instrument.id === 3 || Instrument.id === 104) {
      return 'guitar';
    }

    if (Instrument.id === 63) {
      return 'electro-guitar';
    }

    if (Instrument.id === 66) {
      return 'dj';
    }

    if (Instrument.id === 78) {
      return 'bass';
    }

    if (Instrument.id === 87 || Instrument.id === 12) {
      return 'voice';
    }

    if (
      Instrument.id === 46 || Instrument.id === 37 || Instrument.id === 45 || Instrument.id === 47 || Instrument.id === 114 ||
      Instrument.id === 126
    ) {
      return 'piano';
    }

    if (Instrument.id === 76 || Instrument.id === 80) {
      return 'drums';
    }

    if (
      Instrument.id === 120 || Instrument.id === 56 || Instrument.id === 81 || Instrument.id === 83 || Instrument.id === 23 ||
      Instrument.id === 91 || Instrument.id === 92 || Instrument.id === 100 || Instrument.id === 36 || Instrument.id === 119 ||
      Instrument.id === 120 || Instrument.id === 56 || Instrument.id === 123 || Instrument.id === 125 || Instrument.id === 113
    ) {
      return 'tube';
    }

    if (
      Instrument.id === 5 || Instrument.id === 4 || Instrument.id === 6 || Instrument.id === 20 || Instrument.id === 76 ||
      Instrument.id === 21 || Instrument.id === 50
    ) {
      return 'violin';
    }

    return 'other';
  }
}
