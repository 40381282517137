<div class="search-activity__wrapper">
  <div class="row">
    <form class="search-activity__forms" [formGroup]="SAInstrumentForm" (ngSubmit)="onSubmitForm()" autocomplete="off">
      <div class="grid align-items-center">
        <div class="col-12 col-sm-12 no-padding">
          <div class="search-activity__forms-input">
            <div class="ui-input ui-input__text">
              <div class="ui-input__helper">
                <div class="ui-input__helper-icon">
                  <span class="mr-icon mr-icon-info"></span>
                </div>
              </div>
              <input placeholder="Введите Ваш инструмент" type="text"
                validateHandler="textRU"
                formControlName="instrument"
                pInputText
                tooltipStyleClass="p-tooltip__input"
                pTooltip="Название инструмента можно вводить только на русском языке"
                tooltipPosition="{{(deviceValue | async) === DTypes.Mobile ? 'bottom' : 'right'}}"
                hideDelay="0"
                tooltipEvent="focus"
                [tooltipDisabled]=SATooltipDisabled
                (keyup)="onInputKeyup($event)">
            </div>
          </div>
        </div>
      </div>

      <div class="grid align-items-center flex-wrap pt-2 pb-2">
        <ng-container *ngFor="let instrument of SAInstrumentsValue">
          <div class="col-auth mb-2 animated fast zoomIn">
            <p-chip label="{{instrument.title}}" styleClass="activity__chip"
              [removable]="true"
              (onRemove)="onRemoveSelectedInstrument(instrument)">
            </p-chip>
          </div>
        </ng-container>

      </div>
    </form>
  </div>
  <div class="row">
    <div class="search-activity__results ui-scroll-vertical">
      <ng-container *ngFor="let instrument of SAResult;">
        <div class="search-activity__results-item">
          <div class="ui-checkbox w-full {{SAInstrumentsValue.length === 5 && !instrument.selected ? 'disabled' : ''}}">
            <input id="activity__{{instrument.id}}" type="checkbox"
              [checked]="instrument.selected"
              (change)="onSetSelectedInstrument(instrument, $event)">
            <label for="activity__{{instrument.id}}">
              <span class="ui-checkbox__text ellipsis-text">{{instrument.title}}</span>
            </label>
          </div>
        </div>
      </ng-container>
      <div class="search-activity__results-empty" *ngIf="!SAResult">
        <div class="grid align-items-center">
          <span class="mr-icon mr-icon-info"></span>
          <p class="ml-2">Мы не смогли найти инструмент с таким названием.</p>
        </div>
        <div class="grid align-items-center">
          <a class="ml-6" (click)="onViewAllInstruments($event)">Список всех инструментов</a>
        </div>
      </div>


      <div class="search-activity__results-warning" *ngIf="SAResult">
        <div class="grid align-items-center">
          <div class="col-auto mr-3">
            <span class="mr-icon mr-icon-status-error"></span>
          </div>
          <div class="col-7 col-sm-10">
            <p>Если в списке нет Вашего инструмента,<br/> <strong>начните вводить</strong> его название в поле поиска</p>
          </div>
        </div>
      </div>
    </div>

    <div class="search-activity__results ui-scroll-vertical">
      <ng-container *ngFor="let position of SAPositions">
        <div class="search-activity__results-item">
          <div class="ui-radio">
            <input id="position__{{position.id}}" type="radio" name="position"
              (change)="onSetSelectedPosition(position)"
              [checked]="onGetSelectedPosition(position)">
            <label for="position__{{position.id}}">
              <span class="ui-checkbox__text">{{position.title}}</span>
            </label>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="row">
    <div class="search-activity__helpers">
      <div class="grid align-items-center"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">
        <div class="col-3 col-sm-12">
          <button class="ui-buttons ui-buttons__blue-large" (click)="onSubmitForm()">
            Применить
          </button>
        </div>
        <div class="col-8 col-sm-12"
          ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-4'}}">
          <div class="grid align-items-center">
            <div class="col-auto mr-3">
              <span class="mr-icon mr-icon-info"></span>
            </div>
            <div class="col-10 col-sm-10">
              <p class="txt-caption__large">
                Вы можете выбрать до <strong>5 музыкальных инструментов</strong>,
                а также должность которую занимаете, если она есть.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="search-activity__helpers-graphic"
        *ngIf="(deviceValue | async) === DTypes.Desktop || (deviceValue | async) === DTypes.Tablet">
        <div class="ui-graphics__balalaika-dark"></div>
      </div>
    </div>
  </div>
</div>
