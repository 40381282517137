import { ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { RoutingConfig } from '@app/routing/routing.config';
import { BreadcrumbsDataModel } from '@app/core/models/breadcrumbs';
import { DevicesClass, DeviceTypes } from '@app/core/classes';

@Component({
  selector: 'shared-breadcrumbs',
  templateUrl: 'breadcrumbs.component.html',
  styleUrls: [`./breadcrumbs.component.scss`],
})

export class BreadcrumbsComponent extends DevicesClass  implements OnInit {
  public Routing = RoutingConfig;

  @Input() ComponentData: BreadcrumbsDataModel;

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.cdr.markForCheck();
  }

  onSplitBreadcrumbs(value: string): string {
    const valueArray = value.split(' ');

    let valueLength = this.deviceValue.getValue() === this.DTypes.Mobile ? 2 : 8;

    if (value.length < 20) {
      valueLength = 4;
    }
    if (valueArray.length > valueLength) {
      return valueArray.slice(0, valueLength).join(' ') + '...';
    }
    return value;
  }
}
