import { AppApiURL, AppDomainURL } from '@app/app.settings';
import { AppPrefix } from '@app/app.settings';
import { Injectable } from '@angular/core';
import { StorageService } from '@app/core/services/storage/storage.service';
import { StorageOAuth } from '@app/core/constants';
import { AuthStoryService } from '@app/modules/auth-modules/auth.story';

export enum OAuthTypes {
  VKontakte,      // 0
  Facebook,       // 1
  Google,         // 2
  Odnoklassniki,  // 3
}

@Injectable({
  providedIn: 'root'
})

export class OAuthService {
  private OAuthWidth: number = 768;
  private OAuthHeight: number = 500;

  constructor (
    private storage: StorageService,
    private authStory: AuthStoryService
  ) {}

  onOAuthWindow(OAuthValue: number): void {
    switch (OAuthValue) {
      case (OAuthTypes.VKontakte) :
          (<any>window).open(`${AppApiURL}/login/VKontakte${this.onOAuthCallbackURL()}`, '', this.screenOffset());
        break;

      case (OAuthTypes.Facebook) :
          (<any>window).open(`${AppApiURL}/login/Facebook${this.onOAuthCallbackURL()}`, '', this.screenOffset());
        break;

      case (OAuthTypes.Odnoklassniki) :
          (<any>window).open(`${AppApiURL}/login/Odnoklassniki${this.onOAuthCallbackURL()}`, '', this.screenOffset());
        break;

      case (OAuthTypes.Google) :
          (<any>window).open(`${AppApiURL}/login/Google${this.onOAuthCallbackURL()}`, '', this.screenOffset());
        break;
    }
    this.onOAuthStorage();
  }

  onOAuthStorage(): void {
    (<any>window).addEventListener('storage', event => {
      if (event.key === AppPrefix.toLowerCase() + '-' + StorageOAuth.externalSignIn) {
        try {
          const OAuthSignInData = JSON.parse(event.newValue) || null;

          this.authStory.AuthEventBus.next({
            event: 'onOAuthSignIn',
            data: OAuthSignInData
          })
        } catch (e) {}

        this.onOAuthClearStorage(StorageOAuth.externalSignIn)
      }

      if (event.key === AppPrefix.toLowerCase() + '-' + StorageOAuth.externalSignUp) {
        try {
          const OAuthSignUpData = JSON.parse(event.newValue) || null;

          this.authStory.AuthEventBus.next({
            event: 'onOAuthSignUp',
            data: OAuthSignUpData
          })
        } catch (e) {}

        this.onOAuthClearStorage(StorageOAuth.externalSignUp)
      }

      if (event.key === AppPrefix.toLowerCase() + '-' + StorageOAuth.externalSignError) {
        try {
          this.authStory.AuthEventBus.next({
            event: 'onOAuthSignError',
            data: null
          })
        } catch (e) {}

        this.onOAuthClearStorage(StorageOAuth.externalSignError)
      }
    });
  }

  onOAuthCallbackURL(): string {
      return `?completeProfileReturnUrl=${AppDomainURL}/login/sign-oauth&tokenReturnUrl=${AppDomainURL}/login/sign-oauth`;
  }

  onOAuthClearStorage(key: string): void {
    this.storage.removeStorage({
      key: key
    }, 'local');
  }

  private screenOffset(): string {
    return `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${this.OAuthWidth}, height=${this.OAuthHeight}, top=${(screen.height / 2) - ( this.OAuthHeight / 2)}, left=${(screen.width / 2) - ( this.OAuthWidth / 2)}`;
  }
}
