import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { DevicesClass } from '@app/core/classes';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { InstrumentsService } from '@app/modules/instruments-modules/instruments.service';
import { InstrumentModel, InstrumentsModel, PositionsModel, PositionModel } from '@app/modules/fundamentals-modules/fundamentals.models';
import { FundamentalsService } from '@app/modules/fundamentals-modules/fundamentals.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DialogIncludesTypes, DialogComponentModel } from '@app/core/models/diallog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { debounceTime, map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-search-activity',
  templateUrl: 'search-activity.component.html',
  styleUrls: [`./search-activity.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SearchActivityComponent extends DevicesClass implements OnInit {
  /**
   * Base component variables.
   */
  public SAInstrumentForm: FormGroup;
  public SAResult: InstrumentsModel = this.fundamentalsService.instrumentsRecommendations;
  public SAPositions: PositionsModel = this.fundamentalsService.positionsDependencies;

  public SAPositionsValue: PositionModel = this.fundamentalsService.positionsDependencies[0];
  public SAInstrumentsValue: InstrumentsModel = [];

  public SAInputConfig: DialogComponentModel = null;
  public SATooltipDisabled: boolean = false;

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    private router: Router,
    private instrumentsService: InstrumentsService,
    private fundamentalsService: FundamentalsService,
    private formBuilder: FormBuilder,
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.SAInstrumentForm = this.formBuilder.group({
      instrument: ['']
    });

    /**
     * Form changes control.
     */
    this.onSearchInstrumentCtrl();

    /**
     * Dialog input config.
     */
    this.onSearchInstrumentInputConfig();
  }

  /**
   * Apply Dialog Input config.
   * @type DialogComponentModel
   */
  onSearchInstrumentInputConfig(): void {
    this.SAInputConfig = <DialogComponentModel>this.dialogConfig.data || null;

    if (this.SAInputConfig && this.SAInputConfig.hasOwnProperty('includeData')) {
      const DataInitial = <InstrumentModel>this.SAInputConfig.includeData.dataInitial || null;

      if (DataInitial) {
        this.onSearchInstrumentPatchForm(DataInitial);
      }
    }
  }

  onSearchInstrumentCtrl(): void {
    this.SAInstrumentForm.valueChanges.pipe(
      untilDestroyed(this),
      map(value => {
        /**
         * Display of tooltip
         */
        value.genre === '' ? this.SATooltipDisabled = false : this.SATooltipDisabled = true;

        return value;
      }),
      debounceTime(500)
    ).subscribe(value => {
      if (value.instrument === '') {
        return this.onApplySearchInstrumentResult(
          this.fundamentalsService.instrumentsRecommendations
        )
      }
      this.onSearchInstrumentAPI(value.instrument);
    });
  }

  onSearchInstrumentAPI(instrumentName: string): void {
    this.instrumentsService.instrumentsSearch(instrumentName).subscribe(
      response => this.onApplySearchInstrumentResult(response),
      error => this.onApplySearchInstrumentResult([])
    )
  }

  onSearchInstrumentPatchForm(dataInitial: any): void {
    if (dataInitial.hasOwnProperty('position')) {
      const position =
        dataInitial.position ?
        dataInitial.position : this.fundamentalsService.positionsDependencies[0];

      this.onSetSelectedPosition(position);
    }

    if (dataInitial.hasOwnProperty('instruments')) {
      dataInitial.instruments.map(instrument => {
        this.SAResult.map(inst => {
          if (inst.id === instrument.id) {
            inst.selected = true;
          }
        });
        this.onSetSelectedInstrument(instrument);
      });
    }
  }

  onViewAllInstruments($event: Event): void {
    $event.preventDefault();

    this.onApplySearchInstrumentResult(
      this.fundamentalsService.instrumentsDependencies
    );
  }

  onInputKeyup($event: KeyboardEvent): void {
    if ($event.key === 'Backspace' || $event.key === 'Delete') {
      this.cdr.markForCheck();
    }
  }

  onApplySearchInstrumentResult(instruments: InstrumentsModel): void {
    this.SAResult = instruments.length ? instruments : null;

    if (this.SAResult && this.SAInstrumentsValue) {
      this.SAInstrumentsValue.map(value => {
        this.SAResult.map(inst => {
          if (inst.id === value.id) {
            inst.selected = true;
          }
        });
      });
    }

    this.cdr.markForCheck();
  }

  onSetSelectedPosition(position: PositionModel): void {
    this.SAPositionsValue = position;
  }

  onGetSelectedPosition(position: PositionModel) {
    return position.id === this.SAPositionsValue.id;
  }

  onSetSelectedInstrument(instrument: InstrumentModel, $event?: Event) {
    if (this.SAInstrumentsValue.some(inst => inst.id === instrument.id)) {
      return this.onRemoveSelectedInstrument(instrument);
    }

    if (this.SAInstrumentsValue.length > 4) {
      return $event.target['checked'] = false;
    }
    instrument.selected = true;
    this.SAInstrumentsValue.push(instrument);

    this.cdr.markForCheck();
  }

  onRemoveSelectedInstrument(instrument: InstrumentModel) {
    if (this.SAResult) {
      this.SAResult.map(inst => {
        if (inst.id === instrument.id) {
          inst.selected = false;
        }
      });
    }


    this.SAInstrumentsValue = this.SAInstrumentsValue.filter(inst => {
      return inst.id !== instrument.id;
    });
  }


  onSubmitForm(): void {
    /**
     * Callback if global using.
     * @type PopupTemplate
     */
    if (this.SAInputConfig.includeType === DialogIncludesTypes.PopupTemplate) {
      console.log({
        event: 'Push global Activity',
        data: {
          instruments: this.SAInstrumentsValue,
          position: this.SAPositionsValue
        }
      })
    }

    /**
     * Callback if using in component.
     * @type ComponentTemplate
     */
    if (this.SAInputConfig.includeType === DialogIncludesTypes.ComponentTemplate) {
      this.SAInputConfig.includeData.dataSubject.next({
        instruments: this.SAInstrumentsValue,
        position: this.SAPositionsValue
      });
    }

    /**
     * Close popup.
     */
    this.dialogRef.close();
  }
}
