<shared-breadcrumbs [ComponentData]="BreadcrumbsData"></shared-breadcrumbs>

<div class="pg-content__inner-transparent">
  <div class="pg-wrapper">
    <div class="sign-up__loading animated faster fadeIn" *ngIf="SignUpLoading">
      <div class="ui-loader ui-loader__page-dark"></div>
    </div>

    <div class="sign-up__wrapper"
      ngClass="{{SignUpLoading ? 'loading' : ''}}">

      <div class="row"
        ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'pt-6 pb-6' : 'pt-4 pb-4'}}">

        <div class="sign-up__heading">
          <h1>Регистрация</h1>
        </div>
      </div>

      <div class="row">
        <div class="sign-up__addition">
          <div class="grid align-items-start"
            ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

            <div class="col-6 col-sm-12"
              ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'order-1 pt-6' : ''}}">
              <div class="sign-up__addition-title">
                <h5>Загрузите аватар</h5>
              </div>
              <div class="sign-up__addition-event">
                <div class="sign-up__addition-event__clear" *ngIf="SignUpAvatar" (click)="onUploadAvatarClear()">
                  <i class="pi pi-times-circle"></i>
                </div>

                <div class="sign-up__addition-event__avatar">
                  <input type="file" id="avatarUpload" class="sign-up__addition-event__avatar-input"
                    (change)="onUploadAvatarEvent($event.target['files'])"
                    [accept]="fileAcceptString">

                  <span class="ui-graphics__balalaika-light" *ngIf="!SignUpAvatar"></span>
                  <img src="{{SignUpAvatar}}" *ngIf="SignUpAvatar">

                  <div class="sign-up__addition-event__avatar-link" (click)="onUploadAvatarHandler()">
                    <div class="grid align-items-center justify-content-center">
                      <i class="pi pi-upload"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sign-up__addition-helper">
                <p>Размер не должен превышать 1Mb.<br/>Допустимые типы файлов: jpg, png, gif.</p>
              </div>
            </div>

            <div class="col-6 col-sm-12"
              ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'order-0 pt-6' : ''}}">
              <div class="sign-up__addition-title">
                <h5>Войти или зарегистрироваться</h5>
              </div>
              <div class="sign-up__addition-event">
                <div class="row sign-up__social">
                  <div class="grid h-full align-items-center justify-content-center">
                    <div class="col-auto sign-up__social-link" (click)="oauthService.onOAuthWindow(OAuthTypes.VKontakte)">
                      <span class="mr-icon mr-icon-vk-color">
                        <span class="path1"></span>
                        <span class="path2"></span>
                      </span>
                    </div>
                    <div class="col-auto sign-up__social-link" (click)="oauthService.onOAuthWindow(OAuthTypes.Facebook)">
                      <span class="mr-icon mr-icon-fb-color">
                        <span class="path1"></span>
                        <span class="path2"></span>
                      </span>
                    </div>
                    <div class="col-auto sign-up__social-link" (click)="oauthService.onOAuthWindow(OAuthTypes.Odnoklassniki)">
                      <span class="mr-icon mr-icon-od-color">
                        <span class="path1"></span>
                        <span class="path2"></span>
                        <span class="path3"></span>
                      </span>
                    </div>
                    <div class="col-auto sign-up__social-link" (click)="oauthService.onOAuthWindow(OAuthTypes.Google)">
                      <span class="mr-icon mr-icon-google-color">
                        <span class="path1"></span>
                        <span class="path2"></span>
                        <span class="path3"></span>
                        <span class="path4"></span>
                        <span class="path5"></span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sign-up__addition-helper">
                <p>Вы можете войти или зарегистрироваться<br/> через социальную сеть.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <form class="sign-up__forms" [formGroup]="SignUpForm" autocomplete="off">
          <div class="grid align-items-end "
            ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : 'mb-4'}}">

            <div class="col-6 col-sm-12 no-padding">
              <div class="sign-up__forms-input"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-2' : 'pr-4'}}">

                <div class="ui-input__placeholder">
                  <p>Логин</p>
                  <span class="require">*</span>
                  <p class="error-holder" *ngIf="ErrorsField.username.status || SignUpUsername">
                    {{ErrorsField.username.value}}
                  </p>
                </div>

                <div class="ui-input ui-input__text"
                  ngClass="{{ErrorsField.username.status || SignUpUsername ? 'error' : ''}}">
                  <div class="ui-input__helper">
                    <div class="ui-input__helper-icon">
                      <span class="mr-icon mr-icon-info"></span>
                    </div>
                  </div>
                  <input type="text" placeholder="Логин"
                    pInputText
                    formControlName="username"
                    tooltipStyleClass="p-tooltip__input"
                    pTooltip="Логин может состоять только из английских букв и цифр, без пробелов и быть не менее 5 символов."
                    tooltipPosition="{{(deviceValue | async) === DTypes.Mobile ? 'bottom' : 'right'}}"
                    life="5000"
                    hideDelay="0"
                    tooltipEvent="focus"
                    (blur)="onCheckUsername()"
                    (input)="SignUpUsername = false">
                </div>
              </div>
            </div>

            <div class="col-6 col-sm-12 no-padding">
              <div class="sign-up__forms-input"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-4'}}">

                <div class="ui-input__placeholder">
                  <p>E-mail</p>
                  <span class="require">*</span>
                  <p class="error-holder" *ngIf="ErrorsField.email.status">
                    {{ErrorsField.email.value}}
                  </p>
                </div>
                <div class="ui-input ui-input__text"
                  ngClass="{{ErrorsField.email.status ? 'error' : ''}}">

                  <input type="text" placeholder="E-mail" formControlName="email" (blur)="onCheckInput('email')">
                </div>
              </div>
            </div>
          </div>

          <div class="grid align-items-end"
            ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : 'mb-4'}}">

            <div class="col-6 col-sm-12 no-padding">
              <div class="sign-up__forms-input"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pr-4'}}">

                <div class="ui-input__placeholder">
                  <p>Имя</p>
                  <span class="require">*</span>
                  <p class="error-holder" *ngIf="ErrorsField.firstName.status">
                    {{ErrorsField.firstName.value}}
                  </p>
                </div>
                <div class="ui-input ui-input__text"
                  ngClass="{{ErrorsField.firstName.status ? 'error' : ''}}">
                  <input type="text" placeholder="Имя" formControlName="firstName">
                </div>
              </div>
            </div>

            <div class="col-6 col-sm-12 no-padding">
              <div class="sign-up__forms-input"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-4'}}">

                <div class="ui-input__placeholder">
                  <p>Фамилия</p>
                </div>
                <div class="ui-input ui-input__text">
                  <input type="text" placeholder="Фамилия" formControlName="lastName">
                </div>
              </div>
            </div>
          </div>

          <div class="grid align-items-end"
            ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : 'mb-4'}}">

            <div class="col-6 col-sm-12 no-padding">
              <div class="sign-up__forms-input"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pr-4'}}">

                <div class="ui-input__placeholder">
                  <p>Город</p>
                  <span class="require">*</span>
                  <p class="error-holder" *ngIf="ErrorsField.cityId.status">
                    {{ErrorsField.cityId.value}}
                  </p>
                </div>
                <div class="ui-input ui-input__text"
                  ngClass="{{ErrorsField.cityId.status ? 'error' : ''}}">
                  <div class="ui-input__cup cta ui-scroll-horizontal" (click)="onViewSearchCities()">
                    <input type="text" (focus)="onViewSearchCities()" readonly>
                    <span *ngIf="!SignUpCityValue">Выбрать город</span>

                    <p-chip *ngIf="SignUpCityValue"
                      label="{{SignUpCityValue.name}}"
                      styleClass="input__chip">
                    </p-chip>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6 col-sm-12 no-padding">
              <div class="sign-up__forms-input"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-4'}}">

                <div class="ui-input__placeholder">
                  <p>Ваш инструмент или должность</p>
                  <span class="require">*</span>
                  <p class="error-holder" *ngIf="ErrorsField.instrumentIds.status">
                    {{ErrorsField.instrumentIds.value}}
                  </p>
                </div>
                <div class="ui-input ui-input__text"
                  ngClass="{{ErrorsField.instrumentIds.status ? 'error' : ''}}">
                  <div class="ui-input__cup cta ui-scroll-horizontal" (click)="onViewSearchActivity()">
                    <span *ngIf="!SignUpActivityPosition && !SignUpActivityInstruments.length">
                      Выбрать инструмент или должность
                    </span>

                    <div class="grid align-items-center flex-nowrap "
                      *ngIf="SignUpActivityPosition || SignUpActivityInstruments.length">

                      <p-chip *ngIf="SignUpActivityPosition && SignUpActivityPosition.id !== 0"
                        label="{{SignUpActivityPosition.title}}"
                        styleClass="input__chip">
                      </p-chip>

                      <ng-container *ngFor="let instrument of SignUpActivityInstruments">
                        <p-chip label="{{instrument.title}}" styleClass="input__chip"></p-chip>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="grid align-items-end"
            ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : 'mb-4'}}">

            <div class="col-6 col-sm-12 no-padding">
              <div class="sign-up__forms-input"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pr-4'}}">

                <div class="ui-input__placeholder">
                  <p>Телефон</p>
                  <p class="error-holder" *ngIf="ErrorsField.phone.status">
                    {{ErrorsField.phone.value}}
                  </p>
                </div>
                <div class="ui-input ui-input__text"
                  ngClass="{{ErrorsField.phone.status ? 'error' : ''}}">
                  <input type="text" placeholder="+7 (911) 111-11-11"
                    formControlName="phone"
                    mask="+0 (000) 000-00-00"
                    (input)="onMaskPhone($event)"
                    (blur)="onCheckPhone()">
                </div>
              </div>
            </div>

            <div class="col-6 col-sm-12 no-padding">
              <div class="sign-up__forms-input"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-4'}}">

                <div class="ui-input__placeholder">
                  <p>Дата рождения</p>
                  <p class="error-holder" *ngIf="ErrorsField.birthday.status">
                    {{ErrorsField.birthday.value}}
                  </p>
                </div>
                <div class="ui-input ui-input__text"
                  ngClass="{{ErrorsField.birthday.status ? 'error' : ''}}">
                  <div class="ui-input__helper">
                    <div class="ui-input__helper-icon cta" (click)="picker.open()">
                      <span class="mr-icon mr-icon-date"></span>
                    </div>
                  </div>

                  <input type="text" placeholder="01.01.1991" mask="d0.M0.0000" formControlName="birthday"
                    (input)="onSetBirthdayValue($event.target.value)"
                    (blur)="onCheckFormattedDate()">

                  <input type="hidden"
                    [matDatepicker]="picker"
                    [max]="onGetCurrentDate()"
                    (dateChange)="onSetBirthdayValue($event.value, true)">
                  <mat-datepicker touchUi #picker></mat-datepicker>
                </div>
              </div>
            </div>
          </div>

          <div class="grid align-items-end"
            *ngIf="!SignUpExternal"
            ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : 'mb-4'}}">

            <div class="col-6 col-sm-12 no-padding">
              <div class="sign-up__forms-input"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pr-4'}}">

                <div class="ui-input__placeholder">
                  <p>Пароль</p>
                  <span class="require">*</span>
                  <p class="error-holder" *ngIf="ErrorsField.password.status">
                    {{ErrorsField.password.value}}
                  </p>
                </div>
                <div class="ui-input ui-input__text"
                  ngClass="{{ErrorsField.password.status ? 'error' : ''}}">
                  <input type="password" placeholder="Введите пароль" formControlName="password">
                </div>
              </div>
            </div>

            <div class="col-6 col-sm-12 no-padding">
              <div class="sign-up__forms-input"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-4'}}">

                <div class="ui-input__placeholder">
                  <p>Повторить пароль</p>
                  <span class="require">*</span>
                </div>
                <div class="ui-input ui-input__text"
                  ngClass="{{ErrorsField.password.status ? 'error' : ''}}">
                  <input type="password" placeholder="Повторите пароль" formControlName="password_repeat">
                </div>
              </div>
            </div>
          </div>

          <div class="grid align-items-end"
            ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : 'mb-4'}}">

            <div class="col-12 col-sm-12 no-padding">
              <div class="row"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-6' : 'text-center mt-2'}}">

                <div class="ui-checkbox">
                  <input id="remember" type="checkbox" formControlName="processing">
                  <label for="remember">
                    <span class="ui-checkbox__text">Согласие с обработкой персональных данных </span>
                    <span class="txt-require ml-2">*</span>
                  </label>
                </div>
              </div>

              <div class="row"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-6' : 'mt-6'}}">
                <div class="grid align-items-start">
                  <p class="txt-require pr-1">*</p>
                  <p class="txt-caption__large">— Поля отмеченные звездочкой обязательны для заполнения.</p>
                </div>
              </div>

              <div class="row mb-8"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-2' : 'mt-2'}}">

                <p class="txt-caption__large">
                  При регистрации вы подтверждаете согласие с
                  <a routerLink="/{{Routing.Pages}}/{{Routing.Rules}}" target="_blank">условиями использования Музыканты России</a> и
                  <a routerLink="/{{Routing.Pages}}/{{Routing.Confidential}}" target="_blank">политикой о данных пользователей.</a>
                </p>
              </div>

              <div class="sign-up__forms-errors __sign-up__errors">
                <div class="grid align-items-baseline justify-content-center">
                  <span class="mr-icon mr-icon-status-error"></span>
                  <p>{{ErrorsText}}</p>
                </div>
              </div>

              <div class="row mt-4">
                <div class="grid align-items-center justify-content-center">
                  <div class="col-4">
                    <button class="ui-buttons ui-buttons__blue-large" (click)="onSubmitForm()" pRipple>
                      Зарегистрироваться
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </form>
      </div>

    </div>
  </div>

  <p-toast position="top-center" key="signUpToast"
    [baseZIndex]="5000"
    [styleClass]="'p-toast__sign-up'">
  </p-toast>
</div>


