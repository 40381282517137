<div class="notification-dialog__popup">
  <div class="row">
    <div class="notification-dialog__row default" ngClass="{{onDialogTemplateType}}">
      <div class="notification-dialog__row-icons" *ngIf="NDTypes === DialogTypes.PopupTemplate">
        <div class="grid align-items-center flex-column justify-content-center text-center">
          <span *ngIf="NDIcons" class="{{NDIcons}}"></span>
        </div>
      </div>
      <div class="notification-dialog__row-icons" *ngIf="NDTypes === DialogTypes.PopupSuccessTemplate">
        <div class="grid align-items-center flex-column justify-content-center text-center">
          <span class="{{NDIcons || 'pi pi-check-circle'}}"></span>
        </div>
      </div>
      <div class="notification-dialog__row-icons" *ngIf="NDTypes === DialogTypes.PopupErrorTemplate">
        <div class="grid align-items-center flex-column justify-content-center text-center">
          <span class="{{NDIcons || 'pi pi-times-circle'}}"></span>
        </div>
      </div>
      <div class="notification-dialog__row-text">
        <div class="grid align-items-center flex-column justify-content-center text-center">
          <!--<p class="_content-txt" *ngIf="NDContent">{{NDContent}}</p>-->
          <p class="_content-txt" *ngIf="NDContent" [innerHTML]="onTextSanitize(NDContent)"></p>
        </div>
      </div>
    </div>

    <div class="notification-dialog__row-links">
      <button class="ui-buttons ui-buttons__blue-large" (click)="onCloseDialog()">
        Закрыть
      </button>
    </div>
  </div>
</div>
