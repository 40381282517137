import { Pipe, PipeTransform } from '@angular/core';
import { FundamentalsService } from '@app/modules/fundamentals-modules/fundamentals.service';
import { InstrumentModel } from '@app/modules/fundamentals-modules/fundamentals.models';
import { IBulletinItem, IBulletinDetail, IBulletinRouteType } from '@app/modules/bulletins-modules/bulletins.model';

export type IHeadingPipeDetail = 'searchType' | 'detailType' | 'ssrType';

@Pipe({
  name: 'bulletinHeading'
})

export class BulletinHeadingPipe implements PipeTransform {
  constructor(
    private fundamentalsService: FundamentalsService
  ) {}

  transform(bulletin: any, detail: IHeadingPipeDetail, toLowerCase: boolean = true): string {
    if (detail === 'searchType') {
      return this.shortHeader((<IBulletinItem>bulletin).instrumentId, bulletin.type, true);
    }

    if (detail === 'detailType') {
      if (bulletin.type === 'ArtistSearchBand') {
        return this.detailHeader(
          (<IBulletinDetail>bulletin).musicianViewModel.instrumentId, bulletin.type
        );
      }
      if (bulletin.type === 'BandSearchArtist') {
        return this.detailHeader(
          (<IBulletinDetail>bulletin).bandRequestViewModel.instrumentId, bulletin.type, (<IBulletinDetail>bulletin).performer
        );
      }
    }

    if (detail === 'ssrType') {
      if (bulletin.type === 'ArtistSearchBand') {
        return 'Музыкант ищет группу'
      }
      if (bulletin.type === 'BandSearchArtist') {
        return 'Группа ищет музыканта'
      }
    }

    return this.shortHeader(bulletin.instrumentId, bulletin.type);
  }

  /**
   * Getting of transform short title for bulletin.
   * @param instrumentId
   * @param type -- type of bulletins (ArtistSearchBand -- musician search, BandSearchArtist -- band search)
   * @param lower -- title in lowerCase
   */
  shortHeader( instrumentId: number, type: IBulletinRouteType, lower?: boolean ): string {
    const InstrumentsDependencies = this.fundamentalsService.instrumentsDependencies || [];

    const Instrument = <InstrumentModel>InstrumentsDependencies.filter(
      item => item.id === instrumentId
    )[0] || null;

    if (type === 'ArtistSearchBand') {
      return Instrument ?
        `${Instrument.genetiveForArtistTitle} ищет группу` :
        `Музыкант ищет группу`;
    }
    if (type === 'BandSearchArtist') {
      return Instrument ?
        `Группа ищет ${lower ? Instrument.genitiveTitle.toLowerCase() : Instrument.genitiveTitle}` :
        `Группа ищет музыканта`;
    }
  }

  /**
   * Getting of transform short title for bulletin.
   * @param instrumentId
   * @param type -- type of bulletins (ArtistSearchBand -- musician search, BandSearchArtist -- band search)
   * @param performer -- name of band
   */
  detailHeader( instrumentId: number, type: IBulletinRouteType, performer?: string): string {
    const InstrumentsDependencies = this.fundamentalsService.instrumentsDependencies || [];

    const Instrument = <InstrumentModel>InstrumentsDependencies.filter(
      item => item.id === instrumentId
    )[0] || null;

    if (type === 'ArtistSearchBand') {
      return Instrument ?
        `${Instrument.genetiveForArtistTitle} ищет группу` :
        `Музыкант ищет группу`;
    }
    if (type === 'BandSearchArtist') {
      return Instrument ?
        `Группа ${'«' + performer + '»' || ''} ищет ${Instrument.genitiveTitle.toLowerCase()}` :
        `Группа ${'«' + performer + '»' || ''} ищет музыканта`;
    }
  }
}
