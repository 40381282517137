import { AppStaticURL, AppPROD } from '@app/app.settings';
import { ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DevicesClass } from '@app/core/classes/component/devices.class';
import { IBlogPostNewsDetail } from '@app/modules/news-modules/news.models';
import { FavoritesService } from '@app/modules/favorites-modules/favorites.service';
import { NewsService } from '@app/modules/news-modules/news.service';
import { RoutingConfig } from '@app/routing/routing.config';
import { IAccountUserInfo } from '@app/modules/account-modules/account.model';
import { AccountEventBus, AccountStoryService } from '@app/modules/account-modules/account.story';
import { AuthStoryService } from '@app/modules/auth-modules/auth.story';
import { NewsStoryService, NewsEventBus } from '@app/modules/news-modules/news.story';
import { untilDestroyed } from '@ngneat/until-destroy';

export enum NewsViewTypes {
  Preview,    // 0
  Detail,     // 1
  Profile,    // 2
  ProfileBlock,    // 2
}

@Component({
  selector: 'shared-news-item',
  templateUrl: 'news-item.component.html',
  styleUrls: [`./news-item.component.scss`],
})

export class NewsItemComponent extends DevicesClass implements OnInit {
  public NTypes = NewsViewTypes;
  public NRouting = RoutingConfig;
  public StaticURL = AppStaticURL;

  public NewsMainPicture: boolean;
  public NewsThumbnailError: boolean;
  public NUserAuth: IAccountUserInfo = null;
  public NewsFavId: number = 0;
  public NewsFavLoading: boolean = false;
  public NewsFavActive: boolean = false;

  @Input() NewsItem: IBlogPostNewsDetail;
  @Input() NewsView: NewsViewTypes;

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    private router: Router,
    private sanitizer: DomSanitizer,
    private favoritesService: FavoritesService,
    private newsService: NewsService,
    private accountStory: AccountStoryService,
    private authStoryService: AuthStoryService,
    private newsStoryService: NewsStoryService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.onAccountStoryBus();
    this.onNewsStoryBus();
    this.onRenderFullPicture();
  }

  onAccountStoryBus(): void {
    this.accountStory.AccountEventBus$.pipe(untilDestroyed(this)).subscribe(
      emit => this.onAccountStoryListener(emit)
    );
  }

  onAccountStoryListener(emit: AccountEventBus): void {
    if (emit.event === 'onAccountUpdate') {
      this.NUserAuth = emit.data;
    }

    if (emit.event === 'onAccountLogout' || emit.event === 'onAccountEmpty') {
      this.NUserAuth = null;
    }

    this.cdr.markForCheck();
  }

  onNewsStoryBus(): void {
    this.newsStoryService.NewsEventBus$.pipe(untilDestroyed(this)).subscribe(
      emit => this.onNewsStoryListener(emit)
    );
  }

  onNewsStoryListener(emit: NewsEventBus): void {
    if (emit.event === 'onUpdateFavorites') {
      this.onMapFavoritesNews(emit.data);
    }
  }

  onMapFavoritesNews(news: Array<IBlogPostNewsDetail>): void {
    news.map(newsFavorite => {
      if (!newsFavorite.itemId) {
        return;
      }
      if (newsFavorite.itemId === (this.NewsItem.itemId || this.NewsItem.id)) {
        this.NewsFavId = newsFavorite.id;
        this.NewsFavActive = true;
        this.cdr.markForCheck();
      }
    })
  }

  onTextParser(newsText: string): any {
    return NewsItemComponent.onStringToHTML(newsText);
  }

  onTextSanitize(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html)
  }

  onRenderFullPicture(): void {
    if (this.NewsView === this.NTypes.Detail) {
      const parseTextImg = this.NewsItem.text.split('img');
      const parseTextFrame = this.NewsItem.text.split('iframe');

      if (parseTextImg.length === 1 && parseTextFrame.length === 1) {
        this.NewsMainPicture = true;
      }
    }
  }

  onViewDetailNews(): string {
    return AppPROD ? '_target' : '_self';
  }

  onLikeNews(): void {
    if (!this.NUserAuth) {
      return this.authStoryService.AuthEventBus.next({
        event: 'onAuthNeed',
        data: 'Чтобы оценить новость'
      });
    }

    this.newsService.likeNews(this.NewsItem.id).subscribe(
      response => {
        this.newsService.getNewsById(this.NewsItem.id).subscribe(
          value => {
            this.NewsItem = value;
            this.cdr.markForCheck();
          }
        );
      }
    )
  }

  onDislikeNews(): void {
    if (!this.NUserAuth) {
      return this.authStoryService.AuthEventBus.next({
        event: 'onAuthNeed',
        data: 'Для оценки новости'
      });
    }

    this.newsService.dislikeNews(this.NewsItem.id).subscribe(
      response => {
        this.newsService.getNewsById(this.NewsItem.id).subscribe(
          value => {
            this.NewsItem = value;
            this.cdr.markForCheck();
          }
        );
      }
    )
  }

  onAddToFavorites(): void {
    if (!this.NUserAuth) {
      return this.authStoryService.AuthEventBus.next({
        event: 'onAuthNeed',
        data: 'Для добавления в избранное'
      });
    }
    this.onLoadingFavorites(true);
    /**
     * Get bulletin ID.
     */
    const Id = this.NewsItem.id;

    /**
     * Get bulletin Type.
     */
    const Type = 1;

    /**
     * Add to favorites request.
     */
    this.favoritesService.addToFavorites(Id, Type).subscribe(
      r => {
        this.NewsFavActive = true;
        this.onLoadingFavorites(false);
      },
      e => {
        if (e === 400) {
          this.NewsFavActive = true;
        }
        this.onLoadingFavorites(false)
      }
    );
  }

  onRemoveFromFavorites(): void {
    /**
     * Get bulletin ID.
     */
    const Id = this.NewsItem.id;

    this.favoritesService.removeFromFavorites(Id).subscribe(
      r => {
        this.NewsFavActive = false;
        this.cdr.markForCheck();
      },
      e => {
        this.NewsFavActive = false;
        this.cdr.markForCheck();
      }
    );
  }


  onLoadingFavorites(status: boolean): void {
    this.NewsFavLoading = status;
    this.cdr.markForCheck();
  }

  static onStringToHTML(text: string): string {
    const dom = document.createElement('div');
    dom.innerHTML = text;

    return (<any>dom.firstChild).innerText;
  }
}
