<shared-breadcrumbs [ComponentData]="BreadcrumbsData"></shared-breadcrumbs>
<div class="pg-content__inner-transparent">
  <div class="pg-wrapper">
    <div class="sign-in__loading animated faster fadeIn" *ngIf="SignInLoading">
      <div class="ui-loader ui-loader__page-dark"></div>
    </div>
    <div class="sign-in__wrapper"
      ngClass="{{SignInLoading ? 'loading' : ''}}">

      <div class="row"
        ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'pt-6 pb-6' : 'pt-4 pb-4'}}">

        <div class="sign-in__heading">
          <h1>Вход на сайт</h1>
        </div>
      </div>

      <div class="row">
        <form class="sign-in__forms" [formGroup]="SignInForm" (ngSubmit)="onSubmitForm()" autocomplete="off">
          <div class="sign-in__forms-input">
            <div class="ui-input ui-input__text"
              ngClass="{{SignInErrorsField.email ? 'error' : ''}}">

              <input type="text" placeholder="E-mail" formControlName="email" autofocus="autofocus"
                (blur)="onCheckInput('email')">
            </div>
          </div>
          <div class="sign-in__forms-input">
            <div class="ui-input ui-input__text"
              ngClass="{{SignInErrorsField.password ? 'error' : ''}}">

              <input type="password" placeholder="Пароль" formControlName="password"
                (blur)="onCheckInput('password')" #passwordInput>

              <div class="ui-input__view-password" (click)="onToggleDisplayPassword()"
                ngClass="{{isVisiblePassword() ? 'view' : ''}}">
                <span class="mdi"></span>
              </div>
            </div>
          </div>

          <div class="sign-in__forms-links">
            <div class="grid align-items-center">
              <div class="col-6 no-padding text-left">
                <a (click)="onSignInRemindPopup($event)">Напомнить пароль</a>
              </div>
              <div class="col-6 no-padding text-right">
                <a (click)="onSignInToSignUp($event)">Зарегистрироваться</a>
              </div>
            </div>
          </div>

          <div class="sign-in__forms-errors __sign-in__errors">
            <div class="grid align-items-baseline justify-content-center">
              <span class="mr-icon mr-icon-status-error"></span>
              <p>{{SignInErrorsText}}</p>
            </div>
          </div>

          <div class="sign-in__forms-links">
            <button class="ui-buttons ui-buttons__blue-large" pRipple>Войти на сайт</button>
          </div>

          <div class="sign-in__forms-links">
            <div class="row text-center">
              <div class="ui-checkbox">
                <input id="remember" type="checkbox" formControlName="remember">
                <label for="remember">
                  <span class="ui-checkbox__text">Запомнить меня</span>
                </label>
              </div>
            </div>
          </div>

          <div class="sign-in__forms-links">
            <div class="row text-center">
              <p>
                При входе вы подтверждаете согласие с
                <a (click)="onSignInRouterLink($event, Routing.Rules, true)">условиями использования Музыканты России</a> и
                <a (click)="onSignInRouterLink($event, Routing.Confidential, true)">политикой о данных пользователей.</a>
              </p>
            </div>
          </div>
        </form>
      </div>

      <div class="row sign-in__social">
        <div class="grid h-full align-items-center justify-content-center">
          <div class="col-auto sign-in__social-link" (click)="oauthService.onOAuthWindow(OAuthTypes.VKontakte)">
            <span class="mr-icon mr-icon-vk-color">
              <span class="path1"></span>
              <span class="path2"></span>
            </span>
          </div>
          <div class="col-auto sign-in__social-link" (click)="oauthService.onOAuthWindow(OAuthTypes.Facebook)">
            <span class="mr-icon mr-icon-fb-color">
              <span class="path1"></span>
              <span class="path2"></span>
            </span>
          </div>
          <div class="col-auto sign-in__social-link" (click)="oauthService.onOAuthWindow(OAuthTypes.Odnoklassniki)">
            <span class="mr-icon mr-icon-od-color">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>
          </div>
          <div class="col-auto sign-in__social-link" (click)="oauthService.onOAuthWindow(OAuthTypes.Google)">
            <span class="mr-icon mr-icon-google-color">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
              <span class="path5"></span>
            </span>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
