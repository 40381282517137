import { Routes } from '@angular/router';
import { SignInSeoParams, SignRemindSeoParams, SignUpSeoParams } from '@app/modules/auth-modules/auth.seo';
import { RoutingConfig } from '@app/routing/routing.config';

/**
 * Components
 */
import { SignInComponent } from './sign-in/sign-in.component';
import { SignOauthComponent } from './sign-oauth/sign-oauth.component';
import { SignRemindComponent } from './sign-remind/sign-remind.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { SignSuccessComponent } from './sign-success/sign-success.component';

export const AuthRoutes: Routes = [
  { 
    path: RoutingConfig.SignIn,
    component: SignInComponent,
    data: {
      SEO: SignInSeoParams
    }
  },
  {
    path: RoutingConfig.SignOAuth,
    component: SignOauthComponent,
    data: {
      SEO: SignInSeoParams
    }
  },
  {
    path: RoutingConfig.SignRemind,
    component: SignRemindComponent,
    data: {
      SEO: SignRemindSeoParams
    }
  },
  {
    path: RoutingConfig.SignUp,
    component: SignUpComponent,
    data: {
      SEO: SignUpSeoParams
    }
  },
  {
    path: RoutingConfig.SignSuccess,
    component: SignSuccessComponent,
    data: {
      SEO: SignUpSeoParams
    }
  },
  {
    path: '**',
    redirectTo: RoutingConfig.SignIn,
    pathMatch: 'full'
  }
];
