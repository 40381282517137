import { ReplaySubject } from 'rxjs';
/**
 * Export of Dialog Component interface.
 */
export enum DialogIncludesTypes {
  PopupTemplate,          // 0
  PopupSuccessTemplate,   // 1
  PopupErrorTemplate,     // 2
  ComponentTemplate       // 3
}

/**
 * Export of Dialog InputData interface.
 */
export interface DialogInputData {
  type: DialogIncludesTypes;
  header: string;
  content: string;
  icons?: string
}

/**
 * Export of Dialog IncludesData interface.
 */
export interface DialogIncludesData {
  contentText?: string;
  contentIcons?: string;
  dataInitial?: any,
  dataSubject?: ReplaySubject<any>
}

/**
 * Export of Dialog ComponentModel interface.
 */
export interface DialogComponentModel {
  includeType: DialogIncludesTypes;
  includeData: DialogIncludesData;
}
