import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AccountMenuComponent } from './account-menu.component';

/**
 * Vendors
 */
import { BadgeModule } from 'primeng/badge';

@NgModule({
  declarations: [
    AccountMenuComponent
  ],
  exports: [
    AccountMenuComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    BadgeModule
  ]
})

export class AccountMenuModule {
  constructor() {}
}
