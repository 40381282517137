import { AppApiURL } from '@app/app.settings';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { CityModel, CitiesModel } from '@app/modules/fundamentals-modules/fundamentals.models';
import { FundamentalsService } from '@app/modules/fundamentals-modules/fundamentals.service';
import { StorageService } from '@app/core/services';
import { HttpRequestToken } from '@app/core/classes';
import { StorageFundamentals } from '@app/core/constants';
import { Observable } from 'rxjs';
import { map, timeout } from 'rxjs/operators';

@Injectable()

export class CitiesService {
  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private fundamentalsService: FundamentalsService
  ) {}

  /**
   * Api: v01.
   * Method: Cities CityIdByIp
   */
  public cityIdByIp(): Observable<CityModel> {
    return this.http.get<any>(AppApiURL + '/cities/cityIdByIp', {
      params: new HttpRequestToken(true)
    }).pipe(
      timeout(10000),
      map((response: number) =>
        response ?
        this.onGetCityByDictionary(response) :
        this.onGetCityByDictionary(604)
      ),
    )
  }

  /**
   * Api: v01.
   * Method: Cities CityIdByKey
   */
  public cityIdByKey (key?: string): Observable<CityModel> {
    return this.http.get<any>(`${AppApiURL}/cities/${key}?cityId=${key}`, {
      params: new HttpRequestToken(true)
    }).pipe(
      timeout(10000)
    );
  }

  /**
   * Api: v01.
   * Method: Cities Search
   * @param input
   */
  public citiesSearch(input: string): Observable<CitiesModel> {
    return this.http.get<CitiesModel>(AppApiURL + '/cities/search/' + input, {
      params: new HttpRequestToken(true)
    }).pipe(
      timeout(10000)
    )
  }

  /**
   * Getting of City by dictionary filter.
   * @param cityId
   */
  onGetCityByDictionary(cityId: number): CityModel {
    const CityDictionary = this.fundamentalsService.citiesDependencies.filter((item: CityModel) => {
      return item.id === cityId;
    });

    return CityDictionary.length ? CityDictionary[0] : null;
  }

  /**
   * Getting of default City.
   */
  onGetCityDefault(): CityModel {
    return this.fundamentalsService.citiesRecommendations[0];
  }

  /**
   * Save selected City to LocalStorage.
   */
  onSaveSelectedCity(city: CityModel): void {
    this.storage.setStorage({
      key: StorageFundamentals.cities + '__selected',
      value: city
    });
  }

  /**
   * Save selected City to LocalStorage.
   */
  onGetSelectedCity(): CityModel {
    try {
      return JSON.parse(
        this.storage.getStorage({ key: StorageFundamentals.cities + '__selected' })
      );
    } catch (e) { return null }
  }
}
