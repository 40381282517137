import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { SEOService } from '@app/core/services';
import { AccountEventBus, AccountStoryService } from '@app/modules/account-modules/account.story';
import { AccountService } from '@app/modules/account-modules/account.service';
import { AuthStoryService } from '@app/modules/auth-modules/auth.story';
import { FundamentalsService } from '@app/modules/fundamentals-modules/fundamentals.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CitiesService } from '@app/modules/cities-module/cities.service';
import { CitiesStoryService } from '@app/modules/cities-module/cities.story';
import { RoutingConfig } from '@app/routing/routing.config';
import { NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { NotificationStoryService } from '@app/modules/notification-modules/notification.story';
import { NotificationService } from '@app/modules/notification-modules/notification.service';
import { _deviceMobile } from '@app/utils/device';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AppComponent implements OnInit {
  public Routing = RoutingConfig;
  public Loading: boolean;

  constructor(@Inject(PLATFORM_ID)
    private platformId: Object,
    private cdr: ChangeDetectorRef,
    private seo: SEOService,
    private accountStory: AccountStoryService,
    private accountService: AccountService,
    private authStory: AuthStoryService,
    private fundamentalsService: FundamentalsService,
    private citiesService: CitiesService,
    private citiesStory: CitiesStoryService,
    private router: Router,
    private notificationStory: NotificationStoryService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    /**
     * Scroll page to top on routing changed.
     */
    this.onScrollToTopPage();

    /**
     * Initialize of SEO settings.
     */
    this.onInitSEO();

    /**
     * Initialize of Fundamentals dependencies.
     */
    this.onInitFundamentals();

    /**
     * Initialize and subscribe on Account Changes.
     */
    this.onAccountGetMyInfo();
    this.onAccountStoryBus();
  }

  onPageLoading(status: boolean, delay: number = 0): void {
    setTimeout(() => {
      this.Loading = status;
      this.cdr.detectChanges();
    }, delay);
  }

  onScrollToTopPage(): void {
    this.router.events.pipe(untilDestroyed(this)).subscribe((e: RouterEvent) => {
      if (e instanceof NavigationStart) {
        /**
         * Start loading spinner.
         */
        if (_deviceMobile()) {
          this.onPageLoading(true);
        }
      }
      if (e instanceof NavigationEnd) {
        const LocationRoute =  location.pathname.split('/').some((item, key) => {
          return (
            item === RoutingConfig.News ||
            item === RoutingConfig.Pages ||
            item === RoutingConfig.Profile ||
            item === RoutingConfig.SignIn ||
            item === RoutingConfig.SignUp
          )
        });
        if (LocationRoute) {
          setTimeout(e => { window.scrollTo(0, 0) }, 0);
        }

        /**
         * Update of Account Notifications.
         */
        this.onAccountNotification();

        /**
         * Stop loading spinner.
         */
        this.onPageLoading(false, 500);
      }
    });
  }

  onInitSEO(): void {
    this.seo.init();
  }

  onInitFundamentals(): void {
    this.fundamentalsService.onUploadDependencies().subscribe(
      r => this.onInitCityByIp(),
      e => {
        this.citiesStory.CitiesEventBus.next({
          event: 'onSelectCityApp',
          data: this.citiesService.onGetCityDefault()
        })
      }
    );
  }

  onInitCityByIp(): void {
    const SavedCity = this.citiesService.onGetSelectedCity();

    if (SavedCity) {
      return this.citiesStory.CitiesEventBus.next({
        event: 'onSelectCityApp',
        data: SavedCity
      });
    }

    this.citiesService.cityIdByIp().subscribe(
      response => this.citiesStory.CitiesEventBus.next({
        event: 'onSelectCityApp',
        data: response
      })
    );
  }

  onAccountGetMyInfo(): void {
    this.accountService.onIniGetMyInfo();
  }

  onAccountNotification(): void {
    this.notificationService.onIniUnreadedCount()
  }

  onAccountStoryBus(): void {
    this.accountStory.AccountEventBus$.pipe(untilDestroyed(this)).subscribe(
      emit => this.onAccountStoryListener(emit)
    );
  }

  onAccountStoryListener(emit: AccountEventBus): void {
    if (emit.event === 'onAccountLogout') {
      this.authStory.AuthEventBus.next({
        event: 'onAuthLogout'
      });

      this.notificationStory.NotificationEventBus.next({
        event: 'onUpdateCount',
        data: 0
      });
    }
  }
}
