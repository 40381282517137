import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { RoutingConfig } from '../routing.config';

const routes: Routes = [
  {
    path: RoutingConfig.Root,
    loadChildren: () => import('../../components/root/client/root.module').then((m) => m.RootModule)
  },
  {
    path: RoutingConfig.ShellRoot,
    loadChildren: () => import('../../components/screen/client/screen.module').then((m) => m.ScreenModule)
  },
  {
    path: RoutingConfig.ShellRoot,
    loadChildren: () => import('../../components/shell/client/shell.module').then((m) => m.ShellModule)
  },
  {
    path: '**',
    redirectTo: RoutingConfig.Page404,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      // preloadingStrategy: PreloadAllModules,
      // scrollPositionRestoration: 'enabled'
    }
  )],
  exports: [RouterModule]
})
export class RoutingModule { }
