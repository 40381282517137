<shared-breadcrumbs [ComponentData]="BreadcrumbsData"></shared-breadcrumbs>

<div class="pg-content__inner">
  <div class="account-shell__wrapper">
    <div class="pg-wrapper" ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'pt-6 pb-4' : 'pt-4 pb-4'}}">
      <div class="row text-center">
        <h3>{{AccountHeading}}</h3>
      </div>
    </div>
    <div class="row" *ngIf="(deviceValue | async) === DTypes.Desktop && ASUser">
      <shared-account-menu [Device]="DTypes.Desktop"></shared-account-menu>
    </div>
  </div>

  <router-outlet></router-outlet>
</div>
