<div class="account-shell__loading animated faster fadeIn" *ngIf="ProfileLoading">
  <div class="ui-loader ui-loader__page-light"></div>
</div>

<div class="account-shell__content"
  ngClass="{{ProfileLoading ? 'loading' : ''}}">

  <div class="pg-wrapper" *ngIf="ProfileSubject | async as Profile">

    <form [formGroup]="ProfileForm" (keydown.enter)="$event.preventDefault()" autocomplete="off">

      <div class="account-shell__content-wrapper" *ngIf="!Profile.emailConfirmed"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? '' : 'pl-3 pr-3'}}">

        <div class="row">
          <div class="profile__confirmed status-warning">
            <div class="grid align-items-center justify-content-center flex-column">
              <div class="col-12 col-sm-12 text-center">
                <span class="mr-icon mr-icon-status-error mt-2 mb-4"></span>
                <h5>Ваш профиль не подтвержден!</h5>
                <p class="mt-2 mb-6">
                  Для подтверждения учетной записи пройдите по ссылке в письме, отправленного на почту при регистрации.<br/>
                  Если Вам <strong>не пришло на почту письмо</strong>, Вы можете отправить его повторно.
                </p>
              </div>
              <div class="col-3 col-sm-6">
                <button class="ui-buttons ui-buttons__blue-large" (click)="onReconfirmProfile()"
                  ngClass="{{ProfileReconfirmModify ? 'disabled' : ''}}">
                  Отправить
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="account-shell__content-wrapper"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? '' : 'pl-3 pr-3'}}">

        <div class="account-shell__content-subtitle">
          <h5>Личная информация</h5>
          <div class="grid align-items-start pt-2">
            <p class="txt-require pr-1">*</p>
            <p class="txt-caption__large">— Поля отмеченные звездочкой обязательны для заполнения и не могут быть пустыми.</p>
          </div>
        </div>
      </div>

      <div class="account-shell__content-wrapper">
        <div class="grid align-items-start justify-content-center"
          ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

          <div class="col-4 col-sm-12">
            <div class="account-shell__avatar">
              <div class="account-shell__avatar-event">
                <div class="account-shell__avatar-event__clear" *ngIf="ProfileAvatar" (click)="onUploadAvatarClear()">
                  <i class="pi pi-times-circle"></i>
                </div>

                <div class="account-shell__avatar-event__picture">
                  <input type="file" id="avatarUpload" class="account-shell__avatar-event__picture-input"
                    (change)="onUploadAvatarEvent($event.target['files'])"
                    [accept]="fileAcceptString">

                  <span class="ui-graphics__balalaika-light" *ngIf="!ProfileAvatar"></span>
                  <img src="{{ProfileAvatar}}" (error)="onAvatarError()" *ngIf="ProfileAvatar">

                  <div class="account-shell__avatar-event__picture-link" (click)="onUploadAvatarHandler()">
                    <div class="grid align-items-center justify-content-center">
                      <i class="pi pi-upload"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="account-shell__avatar-heading">
                <h5>{{Profile.username}}</h5>
              </div>
              <div class="account-shell__avatar-helper">
                <p>Размер не должен превышать 1Mb.<br/>Допустимые типы файлов: jpg, png, gif.</p>
              </div>
            </div>
          </div>

          <div class="col-8 col-sm-12">
            <div class="account-shell__form">

              <div class="grid align-items-end"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : 'mb-4'}}">

                <div class="col-6 col-sm-12 no-padding">
                  <div class="account-shell__forms-input"
                    ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-2 pr-2'}}">

                    <div class="ui-input__placeholder">
                      <p>Имя</p>
                      <span class="require">*</span>
                      <p class="error-holder" *ngIf="ErrorsField.name.status">
                        {{ErrorsField.name.value}}
                      </p>
                    </div>
                    <div class="ui-input ui-input__text"
                      ngClass="{{ErrorsField.name.status ? 'error' : ''}}">
                      <input type="text" placeholder="Имя" formControlName="name">
                    </div>
                  </div>
                </div>

                <div class="col-6 col-sm-12 no-padding">
                  <div class="account-shell__forms-input"
                    ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-2 pr-2'}}">

                    <div class="ui-input__placeholder">
                      <p>Фамилия</p>
                      <p class="error-holder" *ngIf="ErrorsField.lastName.status">
                        {{ErrorsField.lastName.value}}
                      </p>
                    </div>
                    <div class="ui-input ui-input__text"
                      ngClass="{{ErrorsField.lastName.status ? 'error' : ''}}">
                      <input type="text" placeholder="Фамилия" formControlName="lastName">
                    </div>
                  </div>
                </div>
              </div>

              <div class="grid align-items-end"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : 'mb-4'}}">

                <div class="col-6 col-sm-12 no-padding">
                  <div class="account-shell__forms-input"
                    ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-2 pr-2'}}">

                    <div class="ui-input__placeholder">
                      <p>E-mail</p>
                      <span class="require">*</span>
                      <p class="error-holder" *ngIf="ErrorsField.email.status">
                        {{ErrorsField.email.value}}
                      </p>
                    </div>
                    <div class="ui-input ui-input__text"
                      ngClass="{{ErrorsField.email.status ? 'error' : ''}}">
                      <input type="text" placeholder="Имя" formControlName="email"
                        tooltipStyleClass="p-tooltip__input"
                        pTooltip="При изменении email потребуется его повторное подтверждение"
                        tooltipPosition="{{(deviceValue | async) === DTypes.Mobile ? 'bottom' : 'right'}}"
                        hideDelay="0"
                        tooltipEvent="focus">
                    </div>
                  </div>
                </div>

                <div class="col-6 col-sm-12 no-padding">
                  <div class="account-shell__forms-input"
                    ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-2 pr-2'}}">

                    <div class="ui-input__placeholder">
                      <p>Дата рождения</p>
                      <p class="error-holder" *ngIf="ErrorsField.birthday.status">
                        {{ErrorsField.birthday.value}}
                      </p>
                    </div>
                    <div class="ui-input ui-input__text"
                      ngClass="{{ErrorsField.birthday.status ? 'error' : ''}}">
                      <div class="ui-input__helper">
                        <div class="ui-input__helper-icon cta" (click)="picker.open()">
                          <span class="mr-icon mr-icon-date"></span>
                        </div>
                      </div>
                      <!--<p-inputMask placeholder="01.01.1991" formControlName="birthday" mask="99.99.9999"></p-inputMask>-->

                      <input type="text" placeholder="01.01.1991" mask="d0.M0.0000" formControlName="birthday"
                        (input)="onSetBirthdayValue($event.target.value)"
                        (blur)="onCheckFormattedDate()">
                      
                      <input type="hidden"
                        [matDatepicker]="picker"
                        [max]="onGetCurrentDate()"
                        (dateChange)="onSetBirthdayValue($event.value, true)">
                      <mat-datepicker touchUi #picker></mat-datepicker>
                    </div>
                  </div>
                </div>
              </div>

              <div class="grid align-items-end"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : 'mb-4'}}">

                <div class="col-6 col-sm-12 no-padding">
                  <div class="account-shell__forms-input"
                    ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-2 pr-2'}}">

                    <div class="ui-input__placeholder">
                      <p>Город</p>
                      <span class="require">*</span>
                      <p class="error-holder" *ngIf="ErrorsField.cityId.status">
                        {{ErrorsField.cityId.value}}
                      </p>
                    </div>
                    <div class="ui-input ui-input__text"
                      ngClass="{{ErrorsField.cityId.status ? 'error' : ''}}">
                      <div class="ui-input__cup cta ui-scroll-horizontal" (click)="onViewSearchCities()">
                        <input type="text" (focus)="onViewSearchCities()" readonly>
                        <span *ngIf="!ProfileCityValue">Выбрать город</span>

                        <p-chip *ngIf="ProfileCityValue"
                          label="{{ProfileCityValue.name}}"
                          styleClass="input__chip">
                        </p-chip>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-6 col-sm-12 no-padding">
                  <div class="account-shell__forms-input"
                    ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-2 pr-2'}}">

                    <div class="ui-input__placeholder">
                      <p>Ваш инструмент или должность</p>
                      <span class="require">*</span>
                      <p class="error-holder" *ngIf="ErrorsField.instrumentIds.status">
                        {{ErrorsField.instrumentIds.value}}
                      </p>
                    </div>
                    <div class="ui-input ui-input__text"
                      ngClass="{{ErrorsField.instrumentIds.status ? 'error' : ''}}">
                      <div class="ui-input__cup cta ui-scroll-horizontal" (click)="onViewSearchActivity()">

                        <span *ngIf="!ProfileActivityPosition && !ProfileActivityInstruments.length">
                          Выбрать инструмент или должность
                        </span>

                        <div class="grid align-items-center flex-nowrap "
                          *ngIf="ProfileActivityPosition || ProfileActivityInstruments.length">

                          <p-chip *ngIf="ProfileActivityPosition && ProfileActivityPosition.id !== 0"
                            label="{{ProfileActivityPosition.title}}"
                            styleClass="input__chip">
                          </p-chip>

                          <ng-container *ngFor="let instrument of ProfileActivityInstruments">
                            <p-chip label="{{instrument.title}}" styleClass="input__chip"></p-chip>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="grid align-items-start"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : 'mb-4'}}">

                <div class="col-6 col-sm-12 no-padding">
                  <div class="account-shell__forms--input"
                    ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-2 pr-2'}}">

                    <div class="ui-input__placeholder">
                      <p>Телефон</p>
                      <p class="error-holder" *ngIf="ErrorsField.contactPhone.status">
                        {{ErrorsField.contactPhone.value}}
                      </p>
                    </div>
                    <div class="ui-input ui-input__text" formGroupName="contacts"
                      ngClass="{{ErrorsField.contactPhone.status ? 'error' : ''}}">
                      <input type="text" placeholder="+7 (911) 111-11-11"
                        formControlName="contactPhone"
                        mask="+0 (000) 000-00-00"
                        (input)="onMaskPhone($event)"
                        (blur)="onCheckPhone()">
                    </div>
                  </div>
                </div>

                <div class="col-6 col-sm-12 no-padding">
                  <div class="account-shell__forms--input"
                    ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-2 pr-2'}}">

                    <div class="ui-input__placeholder">
                      <p>Мессенджеры, привязанные к телефону</p>
                    </div>
                    <div class="account-shell__forms-messengers"
                      ngClass="{{onProfileMessengerValid ? '' : 'disabled'}}">

                      <div class="grid align-items-center">
                        <div class="col-auto">
                          <span class="mr-icon mr-icon-telegram-color" (click)="onSetMessengerValue('hasTelegram')"
                            ngClass="{{onGetMessengerStatus('hasTelegram') ? 'selected' : ''}}">
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </span>
                        </div>
                        <div class="col-auto">
                          <span class="mr-icon mr-icon-viber-color" (click)="onSetMessengerValue('hasViber')"
                            ngClass="{{onGetMessengerStatus('hasViber') ? 'selected' : ''}}">
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </span>
                        </div>
                        <div class="col-auto">
                          <span class="mr-icon mr-icon-wh-color" (click)="onSetMessengerValue('hasWhatsapp')"
                            ngClass="{{onGetMessengerStatus('hasWhatsapp') ? 'selected' : ''}}">
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>


            </div>
          </div>
        </div>
      </div>

      <div class="account-shell__content-wrapper"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'pt-8' : 'pl-3 pr-3'}}">

        <div class="account-shell__content-subtitle">
          <h5>Социальне сети</h5>
        </div>
      </div>

      <div class="account-shell__content-wrapper">
        <div class="grid align-items-start justify-content-center">

          <div class="col-12">
            <div class="grid align-items-end"
              ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : 'mb-4'}}">

              <div class="col-4 col-sm-12 no-padding">
                <div class="account-shell__forms-input"
                  ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-2 pr-2'}}">

                  <div class="ui-input__placeholder">
                    <p>VK</p>
                    <p class="error-holder" *ngIf="ErrorsField.vkUsername.status">
                      {{ErrorsField.vkUsername.value}}
                    </p>
                  </div>
                  <div class="ui-input ui-input__text" formGroupName="contacts"
                    ngClass="{{ErrorsField.vkUsername.status ? 'error' : ''}}">
                    <div class="ui-input__helper">
                      <div class="ui-input__helper-icon">
                          <span class="mr-icon mr-icon-vk-color">
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </span>
                      </div>
                    </div>
                    <input type="text" formControlName="vkUsername" placeholder="Ссылка на VK"
                      tooltipStyleClass="p-tooltip__input"
                      pTooltip="Пример ссылки на VK: «vk.com/musicians_russia»"
                      tooltipPosition="bottom"
                      hideDelay="0"
                      tooltipEvent="focus"
                      (blur)="onCheckSocial('vkUsername')">
                  </div>
                </div>
              </div>

              <div class="col-4 col-sm-12 no-padding">
                <div class="account-shell__forms-input"
                  ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-2 pr-2'}}">

                  <div class="ui-input__placeholder">
                    <p>Facebook</p>
                    <p class="error-holder" *ngIf="ErrorsField.facebookUsername.status">
                      {{ErrorsField.facebookUsername.value}}
                    </p>
                  </div>
                  <div class="ui-input ui-input__text" formGroupName="contacts"
                    ngClass="{{ErrorsField.facebookUsername.status ? 'error' : ''}}">
                    <div class="ui-input__helper">
                      <div class="ui-input__helper-icon">
                          <span class="mr-icon mr-icon-fb-color">
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </span>
                      </div>
                    </div>
                    <input type="text" formControlName="facebookUsername" placeholder="Ссылка на Facebook"
                      tooltipStyleClass="p-tooltip__input"
                      pTooltip="Пример ссылки на Facebook: «fb.com/rusmuzikant»"
                      tooltipPosition="bottom"
                      hideDelay="0"
                      tooltipEvent="focus"
                      (blur)="onCheckSocial('facebookUsername')">
                  </div>
                </div>
              </div>

              <div class="col-4 col-sm-12 no-padding">
                <div class="account-shell__forms-input"
                  ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-2 pr-2'}}">

                  <div class="ui-input__placeholder">
                    <p>Instagram</p>
                    <p class="error-holder" *ngIf="ErrorsField.instagramUsername.status">
                      {{ErrorsField.instagramUsername.value}}
                    </p>
                  </div>
                  <div class="ui-input ui-input__text" formGroupName="contacts"
                    ngClass="{{ErrorsField.instagramUsername.status ? 'error' : ''}}">
                    <div class="ui-input__helper">
                      <div class="ui-input__helper-icon">
                          <span class="mr-icon mr-icon-inst-color">
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                            <span class="path4"></span>
                          </span>
                      </div>
                    </div>
                    <input type="text" formControlName="instagramUsername" placeholder="Ссылка на Instagram"
                      tooltipStyleClass="p-tooltip__input"
                      pTooltip="Пример ссылки на Instagram: «musicians_of_russia»"
                      tooltipPosition="bottom"
                      hideDelay="0"
                      tooltipEvent="focus"
                      (blur)="onCheckSocial('instagramUsername')">
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

      <div class="account-shell__content-wrapper">
        <div class="account-shell__content-errors __profile__errors">
          <div class="grid justify-content-center"
            ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'align-items-center text-left' : 'align-items-baseline'}}">

            <span class="mr-icon mr-icon-status-error"></span>
            <p>{{ErrorsText}}</p>
          </div>
        </div>

        <div class="account-shell__content-button">
          <div class="grid align-items-center justify-content-center">
            <div class="col-3 col-sm-6">
              <button class="ui-buttons ui-buttons__blue-large" (click)="onSaveProfile()"
                ngClass="{{ProfileFormModify ? '' : 'disabled'}}">
                Сохранить
              </button>
            </div>
          </div>
        </div>
      </div>

    </form>
  </div>
</div>
