<div class="row breadcrumbs__wrapper" *ngIf="ComponentData">
  <div class="grid justify-content-center">
    <div class="col-auto breadcrumbs__link">
      <a routerLink="{{Routing.Root}}">Главная</a>
    </div>
    <ng-container *ngFor="let crumb of ComponentData">
      <div class="col-auto breadcrumbs__link" *ngIf="!crumb.active">
        <a routerLink="{{crumb.url}}" title="{{crumb.title}}">{{onSplitBreadcrumbs(crumb.title)}}</a>
      </div>
      <div class="col-auto breadcrumbs__link" *ngIf="crumb.active">
        <span title="{{crumb.title}}">{{onSplitBreadcrumbs(crumb.title)}}</span>
      </div>
    </ng-container>
  </div>
</div>
