import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { DevicesClass } from '@app/core/classes';
import { RoutingConfig } from '@app/routing/routing.config';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-sign-success',
  templateUrl: 'sign-success.component.html',
  styleUrls: [`./sign-success.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SignSuccessComponent extends DevicesClass implements OnInit {
  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    public router: Router,
    private primengConfig: PrimeNGConfig,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
  }

  onProfileLink(): void {
    this.router.navigateByUrl(RoutingConfig.Profile).catch();
  }
}
