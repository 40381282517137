import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export type NotificationEventBusTypes = 'onUpdateCount';
export interface NotificationEventBus {
  event: NotificationEventBusTypes;
  data?: any;
}

@Injectable({
  providedIn: 'root'
})

export class NotificationStoryService {
  public NotificationEventBus: Subject<NotificationEventBus> = new Subject<NotificationEventBus>();
  public NotificationEventBus$ = this.NotificationEventBus.asObservable();
}
