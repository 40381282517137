<div *ngIf="!SignRemindPopup">
  <shared-breadcrumbs [ComponentData]="BreadcrumbsData"></shared-breadcrumbs>
  <div class="pg-content__inner-transparent">
    <div class="pg-wrapper">
      <div class="sign-remind__loading animated faster fadeIn" *ngIf="SignRemindLoading">
        <div class="ui-loader ui-loader__page-light"></div>
      </div>
      <div class="sign-remind__wrapper"
        ngClass="{{SignRemindLoading ? 'loading' : ''}}">

        <div class="row"
          ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'pt-6 pb-6' : 'pt-4 pb-4'}}">

          <div class="sign-remind__heading">
            <h1>Восстановление пароля</h1>
          </div>
        </div>

        <div class="row">
          <form class="sign-remind__forms" [formGroup]="SignRemindForm" (ngSubmit)="onSubmitForm()" autocomplete="off">
            <div class="sign-remind__forms-input">
              <div class="ui-input ui-input__text"
                ngClass="{{SignRemindErrorsField.email ? 'error' : ''}}">

                <input type="text" placeholder="E-mail" formControlName="email" autofocus="autofocus"
                  (blur)="onCheckInput('email')">
              </div>
            </div>

            <div class="sign-remind__forms-input">
              <div class="grid align-items-center pt-2 pb-2">
                <span class="mr-icon mr-icon-info"></span>
                <p class="ml-2">Введите E-mail, указанный при регистрации</p>
              </div>
            </div>

            <div class="sign-remind__forms-errors __sign-remind__errors">
              <div class="grid align-items-baseline">
                <span class="mr-icon mr-icon-status-error"></span>
                <p>{{SignRemindErrorsText}}</p>
              </div>
            </div>

            <div class="sign-remind__forms-links">
              <button class="ui-buttons ui-buttons__blue-large" pRipple>Напомнить пароль</button>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
</div>

<div *ngIf="SignRemindPopup">
  <div class="sign-remind__loading animated faster fadeIn" *ngIf="SignRemindLoading">
    <div class="ui-loader ui-loader__page-light"></div>
  </div>

  <div class="sign-remind__popup"
    ngClass="{{SignRemindLoading ? 'loading' : ''}}">
    <div class="row">
      <form class="sign-remind__forms" [formGroup]="SignRemindForm" (ngSubmit)="onSubmitForm()" autocomplete="off">
        <div class="sign-remind__forms-input">
          <div class="ui-input ui-input__text"
            ngClass="{{SignRemindErrorsField.email ? 'error' : ''}}">

            <input type="text" placeholder="E-mail" formControlName="email" autofocus="autofocus"
              (blur)="onCheckInput('email')">
          </div>
        </div>

        <div class="sign-remind__forms-input" *ngIf="!SignRemindSuccess">
          <div class="grid align-items-center pt-2 pb-2">
            <span class="mr-icon mr-icon-info"></span>
            <p class="ml-2">Введите E-mail, указанный при регистрации</p>
          </div>
        </div>

        <div class="sign-remind__forms-errors __sign-remind__errors">
          <div class="grid align-items-baseline">
            <span class="mr-icon mr-icon-status-error"></span>
            <p>{{SignRemindErrorsText}}</p>
          </div>
        </div>

        <div class="sign-remind__forms-success __sign-remind__success" *ngIf="SignRemindSuccess">
          <div class="grid align-items-center pt-2 pb-2">
            <span class="mr-icon mr-icon-info"></span>
            <p>На вашу почту отправлено письмо с дальнейшими инструкциями по восстановлению пароля.</p>
          </div>
        </div>

        <div class="sign-remind__forms-links">
          <button class="ui-buttons ui-buttons__blue-large" pRipple
            ngClass="{{SignRemindSuccess ? 'disabled' : ''}}">
            Напомнить пароль
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
