import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { CityModel } from '@app/modules/fundamentals-modules/fundamentals.models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CitiesService } from '@app/modules/cities-module/cities.service';

export type CitiesEventBusTypes = 'onSelectCityApp' | 'onUpdateCityApp';
export type CitiesEventBusData = CityModel;

export interface CitiesEventBus {
  event: CitiesEventBusTypes;
  data?: CityModel;
  cityId?: number;
}

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})

export class CitiesStoryService {
  public CitiesEventBus: ReplaySubject<CitiesEventBus> = new ReplaySubject<CitiesEventBus>(1);
  public CitiesEventBus$ = this.CitiesEventBus.asObservable();

  constructor(
    private citiesService: CitiesService
  ) {
    this.CitiesEventBus$.pipe(untilDestroyed(this)).subscribe(
      emit => this.onCitiesStoryListener(emit)
    );
  }

  onCitiesStoryListener(emit: CitiesEventBus): void {
    if (emit.event === 'onUpdateCityApp') {
      if (emit.data) {
        return this.citiesService.onSaveSelectedCity(emit.data);
      }

      if (emit.cityId) {
        const CityValue = this.citiesService.onGetCityByDictionary(emit.cityId);

        CityValue ? this.citiesService.onSaveSelectedCity(CityValue) : null;
      }
    }
  }
}
