import { AppApiURL } from '@app/app.settings';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InstrumentModel, InstrumentsModel } from '@app/modules/fundamentals-modules/fundamentals.models';
import { HttpRequestToken } from '@app/core/classes';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { StorageService } from '@app/core/services';
import { FundamentalsService } from '@app/modules/fundamentals-modules/fundamentals.service';

@Injectable()

export class InstrumentsService {
  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private fundamentalsService: FundamentalsService
  ) {}

  /**
   * Api: v01.
   * Method: Genres Search
   */
  public instrumentsSearch(searchText: string): Observable<InstrumentsModel> {
    return this.http.get<InstrumentsModel>(AppApiURL + '/instruments/search/' + searchText, {
      params: new HttpRequestToken(true)
    }).pipe(
      timeout(10000)
    );
  }

  /**
   * Getting of Instrument by dictionary filter.
   * @param instrumentId
   */
  onGetInstrumentByDictionary(instrumentId: number): InstrumentModel {
    const GenreDictionary = this.fundamentalsService.instrumentsDependencies.filter((item: InstrumentModel) => {
      return item.id === instrumentId;
    });

    return GenreDictionary.length ? GenreDictionary[0] : null;
  }

  /**
   * Getting of default Instrument.
   */
  onGetInstrumentDefault(): InstrumentModel {
    return this.fundamentalsService.instrumentsRecommendations[0];
  }
}
