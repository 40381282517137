import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { DevicesClass } from '@app/core/classes';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BreadcrumbsDataModel } from '@app/core/models/breadcrumbs';
import { RoutingConfig } from '@app/routing/routing.config';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DialogIncludesTypes, DialogComponentModel } from '@app/core/models/diallog';
import { EmailStringValidate } from '@app/utils/validator';
import { AccountService } from '@app/modules/account-modules/account.service';
import { PrimeNGConfig } from 'primeng/api';
import * as $ from 'jquery';

export interface SignRemindErrorModels {
  email: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-sign-remind',
  templateUrl: 'sign-remind.component.html',
  styleUrls: [`./sign-remind.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SignRemindComponent extends DevicesClass implements OnInit {
  public BreadcrumbsData: BreadcrumbsDataModel = [
    {
      title: 'Восстановление пароля',
      url: `/${RoutingConfig.Login}/${RoutingConfig.SignRemind}`,
      active: true
    }
  ];

  /**
   * Base component variables.
   */
  public SignRemindPopup: boolean;
  public SignRemindForm: FormGroup;
  public SignRemindErrorsField: SignRemindErrorModels;
  public SignRemindErrorsText: string;
  public SignRemindLoading: boolean;
  public SignRemindSuccess: boolean;

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private dialogConfig: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private primengConfig: PrimeNGConfig,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;

    /**
     * Build SignRemind form.
     */
    this.SignRemindForm = this.formBuilder.group({
      email: ['', Validators.compose([
        Validators.required, Validators.pattern(EmailStringValidate())
      ])]
    });

    /**
     * Set of default errors value.
     */
    this.SignRemindErrorsField = {
      email: false
    };

    this.onSignRemindInputConfig();
    this.onSignRemindCtrl();
  }

  onSignRemindInputConfig(): void {
    const InputConfig = <DialogComponentModel>this.dialogConfig.data || null;

    if (InputConfig) {
      this.SignRemindPopup = InputConfig.includeType === DialogIncludesTypes.PopupTemplate;
      this.SignRemindForm.controls.email.patchValue(InputConfig.includeData || '')
    }
  }

  onToggleViewErrors(show: boolean): void {
    const SignRemindErrors = $('.__sign-remind__errors');

    show ? SignRemindErrors.slideDown(150) : SignRemindErrors.slideUp(150);
  }

  onSignRemindCtrl(): void {
    for (const key of Object.keys(this.SignRemindErrorsField)) {
      this.SignRemindForm.valueChanges.pipe(untilDestroyed(this)).subscribe(
        value => {
          this.SignRemindErrorsField[key] = false;
          this.SignRemindErrorsText = '';
          this.onToggleViewErrors(false);
        }
      );
    }
  }

  onCheckInput(key: string): void {
    if (this.SignRemindForm.controls.hasOwnProperty(key) && this.SignRemindForm.controls[key].value !== '') {
      this.SignRemindErrorsField[key] = !this.SignRemindForm.controls[key].valid;
    }
  }

  onSignRemindLoading(status: boolean, delay: number = 0) {
    setTimeout(() => {
      this.SignRemindLoading = status;
      this.cdr.detectChanges();
    }, delay);
  }
  
  onSubmitForm(): void {
    /**
     * Loading.
     */
    if (this.SignRemindLoading || this.SignRemindSuccess) {
      return;
    }

    /**
     * Initial validation.
     */
    if (!this.SignRemindForm.valid) {
      const Controls = this.SignRemindForm.controls;

      for (const control of Object.keys(Controls)) {
        if (!Controls[control].valid) {
          if (control === 'email') {
            this.SignRemindErrorsField.email = true;
            this.SignRemindErrorsText = 'Неправильно введен E-mail!';
            this.onToggleViewErrors(true);
            break;
          }
        }
      }

      return;
    }

    /**
     * SignRemind request API.
     */
    this.onSignRemindLoading(true);

    this.accountService.forgotPassword(this.SignRemindForm.getRawValue()).subscribe(
      response => {
        this.SignRemindSuccess = true;

        /**
         * Hide loading spinner.
         */
        this.onSignRemindLoading(false);


        this.cdr.markForCheck();
      },
      error => this.onSignRemindBaseErrorAPI(error)
    )
  }

  onSignRemindBaseErrorAPI(message: string): void {
    this.SignRemindErrorsText = message;

    for (const i of Object.keys(this.SignRemindErrorsField)) {
      this.SignRemindErrorsField[i] = true;
    }
    this.onToggleViewErrors(true);

    /**
     * Hide loading spinner.
     */
    this.onSignRemindLoading(false);
  }
}
