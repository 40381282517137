import { Routes } from '@angular/router';
import { RoutingConfig } from '@app/routing/routing.config';
import {
  BulletinsSeoParams,
  ChangePasswordSeoParams,
  ConfirmEmailSeoParams,
  DiscountSeoParams,
  FavoritesSeoParams,
  NotificationSeoParams,
  PointsSeoParams,
  RecoveryPassSeoParams,
  ProfileSeoParams,
  SettingsSeoParams
} from '@app/modules/account-modules/account.seo';

/**
 * Components
 */
import { AccountShellComponent } from './account-shell/account-shell.component';
import { BulletinsComponent } from './bulletins/bulletins.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { DiscountComponent } from './discount/discount.component';
import { FavoritesComponent } from './favorites/favorites.component';
import { NotificationComponent } from './notification/notification.component';
import { PointsComponent } from './points/points.component';
import { RecoveryPasswordComponent } from './recovery-password/recovery-password.component';
import { ProfileComponent } from './profile/profile.component';
import { SettingsComponent } from './settings/settings.component';

/**
 * Services
 */
import { GuardAuthService } from '@app/core/services';

export const AccountRoutes: Routes = [
  {
    path: '',
    component: AccountShellComponent,
    children: [
      {
        path: RoutingConfig.ProfileBulletins,
        component: BulletinsComponent,
        canActivate: [
          GuardAuthService
        ],
        data: {
          SEO: BulletinsSeoParams
        }
      },
      {
        path: RoutingConfig.ProfileChangePass,
        component: ChangePasswordComponent,
        data: {
          SEO: ChangePasswordSeoParams
        },
        canActivate: [
          GuardAuthService
        ]
      },
      {
        path: RoutingConfig.ProfileConfirmEmail,
        component: ConfirmEmailComponent,
        data: {
          SEO: ConfirmEmailSeoParams
        },
        canActivate: [
          GuardAuthService
        ]
      },
      {
        path: RoutingConfig.ProfileDiscount,
        component: DiscountComponent,
        data: {
          SEO: DiscountSeoParams
        },
        canActivate: [
          GuardAuthService
        ]
      },
      {
        path: RoutingConfig.ProfileFavorites,
        component: FavoritesComponent,
        data: {
          SEO: FavoritesSeoParams
        },
        canActivate: [
          GuardAuthService
        ]
      },
      {
        path: RoutingConfig.ProfileNotify,
        component: NotificationComponent,
        data: {
          SEO: NotificationSeoParams
        },
        canActivate: [
          GuardAuthService
        ]
      },
      {
        path: RoutingConfig.ProfilePoints,
        component: PointsComponent,
        data: {
          SEO: PointsSeoParams
        },
        canActivate: [
          GuardAuthService
        ]
      },
      {
        path: RoutingConfig.ProfileRecoveryPass,
        component: RecoveryPasswordComponent,
        data: {
          SEO: RecoveryPassSeoParams
        }
      },
      {
        path: RoutingConfig.ProfileAccount,
        component: ProfileComponent,
        data: {
          SEO: ProfileSeoParams
        },
        canActivate: [
          GuardAuthService
        ]
      },
      {
        path: RoutingConfig.ProfileSettings,
        component: SettingsComponent,
        data: {
          SEO: SettingsSeoParams
        },
        canActivate: [
          GuardAuthService
        ]
      },
      {
        path: '**',
        redirectTo: RoutingConfig.ProfileAccount,
        pathMatch: 'full'
      }
    ]
  }
];
