import { HttpParams } from '@angular/common/http';

export class HttpRequestToken extends HttpParams {
  constructor(public skipToken: boolean) {
    super();
  }
}

export class HttpRequestFile extends HttpParams {
  constructor(public skipContentType: boolean) {
    super();
  }
}
