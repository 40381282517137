import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { DevicesClass } from '@app/core/classes';
import { BreadcrumbsDataModel } from '@app/core/models/breadcrumbs';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RoutingConfig } from '@app/routing/routing.config';
import { AccountEventBus, AccountStoryService } from '@app/modules/account-modules/account.story';
import { IAccountUserInfo } from '@app/modules/account-modules/account.model';


@UntilDestroy()
@Component({
  selector: 'app-account-shell',
  templateUrl: 'account-shell.component.html',
  styleUrls: [`./account-shell.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})

export class AccountShellComponent extends DevicesClass implements OnInit {
  public AccountHeading: string = 'Личный кабинет';
  public BreadcrumbsData: BreadcrumbsDataModel = [
    {
      title: 'Личный кабинет',
      url: '/' + RoutingConfig.Profile
    },
    {
      title: 'Профиль пользователя',
      url: '/' + RoutingConfig.ProfileAccount,
      active: true
    }
  ];

  /**
   * Base component variables.
   */
  public ASUser: IAccountUserInfo = null;

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    public route: ActivatedRoute,
    private router: Router,
    private accountStory: AccountStoryService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.onAccountStoryBus();
    this.onSetAccountConfig();

    this.router.events.pipe(untilDestroyed(this)).subscribe((e: RouterEvent) => {
      if (e instanceof NavigationEnd) {
        this.onSetAccountConfig();
      }
    });
  }

  onAccountStoryBus(): void {
    this.accountStory.AccountEventBus$.pipe(untilDestroyed(this)).subscribe(
      emit => this.onAccountStoryListener(emit)
    );
  }

  onAccountStoryListener(emit: AccountEventBus): void {
    if (emit.event === 'onAccountUpdate') {
      this.ASUser = emit.data;
      this.cdr.markForCheck();
    }

    if (emit.event === 'onAccountLogout') {
      this.router.navigateByUrl(
        `/${RoutingConfig.Login}/${RoutingConfig.SignIn}?redirectUrl=${location.pathname}`
      ).catch();
    }
  }

  onSetAccountConfig(): void {
    if (this.onCheckURL(RoutingConfig.ProfileAccount)) {
      this.onSetBreadcrumbs('Профиль пользователя', RoutingConfig.ProfileAccount)
    }

    if (this.onCheckURL(RoutingConfig.ProfileChangePass)) {
      this.onSetBreadcrumbs('Изменение пароля', RoutingConfig.ProfileChangePass)
    }

    if (this.onCheckURL(RoutingConfig.ProfileRecoveryPass)) {
      this.onSetBreadcrumbs('Восстановление пароля', RoutingConfig.ProfileRecoveryPass)
    }

    if (this.onCheckURL(RoutingConfig.ProfileNotify)) {
      this.onSetBreadcrumbs('Уведомления', RoutingConfig.ProfileNotify)
    }

    if (this.onCheckURL(RoutingConfig.ProfileFavorites)) {
      this.onSetBreadcrumbs('Избранное', RoutingConfig.ProfileFavorites)
    }

    if (this.onCheckURL(RoutingConfig.ProfileBulletins)) {
      this.onSetBreadcrumbs('Мои объявления', RoutingConfig.ProfileBulletins)
    }

    if (this.onCheckURL(RoutingConfig.ProfileSettings)) {
      this.onSetBreadcrumbs('Настройки', RoutingConfig.ProfileSettings)
    }
  }

  onSetBreadcrumbs(title: string, url: string): void {
    this.AccountHeading = title;
    this.BreadcrumbsData = [
      {
        title: 'Личный кабинет',
        url: '/' + RoutingConfig.Profile
      },
      {
        title: title,
        url: '/' + url,
        active: true
      }
    ];

    this.cdr.markForCheck();
  }

  onCheckURL(url: string): boolean {
    return location.pathname.split('/').some(route => route === url);
  }
}
