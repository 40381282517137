import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'formatDate'
})

export class DatePipe implements PipeTransform {
  constructor () {
  }

  transform(date: any): string {
    if (new Date(date)) {
      return `${this.getDate(new Date(date))}, ${this.getTime(new Date(date))}`;
    }
    return date;
  }

  /**
   * Getting of bulletin date in DD:MM:YY format.
   * @param date -- date of bulletin
   */
  private getDate(date: Date): string {
    return `${date.getDate()} ${this.getMonthRUGenitive(date)} ${date.getFullYear()}`;
  }

  /**
   * Getting of bulletin time in HH:MM format.
   * @param date -- date of bulletin
   */
  private getTime(date: Date): string {
    moment.locale('ru');

    return moment(date).format('LT');
  }

  /**
   * Getting of bulletin date in default month format.
   * @param date -- date of bulletin
   */
  private getMonthRU(date: Date): string {
    switch (date.getMonth()) {
      case (0):
        return 'январь';
      case (1):
        return 'февраль';
      case (2):
        return 'март';
      case (3):
        return 'апрель';
      case (4):
        return 'май';
      case (5):
        return 'июнь';
      case (6):
        return 'июль';
      case (7):
        return 'август';
      case (8):
        return 'сентябрь';
      case (9):
        return 'октябрь';
      case (10):
        return 'ноябрь';
      case (11):
        return 'декабрь';
    }
  }

  /**
   * Getting of bulletin date in genitive month format.
   * @param date -- date of bulletin
   */
  private getMonthRUGenitive(date: Date): string {
    switch (date.getMonth()) {
      case (0):
        return 'января';
      case (1):
        return 'февраля';
      case (2):
        return 'марта';
      case (3):
        return 'апреля';
      case (4):
        return 'мая';
      case (5):
        return 'июня';
      case (6):
        return 'июля';
      case (7):
        return 'августа';
      case (8):
        return 'сентября';
      case (9):
        return 'октября';
      case (10):
        return 'ноября';
      case (11):
        return 'декабря';
    }
  }
}
