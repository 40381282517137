<div class="account-shell__content">
  <div class="row animated fadeIn faster delay-0_3s">
    <div class="grid align-items-center justify-content-center">
      <div class="ui-tabs">
        <div class="ui-tabs__link" (click)="onSetTabValue(TVTypes.Active)"
          ngClass="{{BTabsValue === TVTypes.Active ? 'active' : ''}}">
          <p>Активные</p>
        </div>

        <div class="ui-tabs__link" (click)="onSetTabValue(TVTypes.Expired)"
          ngClass="{{BTabsValue === TVTypes.Expired ? 'active' : ''}}">
          <p>Завершенные</p>
        </div>

        <div class="ui-tabs__link" (click)="onSetTabValue(TVTypes.Blocked)"
          ngClass="{{BTabsValue === TVTypes.Blocked ? 'active' : ''}}">
          <p>Заблокированные</p>
        </div>
      </div>
    </div>
  </div>

  <div class="row animated fadeIn faster" *ngIf="BLoading">
    <div class="pg-wrapper">
      <div class="account-shell__content-wrapper">
        <shared-skeleton-item [SkeletonView]="SVTypes.Profile"></shared-skeleton-item>
      </div>
    </div>
  </div>

  <div class="row animated fadeIn faster" *ngIf="!BLoading">
    <div class="row" *ngIf="BBulletinsResults.length">
      <div class="account-shell__events">
        <div class="pg-wrapper">
          <div class="account-shell__content-wrapper">
            <div class="grid align-items-center"
              ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

              <div class="col-4 col-md-3 col-sm-12"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4 order-0' : ''}}">
                <div class="ui-checkbox">
                  <input id="selectAll" type="checkbox"
                    [checked]="BBulletinsSelectedAll"
                    (change)="onSetSelectedAllBulletins($event)">
                  <label for="selectAll">
                    <span class="ui-checkbox__text">Выбрать все объявления</span>
                  </label>
                </div>
              </div>

              <div class="col-3 col-sm-8 margin-left-auto margin-right-auto"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4 order-2' : ''}}">
                <button class="ui-buttons ui-buttons__light-large" (click)="onBulletinCreate()">
                  Подать объявление
                </button>
              </div>

              <div class="col-4 col-md-5 col-sm-12 margin-left-auto"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'order-1' : ''}}">

                <div class="grid align-items-center">
                  <div class="col-auto col-sm-7 margin-left-auto"
                    ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : ''}}">

                    <div class="account-shell__events-icons remove-selected" (click)="onDeleteSelectedBulletins()"
                      ngClass="{{this.BBulletinsSelected.length ? '' : 'disabled'}} {{(deviceValue | async) === DTypes.Mobile ? 'pl-0' : ''}}">
                      <span class="mr-icon mr-icon-trash"></span>
                      <span class="mr-text">Удалить выбранные</span>
                    </div>
                  </div>

                  <div class="col-auto col-sm-5 pl-2"
                    ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4 pl-0' : ''}}">

                    <div class="account-shell__events-icons remove-all" (click)="onDeleteAllBulletins()"
                      ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'pl-0' : ''}}">
                      <span class="mr-icon mr-icon-trash"></span>
                      <span class="mr-text">Удалить все</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="pg-wrapper">
        <div class="account-shell__content-wrapper">
          <div class="bulletins__result" *ngIf="!BBulletinsResults.length">
            <div class="bulletins__result-empty">
              <div class="grid align-items-center justify-content-center flex-column">
                <div class="mr-icon mr-icon-other"></div>
                <h5 class="mt-6">У вас пока нет {{emptyCupDescription}} объявлений...</h5>
              </div>
            </div>
            <div class="row mt-2" *ngIf="BTabsValue === TVTypes.Active">
              <div class="grid align-items-center justify-content-center">
                <div class="col-3 col-sm-6 text-center">
                  <button class="ui-buttons ui-buttons__light-large" (click)="onBulletinCreate()">
                    Подать объявление
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="bulletins__result" *ngIf="BBulletinsResults.length">
            <ng-container *ngFor="let result of BBulletinsResults;">
              <div class="grid align-items-center justify-content-center"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

                <div class="bulletins__result-checkbox"
                  ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'order-1 mt-2 mb-4' : ''}}">

                  <div class="ui-checkbox">
                    <input id="{{result.id}}" type="checkbox" [checked]="result.selected"
                      (change)="onSetSelectedBulletin(result)">

                    <label for="{{result.id}}">
                      <span class="ui-checkbox__text" *ngIf="(deviceValue | async) === DTypes.Mobile">Выбрать</span>
                    </label>
                  </div>
                </div>

                <div class="bulletins__result-content"
                  ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'order-0' : ''}}">

                  <shared-bulletin-item
                    [BulletinItem]="result"
                    [BulletinView]="(deviceValue | async) === DTypes.Mobile ? BIVTypes.ProfileBlock : BIVTypes.ProfileList">
                  </shared-bulletin-item>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<p-confirmDialog #default [key]="'default'" [appendTo]="'body'" [styleClass]="'p-dialog__confirm'">
  <p-footer>
    <button class="ui-buttons ui-buttons__light-small" (click)="default.reject()">Отменить</button>
    <button class="ui-buttons ui-buttons__blue-small" (click)="default.accept()">Удалить</button>
  </p-footer>
</p-confirmDialog>

<p-confirmDialog #publication [key]="'publication'" [appendTo]="'body'" [styleClass]="'p-dialog__confirm'">
  <p-footer>
    <button class="ui-buttons ui-buttons__light-small" (click)="publication.reject()">Отменить</button>
    <button class="ui-buttons ui-buttons__blue-small" (click)="publication.accept()">Снять с публикации</button>
  </p-footer>
</p-confirmDialog>

<p-confirmDialog #activate [key]="'activate'" [appendTo]="'body'" [styleClass]="'p-dialog__confirm'">
  <p-footer>
    <button class="ui-buttons ui-buttons__light-small" (click)="activate.reject()">Отменить</button>
    <button class="ui-buttons ui-buttons__blue-small" (click)="activate.accept()">Активировать</button>
  </p-footer>
</p-confirmDialog>
