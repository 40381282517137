import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { DevicesClass } from '@app/core/classes';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RoutingConfig } from '@app/routing/routing.config';
import { DialogComponentModel, DialogIncludesTypes } from '@app/core/models/diallog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@UntilDestroy()
@Component({
  selector: 'app-notification-dialog',
  templateUrl: 'notification-dialog.component.html',
  styleUrls: [`./notification-dialog.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})

export class NotificationDialogComponent extends DevicesClass implements OnInit {
  public Routing = RoutingConfig;
  public DialogTypes = DialogIncludesTypes;

  /**
   * Base component variables.
   */
  public NDTypes: DialogIncludesTypes;
  public NDContent: string = null;
  public NDIcons: string = null;

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    public ref: DynamicDialogRef,
    private router: Router,
    private dialogConfig: DynamicDialogConfig,
    private sanitizer: DomSanitizer,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.onNotificationDialogInputConfig();
  }

  onNotificationDialogInputConfig(): void {
    const InputConfig = <DialogComponentModel>this.dialogConfig.data || null;

    this.NDTypes = InputConfig.includeType || DialogIncludesTypes.PopupTemplate;
    this.NDContent = InputConfig.includeData.contentText || '';
    this.NDIcons = InputConfig.includeData.contentIcons || '';
  }

  onCloseDialog(): void {
    this.ref.close()
  }

  onTextSanitize(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html)
  }

  get onDialogTemplateType(): string {
    if (this.NDTypes === this.DialogTypes.PopupTemplate) {
      return 'default';
    }

    if (this.NDTypes === this.DialogTypes.PopupSuccessTemplate) {
      return 'success';
    }

    if (this.NDTypes === this.DialogTypes.PopupErrorTemplate) {
      return 'error';
    }

    return 'default'
  }
}
