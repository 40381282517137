import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FavoritesService } from '@app/modules/favorites-modules/favorites.service';

@NgModule({
  declarations: [],
  exports: [],
  imports: [
    CommonModule
  ],
  providers: [
    FavoritesService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})

export class FavoritesModule {}
