import { AppApiURL } from '@app/app.settings';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { HttpRequestToken } from '@app/core/classes';
import { Observable, throwError } from 'rxjs';
import { catchError, debounceTime, map, timeout } from 'rxjs/operators';
import { IBlogPostNewsDetail, IBlogPostNewsLists } from './news.models';

@Injectable()

export class NewsService {
  constructor(
    private http: HttpClient
  ) {}

  /**
   * Api: v01.
   * Method: BlogPost getting of all news
   * @param size
   * @param page
   * @param debounce
   */
  public getAllNews(size?: number, page?: number, debounce?: number): Observable<IBlogPostNewsLists> {
    return this.http.get<IBlogPostNewsLists>(AppApiURL + `/blog/?discriminator=News&page=${page ? page : 1}&size=${size ? size : 20}`, {
      params: new HttpRequestToken(true)
    }).pipe(
      debounceTime(debounce ? debounce : 0)
    );
  }

  /**
   * Api: v01.
   * Method: BlogPost getting of news by ID
   * @param id
   */
  public getNewsById(id: number): Observable<IBlogPostNewsDetail> {
    return this.http.get<any>(AppApiURL + `/blog/${id}`)
      .pipe(
        timeout(10000)
      );
  }


  /**
   * Api: v01.
   * Method: Like set like of news.
   * @param id
   */
  public likeNews(id: number): Observable<any> {
    return this.http.post<any>(AppApiURL + `/likes/${id}/like`, null)
      .pipe(
        map((response: HttpResponse<any>) => response),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }


  /**
   * Api: v01.
   * Method: Like set like of news.
   * @param id
   */
  public dislikeNews(id: number): Observable<any> {
    return this.http.post<any>(AppApiURL + `/likes/${id}/dislike`, null)
      .pipe(
        map((response: HttpResponse<any>) => response),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }
}
