/**
 * Util for checking of device.
 * Will return @true for mobile device.
 */
export const _deviceMobile = (): boolean => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

/**
 * Util for getting of device type.
 * By default will return of desktop type.
 *
 * @types {
 *  m -- mobile
 *  t -- tablet
 *  d -- desktop
 * }
 */
export const _deviceType = (): number => {
  if (window.innerWidth < 768) {
    return 0;
  }
  if (window.innerWidth >= 768 && _deviceMobile()) {
    return 1;
  }
  if (window.innerWidth >= 768 && !_deviceMobile()) {
    return 2;
  }

  return 2;
};
