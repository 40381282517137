<div class="account-shell__loading animated faster fadeIn" *ngIf="SettingsLoading">
  <div class="ui-loader ui-loader__page-light"></div>
</div>

<div class="account-shell__content"
  ngClass="{{SettingsLoading ? 'loading' : ''}}">


  <div class="pg-wrapper">
    <div class="account-shell__content-wrapper"
      ngClass="{{(deviceValue | async) === DTypes.Mobile ? '' : 'pl-3 pr-3'}}">

      <div class="account-shell__content-subtitle">
        <h5>Уведомления в мессенджерах</h5>
        <p class="txt-caption__large">Вы можете получать новые уведомления, разрешив нашему Боту отправлять Вам сообщения в выбранных мессенджерах</p>
      </div>

      <div class="account-shell__content-wrapper pb-12">
        <div class="grid align-items-center justify-content-center"
          ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

          <div class="col-3 col-md-5 col-sm-12">
            <div class="settings__item no-border">
              <div class="settings__item-icon">
                <span class="mr-icon mr-icon-telegram-color">
                  <span class="path1"></span>
                  <span class="path2"></span>
                </span>
              </div>
              <div class="settings__item-button">
                <button class="ui-buttons ui-buttons__blue-small" *ngIf="!TelegramNotifyEnabled"
                  (click)="onMessengerGetDeepLinkIdentifier(MType.telegram)">
                  Получать в Telegram
                </button>
                <button class="ui-buttons ui-buttons__light-small" *ngIf="TelegramNotifyEnabled"
                  (click)="onMessengerUnsubscribe(MType.telegram)">
                  Отключить
                </button>
              </div>
            </div>
          </div>

          <div class="col-3 col-md-5 col-sm-12">
            <div class="settings__item no-border">
              <div class="settings__item-icon">
                <span class="mr-icon mr-icon-vk-color">
                  <span class="path1"></span>
                  <span class="path2"></span>
                </span>
              </div>
              <div class="settings__item-button">
                <button class="ui-buttons ui-buttons__blue-small" *ngIf="!VKNotifyEnabled"
                  (click)="onMessengerGetDeepLinkIdentifier(MType.vk)">
                  Получать в VK
                </button>
                <button class="ui-buttons ui-buttons__light-small" *ngIf="VKNotifyEnabled"
                  (click)="onMessengerUnsubscribe(MType.vk)">
                  Отключить
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="account-shell__content-subtitle">
        <h5>Стандартные уведомления</h5>
      </div>

      <div class="account-shell__content-wrapper">
        <form [formGroup]="SettingsForm" autocomplete="off">

          <div class="grid align-items-center justify-content-center"
            ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

            <div class="col-4 col-sm-12">
              <div class="settings__item">
                <div class="settings__item-icon">
                  <span class="mr-icon mr-icon-settings-search"></span>
                </div>
                <div class="settings__item-title">
                  <h5>Поиск музыкантов</h5>
                </div>
                <div class="settings__item-text">
                  <p>Подборки подходящих объвлений под ваш запрос</p>
                </div>
                <div class="settings__item-switcher">
                  <label class="ui-switcher settings-switcher"
                    ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'sm-view' : ''}}">

                    <input type="checkbox" formControlName="musicianSearch"/>
                    <span class="ui-switcher__item"></span>
                  </label>
                </div>
              </div>
            </div>

            <div class="col-4 col-sm-12">
              <div class="settings__item">
                <div class="settings__item-icon">
                  <span class="mr-icon mr-icon-settings-mail"></span>
                </div>
                <div class="settings__item-title">
                  <h5>E-mail - уведомления</h5>
                </div>
                <div class="settings__item-text">
                  <p>Самые актуальные предложения только для Вас</p>
                </div>
                <div class="settings__item-switcher">
                  <label class="ui-switcher settings-switcher"
                    ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'sm-view' : ''}}">

                    <input type="checkbox" formControlName="email"/>
                    <span class="ui-switcher__item"></span>
                  </label>
                </div>
              </div>
            </div>

            <div class="col-4 col-sm-12">
              <div class="settings__item no-border">
                <div class="settings__item-icon">
                  <span class="mr-icon mr-icon-settings-news"></span>
                </div>
                <div class="settings__item-title">
                  <h5>Новостная лента</h5>
                </div>
                <div class="settings__item-text">
                  <p>Уведомления о новых статьях в ленте</p>
                </div>
                <div class="settings__item-switcher">
                  <label class="ui-switcher settings-switcher"
                    ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'sm-view' : ''}}">

                    <input type="checkbox" formControlName="news"/>
                    <span class="ui-switcher__item"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>


      <div class="account-shell__content-wrapper">
        <div class="account-shell__content-button">
          <div class="grid align-items-center justify-content-center">
            <div class="col-3 col-sm-6">
              <button class="ui-buttons ui-buttons__blue-large" (click)="onSaveSettings()"
                ngClass="{{SettingsFormModify ? '' : 'disabled'}}">
                Сохранить
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>
