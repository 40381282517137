import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FundamentalsModule } from '@app/modules/fundamentals-modules/fundamentals.module'

/**
 * Components
 */
import { SearchCityComponent } from './search-city/search-city.component';

/**
 * Services
 */
import { CitiesService } from './cities.service';

/**
 * Vendors
 */
import { TooltipModule } from 'primeng/tooltip';

/**
 * Directives
 */
import { ValidationModule } from '@app/core/directives';

@NgModule({
  declarations: [
    SearchCityComponent
  ],
  exports: [
    SearchCityComponent
  ],
  entryComponents: [
    SearchCityComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FundamentalsModule,
    TooltipModule,
    ValidationModule
  ],
  providers: [
    CitiesService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})

export class CitiesModule {}
