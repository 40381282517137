/**
 * Validate of Phone number.
 * @string
 */
export const PhoneNumberValidate = (phone: string): boolean => {
  if (!phone || phone === '') {
    return true;
  }
  return (
    phone.split('')[0] === '7' && phone.split('').length === 11
  );
};

/**
 * Validate of Email.
 * @string
 */
export const EmailStringValidate = (): string => {
  return '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$';
};

/**
 * Validate of file types.
 * @param file
 * @boolean
 */
export const FileIsImageValidate = (file: File): boolean => {
  const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

  return file && acceptedImageTypes.includes(file['type']);
};


/**
 * Validate of VK link.
 * @param link
 * @boolean
 */
export const VKLinkValidate = (link: string): boolean => {
  const regex = /(https{0,1}:\/\/)?(www\.)?(vk.com\/)(id\d|[a-zA-Z0-9_.])+$/;

  return regex.test(link);
};


/**
 * Validate of Facebook link.
 * @param link
 * @boolean
 */
export const FBLinkValidate = (link: string): boolean => {
  const regex = /(?:(?:http|https):\/\/)?(?:www.)?facebook.com|fb.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?/gm;

  let error = false;
  let id = null;

  while ((id = regex.exec(link)) !== null) {
    if (id.index === regex.lastIndex) {
      regex.lastIndex++;
    }
    error = true;
  }

  return error;
};


/**
 * Validate of Instagram link.
 * @param link
 * @boolean
 */
export const INSTLinkValidate = (link: string): boolean => {
  const regex = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/;

  return regex.test(link);
};


/**
 * Validate of SoundCloud link.
 * @param link
 * @boolean
 */
export const SoundCloudLinkValidate = (link: string): boolean => {
  const regex = /^https?:\/\/(soundcloud\.com|snd\.sc)\/(.*)$/;

  return !!link.match(regex) && !!link.match(regex)[2];
};


/**
 * Validate of Instagram link.
 * @param link
 * @boolean
 */
export const URLLinkValidate = (link: string): boolean => {
  const regex = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/igm;

  return regex.test(link);
};


/**
 * Validate of Youtube link.
 * @param link
 * @boolean
 */
export const YTLinkValidate = (link: string): boolean => {
  const regex = '^(http(s)?:\\/\\/)?((w){3}.)?youtu(be|.be)?(\\.com)?\\/.+';
  let error = true;

  if (link.search('^(http(s)?:\\/\\/)?((w){3}.)?youtu(be|.be)?(\\.com)?\\/.+') !== 0) {
    error = false;
  }
  return error;
};
