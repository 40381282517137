import { AppApiURL } from '@app/app.settings';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { IListNotification } from '@app/modules/notification-modules/notification.model';
import { DeviceTypes } from '@app/core/classes';
import { DialogInputData, DialogComponentModel } from '@app/core/models/diallog';
import { DialogService } from 'primeng/dynamicdialog';
import { TokenService } from '@app/core/services';
import { NotificationStoryService } from '@app/modules/notification-modules/notification.story';
import { Observable, throwError } from 'rxjs';
import { timeout, map, catchError } from 'rxjs/operators';
import { _deviceType } from '@app/utils/device';

/**
 * Components
 */
import { NotificationDialogComponent } from './notification-dialog/notification-dialog.component';


@Injectable()

export class NotificationService {
  constructor(
    private http: HttpClient,
    private dialogService: DialogService,
    private tokenService: TokenService,
    private notificationStory: NotificationStoryService,
  ) {}

  /**
   * Method: Open popup with Auth Remind Password.
   * @param options
   */
  public onDialog(options: DialogInputData): void {
    this.dialogService.open(NotificationDialogComponent, {
      header: options.header,
      width: _deviceType() === DeviceTypes.Mobile ? '90%' : '100%',
      styleClass: 'p-dialog__notification',
      data: <DialogComponentModel>{
        includeType: options.type,
        includeData: {
          contentText: options.content,
          contentIcons: options.icons
        }
      }
    });
  }


  /**
   * Api: v01.
   * Method: Get of all notification
   */
  public getNotification(): Observable<IListNotification> {
    return this.http.get<IListNotification>(AppApiURL + `/notifications`,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false
      }
    ).pipe(
      map((response: HttpResponse<any>) => response.body),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method: Get of unreaded notification
   */
  public unreadedCount(): Observable<number> {
    return this.http.get<number>(AppApiURL + `/notifications/unreadedCount`, {
        withCredentials: true,
        observe: 'response',
        reportProgress: false
      }
    ).pipe(
      map((response: HttpResponse<any>) => response.body),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Get of initial notification.
   */
  public onIniUnreadedCount(): void {
    if (!this.tokenService.accessToken) {
      return this.notificationStory.NotificationEventBus.next({
        event: 'onUpdateCount',
        data: 0
      })
    }
    this.unreadedCount().subscribe(
      response => {
        this.notificationStory.NotificationEventBus.next({
          event: 'onUpdateCount',
          data: response
        })
      },
      error => {
        this.notificationStory.NotificationEventBus.next({
          event: 'onUpdateCount',
          data: 0
        })
      }
    )
  }

  /**
   * Api: v01.
   * Method: markAsReaded of notification by Id
   * @id
   */
  public markAsReadedNotification(id: number): Observable<any> {
    return this.http.put<any>(AppApiURL + `/notifications/${id}/markAsReaded`,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false
      }
    ).pipe(
      map((response: HttpResponse<any>) => response),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method: Delete of notification by Id
   * @id
   */
  public deleteNotification(id: number): Observable<any> {
    return this.http.delete<any>(AppApiURL + `/notifications/${id}`,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false
      }
    ).pipe(
      map((response: HttpResponse<any>) => response.body),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }
}
