import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SkeletonItemComponent } from './skeleton-item.component';
import { SkeletonModule } from 'primeng/skeleton';

@NgModule({
  declarations: [
    SkeletonItemComponent
  ],
  exports: [
    SkeletonItemComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SkeletonModule
  ]
})

export class SkeletonItemModule {
  constructor() {}
}
