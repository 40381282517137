import { SeoPageModel } from '@app/core/services/seo/seo.model';

export const BulletinsSeoParams = <SeoPageModel>{
  title: 'Мои объявления',
  description: 'Сервис по поиску музыкантов No1 в России и на Луне',
  image: '',
  url: '',
};

export const ChangePasswordSeoParams = <SeoPageModel>{
  title: 'Изменение пароля',
  description: 'Сервис по поиску музыкантов No1 в России и на Луне',
  image: '',
  url: '',
};

export const ConfirmEmailSeoParams = <SeoPageModel>{
  title: 'Подтверждение почты',
  description: 'Сервис по поиску музыкантов No1 в России и на Луне',
  image: '',
  url: '',
};

export const DiscountSeoParams = <SeoPageModel>{
  title: 'Карта музыканта',
  description: 'Сервис по поиску музыкантов No1 в России и на Луне',
  image: '',
  url: '',
};

export const FavoritesSeoParams = <SeoPageModel>{
  title: 'Избранное',
  description: 'Сервис по поиску музыкантов No1 в России и на Луне',
  image: '',
  url: '',
};

export const NotificationSeoParams = <SeoPageModel>{
  title: 'Уведомления',
  description: 'Сервис по поиску музыкантов No1 в России и на Луне',
  image: '',
  url: '',
};

export const PointsSeoParams = <SeoPageModel>{
  title: 'Мои баллы',
  description: 'Сервис по поиску музыкантов No1 в России и на Луне',
  image: '',
  url: '',
};

export const RecoveryPassSeoParams = <SeoPageModel>{
  title: 'Восстановление пароля',
  description: 'Сервис по поиску музыкантов No1 в России и на Луне',
  image: '',
  url: '',
};

export const ProfileSeoParams = <SeoPageModel>{
  title: 'Профиль',
  description: 'Сервис по поиску музыкантов No1 в России и на Луне',
  image: '',
  url: '',
};

export const SettingsSeoParams = <SeoPageModel>{
  title: 'Настройки',
  description: 'Сервис по поиску музыкантов No1 в России и на Луне',
  image: '',
  url: '',
};
