import { AppApiURL, AppDomainURL } from '@app/app.settings';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { HttpRequestToken } from '@app/core/classes';
import { Observable, throwError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { AuthSignUpRequest, AuthSignUpResponse } from '@app/modules/auth-modules/auth.model'
import { IMusicianUserInfo, IMusicianUpdateMyInfo } from '@app/modules/musician-modules/musician.model';
import { RoutingConfig } from '@app/routing/routing.config';


@Injectable()

export class MusicianService {
  constructor(
    private http: HttpClient
  ) {}

  /**
   * Api: v01.
   * Method: Musician signUp
   */
  public signUp(request: AuthSignUpRequest): Observable<AuthSignUpResponse> {
    const returnPath = `?confirmEmailPath=${AppDomainURL}/${RoutingConfig.Profile}/${RoutingConfig.ProfileConfirmEmail}`;

    return this.http.post<AuthSignUpRequest>(AppApiURL + `/musician/signUp${returnPath}`,
      request,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false,
        params: new HttpRequestToken(true)
      }
    ).pipe(
      timeout(10000),
      map((response: HttpResponse<any>) => response.body),
      catchError((error: HttpErrorResponse) => {
        if (error.hasOwnProperty('error')) {
          return throwError(error.error);
        }
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method: Musician FinishRegistration
   */
  public finishRegistration(request: AuthSignUpRequest): Observable<AuthSignUpResponse> {
    const returnPath = `?confirmEmailPath=${AppDomainURL}/${RoutingConfig.Profile}/${RoutingConfig.ProfileConfirmEmail}`;

    return this.http.post<AuthSignUpRequest>(AppApiURL + `/musician/finishRegistration${returnPath}`,
      request,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false,
        params: new HttpRequestToken(true)
      }
    ).pipe(
      timeout(10000),
      map((response: HttpResponse<any>) => response.body),
      catchError((error: HttpErrorResponse) => {
        if (error.hasOwnProperty('error')) {
          return throwError(error.error);
        }
        return throwError(error);
      })
    );
  }


  /**
   * Api: v01.
   * Method: Musician musicianUserInfo
   */
  public musicianUserInfo(): Observable<IMusicianUserInfo> {
    return this.http.post<IMusicianUserInfo>(AppApiURL + '/musician/musicianUserInfo', null,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false
      }
    ).pipe(
      map((response: HttpResponse<any>) => response.body)
    )
  }


  /**
   * Api: v01.
   * Method: Musician updateMyInfo
   */
  public updateMyInfo(request: IMusicianUpdateMyInfo): Observable<IMusicianUserInfo> {
    return this.http.post<IMusicianUserInfo>(AppApiURL + '/musician/updateMyInfo', request,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false
      }
    ).pipe(
      map((response: HttpResponse<any>) => response.body)
    )
  }
}
