import { Directive, Input, HostListener, ElementRef } from '@angular/core';

const RegExpConfig: any = {
  textRU: /^[А-Яа-я0-9]+(?:[- ][А-Яа-я0-9]+)*$/,
  textEN: /^[A-Za-z0-9]+(?:[- ][A-Za-z0-9]+)*$/,
  textALL: /^[А-Яа-я0-9-A-Za-z0-9 ]+(?:[- ][А-Яа-я0-9-A-Za-z0-9 ]+)*$/
};

@Directive({
  selector: '[validateHandler]'
})

export class ValidationDirective {
  @Input() validateHandler: string;

  constructor(
    private el: ElementRef,
  ) {}

  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
    let value: string = this.el.nativeElement.value;
    let next: string = value.concat(event.key);

    if (
      event.key === '_' ||
      event.key === '-' ||
      event.key === 'Control' ||
      event.key === 'Alt' ||
      event.key === 'Meta' ||
      event.key === 'Backspace' ||
      event.key === 'Delete') {
      return;
    }

    if (!RegExpConfig.hasOwnProperty(this.validateHandler)) {
      return;
    }

    if (next && !(new RegExp(RegExpConfig[this.validateHandler]).test(next))) {
      event.preventDefault();
    }
  }
}
