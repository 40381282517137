import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export type NewsEventBusTypes = 'onAddToFavorites' | 'onRemoveFromFavorites' | 'onUpdateFavorites';
export interface NewsEventBus {
  event: NewsEventBusTypes;
  data?: any;
}

@Injectable({
  providedIn: 'root'
})

export class NewsStoryService {
  public NewsEventBus: Subject<NewsEventBus> = new Subject<NewsEventBus>();
  public NewsEventBus$ = this.NewsEventBus.asObservable();
}
