<div class="account-shell__loading animated faster fadeIn" *ngIf="RecoveryPassLoading">
  <div class="ui-loader ui-loader__page-light"></div>
</div>

<div class="account-shell__content"
  ngClass="{{RecoveryPassLoading ? 'loading' : ''}}">

  <div class="pg-wrapper">
    <div class="account-shell__content-wrapper"
      ngClass="{{(deviceValue | async) === DTypes.Mobile ? '' : 'pl-3 pr-3'}}">

      <form [formGroup]="RecoveryPassForm" autocomplete="off">

        <div class="account-shell__content-subtitle">
          <h5>Новый пароль</h5>
          <div class="grid align-items-start pt-2">
            <p class="txt-require pr-1">*</p>
            <p class="txt-caption__large">— Поля отмеченные звездочкой обязательны для заполнения.</p>
          </div>
        </div>
        <div class="account-shell__content-wrapper">
          <div class="grid align-items-center justify-content-center flex-column">
            <div class="col-5 col-sm-12">
              <div class="account-shell__forms-input">
                <div class="ui-input__placeholder">
                  <p>Введите новый пароль:</p>
                  <span class="require">*</span>
                </div>
                <div class="ui-input ui-input__text"
                  ngClass="{{RecoveryPassErrorsField.newPassword ? 'error' : ''}}">
                  <input type="password" placeholder="Пароль" formControlName="newPassword" #newPasswordInput>

                  <div class="ui-input__view-password" (click)="onToggleDisplayPassword('new')"
                    ngClass="{{isVisiblePassword('new') ? 'view' : ''}}">
                    <span class="mdi"></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-5 col-sm-12 mt-4 mb-4">
              <div class="account-shell__forms-input">
                <div class="ui-input__placeholder">
                  <p>Повторите новый пароль:</p>
                  <span class="require">*</span>
                </div>
                <div class="ui-input ui-input__text"
                  ngClass="{{RecoveryPassErrorsField.newPasswordConfirm ? 'error' : ''}}">
                  <input type="password" placeholder="Пароль" formControlName="newPasswordConfirm" #repeatPasswordInput>

                  <div class="ui-input__view-password" (click)="onToggleDisplayPassword('repeat')"
                    ngClass="{{isVisiblePassword('repeat') ? 'view' : ''}}">
                    <span class="mdi"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="account-shell__content-wrapper">
          <div class="account-shell__content-errors __recovery-password__errors">
            <div class="grid justify-content-center"
              ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'align-items-center text-left' : 'align-items-baseline'}}">

              <span class="mr-icon mr-icon-status-error"></span>
              <p>{{RecoveryPassErrorsText}}</p>
            </div>
          </div>

          <div class="account-shell__content-button">
            <div class="grid align-items-center justify-content-center">
              <div class="col-3 col-sm-6">
                <button class="ui-buttons ui-buttons__blue-large" (click)="onRecoveryPassword()">
                  Сохранить
                </button>
              </div>
            </div>
          </div>
        </div>

      </form>

    </div>
  </div>

  <p-toast position="bottom-center" key="changePassToast"
    [baseZIndex]="5000"
    [styleClass]="'p-toast__profile'">
  </p-toast>
</div>
