import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export type BulletinsEventBusTypes =
  'onAddToFavorites'      |
  'onRemoveFromFavorites' |
  'onUpdateFavorites'     |
  'onStopPublication'     |
  'onActivatePublication' |
  'onShowPhone';

export type BulletinsEventBusData = any;

export interface BulletinsEventBus {
  event: BulletinsEventBusTypes;
  data?: BulletinsEventBusData;
}

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})

export class BulletinsStoryService {
  public BulletinsEventBus: Subject<BulletinsEventBus> = new Subject<BulletinsEventBus>();
  public BulletinsEventBus$ = this.BulletinsEventBus.asObservable();

  constructor() {
  }
}
