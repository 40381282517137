import { Injectable } from '@angular/core';
import { StorageService } from '@app/core/services/storage/storage.service';
import { StorageToken } from '@app/core/constants';

@Injectable()

export class TokenService {
  constructor(
    private storage: StorageService
  ) {}

  /**
   * Setter for Access Token.
   * @param value
   * @param remember
   */
  onAccessToken(value: string, remember: boolean = true) {
    this.storage.setStorage({
      key: StorageToken.accessToken,
      value: value
    }, remember ? 'local' : 'session');
  }

  /**
   * Getter for Access Token.
   */
  get accessToken(): string {
    return (
      this.storage.getStorage({ key: StorageToken.accessToken }, 'local') ||
      this.storage.getStorage({ key: StorageToken.accessToken }, 'session') ||
      null
    )
  }

  /**
   * Getter for Access Token.
   */
  get accessTokenRemember(): boolean {
    return !!this.storage.getStorage({ key: StorageToken.accessToken }, 'local')
  }

  /**
   * Clear Access Token.
   */
  onAccessTokenClear(): void {
    this.storage.removeStorage(
      {
        key: StorageToken.accessToken
      }
    )
  }



  /**
   * Setter for Refresh Token.
   * @param value
   * @param remember
   */
  onRefreshToken(value: string, remember: boolean = true) {
    this.storage.setStorage({
      key: StorageToken.refreshToken,
      value: value
    }, remember ? 'local' : 'session');
  }

  /**
   * Getter for Refresh Token.
   */
  get refreshToken(): string {
    return (
      this.storage.getStorage({ key: StorageToken.refreshToken }, 'local') ||
      this.storage.getStorage({ key: StorageToken.refreshToken }, 'session') ||
      null
    )
  }

  /**
   * Getter for Refresh Token.
   */
  get refreshTokenRemember(): boolean {
    return !!this.storage.getStorage({ key: StorageToken.refreshToken }, 'local')
  }

  /**
   * Clear Refresh Token.
   */
  onRefreshTokenClear(): void {
    this.storage.removeStorage(
      {
        key: StorageToken.refreshToken
      }
    )
  }


  /**
   * Setter for Expires.
   * @param value
   * @param remember
   */
  onExpires(value: string, remember: boolean = true) {
    this.storage.setStorage({
      key: StorageToken.expires,
      value: value
    }, remember ? 'local' : 'session');
  }

  /**
   * Getter for Expires.
   */
  get expires(): string {
    return (
      this.storage.getStorage({ key: StorageToken.expires }, 'local') ||
      this.storage.getStorage({ key: StorageToken.expires }, 'session') ||
      null
    )
  }

  /**
   * Getter for Expires.
   */
  get expiresRemember(): boolean {
    return !!this.storage.getStorage({ key: StorageToken.expires }, 'local')
  }

  /**
   * Clear expires Token.
   */
  onExpiresClear(): void {
    this.storage.removeStorage(
      {
        key: StorageToken.expires
      }
    );
  }
}
