import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { DevicesClass } from '@app/core/classes';
import { RoutingConfig } from '@app/routing/routing.config';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BreadcrumbsDataModel } from '@app/core/models/breadcrumbs';
import { EmailStringValidate } from '@app/utils/validator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@app/modules/auth-modules/auth.service';
import { AuthSignInRequest } from '@app/modules/auth-modules/auth.model';
import { OAuthService, OAuthTypes } from '@app/modules/auth-modules/oauth.service';
import { AuthStoryService, AuthEventBus } from '@app/modules/auth-modules/auth.story';
import { AccountStoryService } from '@app/modules/account-modules/account.story';
import { AccountService } from '@app/modules/account-modules/account.service';
import { ObjectToUrlParams } from '@app/utils/converter';
import { PrimeNGConfig } from 'primeng/api';
import * as $ from 'jquery';

export interface SignInErrorModels {
  email: boolean;
  password: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-sign-in',
  templateUrl: 'sign-in.component.html',
  styleUrls: [`./sign-in.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SignInComponent extends DevicesClass implements OnInit {
  public Routing = RoutingConfig;
  public OAuthTypes = OAuthTypes;
  public BreadcrumbsData: BreadcrumbsDataModel = [
    {
      title: 'Авторизация',
      url: `/${RoutingConfig.Login}/${RoutingConfig.SignIn}`,
      active: true
    }
  ];

  /**
   * Base component variables.
   */
  public SignInForm: FormGroup;
  public SignInErrorsField: SignInErrorModels;
  public SignInErrorsText: string;
  public SignInLoading: boolean;

  @ViewChild('passwordInput', { static: false }) passwordInput;

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    public oauthService: OAuthService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private accountService: AccountService,
    private accountStory: AccountStoryService,
    private authStory: AuthStoryService,
    private primengConfig: PrimeNGConfig,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;

    /**
     * Build SignIn form.
     */
    this.SignInForm = this.formBuilder.group({
      email: ['', Validators.compose([
        Validators.required, Validators.pattern(EmailStringValidate())
      ])],
      password: ['', Validators.required],
      remember: [true]
    });

    /**
     * Set of default errors value.
     */
    this.SignInErrorsField = {
      email: false,
      password: false
    };

    /**
     * Form changes control.
     */
    this.onSignInCtrl();

    /**
     * Auth Story Bus listener.
     */
    this.onAuthStoryBus();
  }

  onToggleViewErrors(show: boolean): void {
    const SignInErrors = $('.__sign-in__errors');
    show ? SignInErrors.slideDown(150) : SignInErrors.slideUp(150);
  }

  onToggleDisplayPassword(): void {
    const Password = this.passwordInput.nativeElement;
    Password.type === 'password' ? Password.type = 'text' : Password.type = 'password';
  }

  isVisiblePassword(): boolean {
    return this.passwordInput ? this.passwordInput.nativeElement.type === 'text' : false;
  }

  onAuthStoryBus(): void {
    this.authStory.AuthEventBus$.pipe(untilDestroyed(this)).subscribe(
      emit => this.onAuthStoryListener(emit)
    );
  }

  onAuthStoryListener(emit: AuthEventBus): void {
    if (emit.event === 'onOAuthSignIn') {
      this.authService.getToken(emit.data.token).subscribe(
        response => {
          this.authService.SignInExternal(response);

          /**
           * Getting of Account User model.
           */
          this.onSignInAccountUserAPI();
        },
        error => this.onSignInBaseErrorAPI('Не удалось выполнить вход через социальные сети.')
      );
    }

    if (emit.event === 'onOAuthSignUp') {
      this.router.navigateByUrl(
        `/${RoutingConfig.Login}/${RoutingConfig.SignUp}?${ObjectToUrlParams(emit.data)}`
      ).catch();
    }
  }

  onSignInCtrl(): void {
    for (const key of Object.keys(this.SignInErrorsField)) {
      this.SignInForm.valueChanges.pipe(untilDestroyed(this)).subscribe(
        value => {
          this.SignInErrorsField[key] = false;
          this.SignInErrorsText = '';
          this.onToggleViewErrors(false);
        }
      );
    }
  }

  onSignInRemindPopup($event: Event): void {
    $event.preventDefault();
    this.authService.onAuthRemindPopup(
      this.SignInForm.controls.email.valid ? this.SignInForm.controls.email.value : ''
    );
  }

  onSignInToSignUp($event: Event): void {
    $event.preventDefault();

    this.router.navigateByUrl(
      `/${RoutingConfig.Login}/${RoutingConfig.SignUp}`
    ).catch();
  }

  onCheckInput(key: string): void {
    if (this.SignInForm.controls.hasOwnProperty(key) && this.SignInForm.controls[key].value !== '') {
      this.SignInErrorsField[key] = !this.SignInForm.controls[key].valid;
    }
  }

  onSignInLoading(status: boolean, delay: number = 0) {
    setTimeout(() => {
      this.SignInLoading = status;
      this.cdr.detectChanges();
    }, delay);
  }

  onSubmitForm(): void {
    /**
     * Loading.
     */
    if (this.SignInLoading) {
      return;
    }

    /**
     * Initial validation.
     */
    if (!this.SignInForm.valid) {
      const Controls = this.SignInForm.controls;

      for (const control of Object.keys(Controls)) {
        if (!Controls[control].valid) {
          if (control === 'email') {
            this.SignInErrorsField.email = true;
            this.SignInErrorsText = 'Неправильно введен E-mail!';
            this.onToggleViewErrors(true);
            break;
          }
          if (control === 'password') {
            this.SignInErrorsField.password = true;
            this.SignInErrorsText = 'Неправильно введен пароль!';
            this.onToggleViewErrors(true);
            break;
          }
        }
      }

      return;
    }

    /**
     * SignIn request API.
     */
    this.onSignInLoading(true);
    this.onSignInRequestAPI(<AuthSignInRequest>this.SignInForm.getRawValue());
  }

  onSignInRequestAPI(request: AuthSignInRequest): void {
    this.authService.signIn(request).subscribe(
      response => {
        /**
         * Update tokens value.
         */
        this.authService.onAuthRequestSuccess(response, this.SignInForm.controls.remember.value);

        /**
         * Getting of Account User model.
         */
        this.onSignInAccountUserAPI();
      },
      authError => this.onSignInBaseErrorAPI(authError)
    );
  }

  onSignInAccountUserAPI(): void {
    this.accountService.getMyInfo().subscribe(
      accountResponse => {
        this.accountStory.AccountEventBus.next({
          event: 'onAccountUpdate',
          data: accountResponse
        });

        this.router.navigateByUrl(
          this.route.snapshot.queryParams['redirectUrl'] || RoutingConfig.Root
        ).catch();

        /**
         * Hide loading spinner.
         */
        this.onSignInLoading(false);
      },
      accountError => this.onSignInBaseErrorAPI(accountError)
    );
  }

  onSignInBaseErrorAPI(message: string): void {
    this.SignInErrorsText = message;

    for (const i of Object.keys(this.SignInErrorsField)) {
      this.SignInErrorsField[i] = true;
    }
    this.onToggleViewErrors(true);

    /**
     * Hide loading spinner.
     */
    this.onSignInLoading(false);
  }

  onSignInRouterLink($event: Event, url: string, blank?: boolean): void {
    $event.preventDefault();

    window.open(
      `/${RoutingConfig.Pages}/${url}`, '_blank'
    );
  }
}
