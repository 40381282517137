import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NotificationItemComponent } from './notification-item.component';

/**
 * Pipes
 */
import { PipesCoreModule } from '@app/core/pipes/pipes.module';

/**
 * Vendors
 */
import { ChipModule } from 'primeng/chip';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';

@NgModule({
  declarations: [
    NotificationItemComponent
  ],
  exports: [
    NotificationItemComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    PipesCoreModule,
    ChipModule,
    NgxYoutubePlayerModule
  ]
})

export class NotificationItemModule {
  constructor() {}
}
