import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FundamentalsService } from '@app/modules/fundamentals-modules/fundamentals.service';

@NgModule({
  declarations: [],
  exports: [],
  imports: [
    CommonModule
  ],
  providers: [
    FundamentalsService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})

export class FundamentalsModule {}
