import { AppStaticURL } from '@app/app.settings';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InputValidators } from '@app/core/validators/input.validator';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DevicesClass, DeviceTypes } from '@app/core/classes';
import { BreadcrumbsDataModel } from '@app/core/models/breadcrumbs';
import { RoutingConfig } from '@app/routing/routing.config';
import { AccountService } from '@app/modules/account-modules/account.service';
import { IAccountUserInfo } from '@app/modules/account-modules/account.model';
import { MusicianService } from '@app/modules/musician-modules/musician.service';
import { ReplaySubject } from 'rxjs';
import { CitiesService } from '@app/modules/cities-module/cities.service';
import { InstrumentsService } from '@app/modules/instruments-modules/instruments.service';
import { CityModel, InstrumentsModel, PositionModel } from '@app/modules/fundamentals-modules/fundamentals.models';
import { IMusicianUpdateMyInfo } from '@app/modules/musician-modules/musician.model';
import { DialogIncludesTypes, DialogComponentModel } from '@app/core/models/diallog';
import { DialogService } from 'primeng/dynamicdialog';
import { AccountEventBus, AccountStoryService } from '@app/modules/account-modules/account.story';
import { MessageService } from 'primeng/api';
import { NotificationService } from '@app/modules/notification-modules/notification.service';
import { FileToBase64, ObjectRequestFormatted } from '@app/utils/converter';
import { PhoneNumberValidate, FBLinkValidate, INSTLinkValidate, VKLinkValidate } from '@app/utils/validator';
import { FBRequestFormatted, PhoneRequestFormatted, VKRequestFormatted } from '@app/utils/converter';
import { mergeMap } from 'rxjs/operators';
import { _deviceType } from '@app/utils/device';
import * as moment from 'moment';
import * as $ from 'jquery';

/**
 * Components
 */
import { SearchCityComponent } from '@app/modules/cities-module/search-city/search-city.component';
import { SearchActivityComponent } from '@app/modules/activity-modules/search-activity/search-activity.component';
import { HTML } from 'mdast';

export interface BaseErrorModel {
  status: boolean;
  value: string;
}
export interface ProfileErrorModels {
  username: BaseErrorModel;
  name: BaseErrorModel;
  lastName: BaseErrorModel;
  email: BaseErrorModel;
  birthday: BaseErrorModel;
  cityId: BaseErrorModel;
  instrumentIds: BaseErrorModel;
  contactPhone: BaseErrorModel;
  password: BaseErrorModel;
  vkUsername: BaseErrorModel;
  facebookUsername: BaseErrorModel;
  instagramUsername: BaseErrorModel;
}

@UntilDestroy()
@Component({
  selector: 'app-account-profile',
  templateUrl: 'profile.component.html',
  styleUrls: [`./profile.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ProfileComponent extends DevicesClass implements OnInit {
  public StaticURL = AppStaticURL;
  public BreadcrumbsData: BreadcrumbsDataModel = [
    {
      title: 'Личный кабинет',
      url: '/' + RoutingConfig.Profile
    },
    {
      title: 'Профиль пользователя',
      url: '/' + RoutingConfig.ProfileAccount,
      active: true
    }
  ];

  /**
   * Base component variables.
   */
  public ProfileSubject: ReplaySubject<IAccountUserInfo> = new ReplaySubject<IAccountUserInfo>(1);
  public ProfileForm: FormGroup;
  public ProfileFormModify: boolean;
  public ProfileReconfirmModify: boolean;
  public ProfileLoading: boolean;
  public ProfileAvatar: string;
  public ProfileCitySubject: ReplaySubject<CityModel> = new ReplaySubject<CityModel>(1);
  public ProfileCityValue: CityModel = null;
  public ProfileActivitySubject: ReplaySubject<any> = new ReplaySubject<any>(1);
  public ProfileActivityPosition: PositionModel = null;
  public ProfileActivityInstruments: InstrumentsModel = [];

  /**
   * Errors view variables
   */
  public ErrorsField: ProfileErrorModels;
  public ErrorsText: string;

  /**
   * Special component variables.
   */
  public fileAcceptString = 'image/x-png,image/gif,image/jpeg';

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    private router: Router,
    private formBuilder: FormBuilder,
    private inputValidators: InputValidators,
    private accountService: AccountService,
    private musicianService: MusicianService,
    private dialogService: DialogService,
    private citiesService: CitiesService,
    private instrumentsService: InstrumentsService,
    private accountStory: AccountStoryService,
    private messageService: MessageService,
    private notificationService: NotificationService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    /**
     * Build of profile form.
     */
    this.ProfileForm = this.formBuilder.group({
      id : ['', Validators.required],
      name : ['', Validators.required],
      lastName : [''],
      username : ['', Validators.required],
      email : ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])],
      positionId : [0, Validators.required],
      picPath : [''],
      cityId : [0, Validators.required],
      emailConfirmed : [true],
      instrumentIds : [[], Validators.required],
      birthday : [''],
      contacts: this.formBuilder.group({
        vkUsername: ['', {validators: [this.inputValidators.checkVKValue]}],
        facebookUsername: ['', {validators: [this.inputValidators.checkFBValue]}],
        instagramUsername: ['', {validators: [this.inputValidators.checkINSTValue]}],
        contactPhone: [null],
        hasTelegram: [false],
        hasViber: [false],
        hasWhatsapp: [false],
      })
    });

    /**
     * Set of default errors value.
     */
    this.ErrorsField = {
      username: {status: false, value: ''},
      name: {status: false, value: ''},
      lastName: {status: false, value: ''},
      email: {status: false, value: ''},
      birthday: {status: false, value: ''},
      cityId: {status: false, value: ''},
      instrumentIds: {status: false, value: ''},
      contactPhone: {status: false, value: 'Неправильный формат телефона'},
      password: {status: false, value: ''},
      vkUsername: {status: false, value: 'Неправильный формат ссылки'},
      facebookUsername: {status: false, value: 'Неправильный формат ссылки'},
      instagramUsername: {status: false, value: 'Неправильный формат ссылки'}
    };

    /**
     * Get of profile data.
     */
    this.onAccountStoryBus();

    /**
     * Form changes control.
     */
    this.onProfileFormCtrl();
    this.onCityCtrl();
    this.onActivityCtrl();
  }

  onAccountStoryBus(): void {
    this.accountStory.AccountEventBus$.pipe(untilDestroyed(this)).subscribe(
      emit => this.onAccountStoryListener(emit)
    );
  }

  onAccountStoryListener(emit: AccountEventBus): void {
    if (emit.event === 'onAccountUpdate') {
      this.onGetMusicianUserInfo();
      this.onSetProfileData(emit.data);
    }

    if (emit.event === 'onAccountLogout' || emit.event === 'onAccountEmpty') {
      this.router.navigateByUrl(`/${RoutingConfig.Login}/${RoutingConfig.SignIn}?redirectUrl=${location.pathname}`).catch();
    }
  }

  onSetProfileData(response): void {
    this.ProfileSubject.next(response);

    for (const key of Object.keys(response)) {
      switch (key) {
        case ('cityId') :
          this.onCityApply(response[key]);
        break;

        case ('picPath') :
          this.ProfileAvatar = response[key] ? AppStaticURL + response[key] : null;
          this.ProfileForm.controls[key].setValue(response[key], { emitEvent: false });
        break;

        case ('contacts') :
          for (const contact of Object.keys(response[key])) {
            if (this.onProfileContactsFormControl[contact]) {
              if (!response[key][contact]) {
                continue;
              }

              if (contact === 'contactPhone') {
                if (Number(response[key][contact]) === 0) {
                  continue;
                }
                this.onProfileContactsFormControl[contact].setValue(
                  '7' + response[key][contact], { emitEvent: false }
                );
              }

              if (contact === 'vkUsername') {
                this.onProfileContactsFormControl[contact].setValue(
                  'vk.com/' + response[key][contact], { emitEvent: false }
                );
                // https://
              }

              if (contact === 'facebookUsername') {
                this.onProfileContactsFormControl[contact].setValue(
                  'fb.com/' + response[key][contact], { emitEvent: false }
                );
              }

              if (contact === 'instagramUsername') {
                this.onProfileContactsFormControl[contact].setValue(
                  response[key][contact], { emitEvent: false }
                );
              }

              if (contact === 'hasTelegram') {
                this.onProfileContactsFormControl[contact].setValue(
                  response[key][contact], { emitEvent: false }
                );
              }

              if (contact === 'hasViber') {
                this.onProfileContactsFormControl[contact].setValue(
                  response[key][contact], { emitEvent: false }
                );
              }

              if (contact === 'hasWhatsapp') {
                this.onProfileContactsFormControl[contact].setValue(
                  response[key][contact], { emitEvent: false }
                );
              }
            }
          }
          break;
        default:
          if (this.ProfileForm.controls.hasOwnProperty(key)) {
            if (key === 'birthday') {
              this.onSetBirthdayValue(response[key], true);

            } else {
              this.ProfileForm.controls[key].setValue(response[key], { emitEvent: false });
            }
          }
      }
    }
  }

  onGetMusicianUserInfo(): void {
    this.musicianService.musicianUserInfo().subscribe(
      response => {
        this.onActivityApply(response.positionId);
        this.onInstrumentsApply(response.instrumentIds);
      }
    );
  }

  onProfileLoading(status: boolean, delay: number = 0) {
    setTimeout(() => {
      this.ProfileLoading = status;
      this.cdr.detectChanges();
    }, delay);
  }

  onReconfirmProfile(): void {
    if (this.ProfileReconfirmModify) {
      return;
    }

    this.accountService.resendEmail().subscribe(
      r => {
        this.notificationService.onDialog({
          type: DialogIncludesTypes.PopupSuccessTemplate,
          header: 'Подтверждение профиля',
          content: 'Мы отправили Вам на почту, указанную в профиле, новое письмо для подтверждения учетной записи.'
        });
        this.ProfileReconfirmModify = true;
        this.cdr.markForCheck();
      },
      e => {
        this.notificationService.onDialog({
          type: DialogIncludesTypes.PopupSuccessTemplate,
          header: 'Подтверждение профиля',
          content: 'Не удалось отправить повторно письмо подтверждения. Попробуйте повторить позднее.'
        });
      }
    )
  }

  onProfileFormCtrl(): void {
    for (const key of Object.keys(this.ErrorsField)) {
      if (this.ProfileForm.controls.hasOwnProperty(key)) {
        this.ProfileForm.controls[key].valueChanges.pipe(untilDestroyed(this)).subscribe(
          value => {
            this.ErrorsField[key].status = false;
            this.ProfileFormModify = true;
            this.ErrorsText = '';

            this.onToggleViewErrors(false);
          }
        );
      }
      if (this.onProfileContactsFormControl.hasOwnProperty(key)) {
        this.onProfileContactsFormControl[key].valueChanges.pipe(untilDestroyed(this)).subscribe(
          value => {
            this.ErrorsField[key].status = false;
            this.ProfileFormModify = true;
            this.ErrorsText = '';

            this.onToggleViewErrors(false);
          }
        )
      }
    }
  }

  onCityCtrl(): void {
    this.ProfileCitySubject.pipe(untilDestroyed(this)).subscribe(
      value => {
        this.ProfileCityValue = value;
        this.ProfileForm.controls.cityId.setValue(value.id);

        this.cdr.markForCheck();
      }
    );
  }

  onCityApply(id: number): void {
    const CiyValue = this.citiesService.onGetCityByDictionary(id) || null;

    if (CiyValue) {
      this.ProfileCityValue = CiyValue;
      this.ProfileForm.controls.cityId.setValue(CiyValue.id, { emitEvent: false });
    }
  }

  onInstrumentsApply(ids: Array<number>): void {
    ids.map(id => {
      const InstrumentValue = this.instrumentsService.onGetInstrumentByDictionary(id);

      this.ProfileActivityInstruments.push(InstrumentValue);
      this.cdr.markForCheck();
    });

    this.ProfileForm.controls.instrumentIds.setValue(ids, { emitEvent: false });
  }

  onActivityCtrl(): void {
    this.ProfileActivitySubject.pipe(untilDestroyed(this)).subscribe(
      value => {
        this.ProfileActivityPosition = value.position;
        this.ProfileForm.controls.positionId.setValue(value.position.id);

        this.ProfileActivityInstruments = value.instruments;
        this.ProfileForm.controls.instrumentIds.setValue(value.instruments.map(item => item.id));

        this.cdr.markForCheck();
      }
    );
  }

  onActivityApply(id: number): void {
    this.ProfileForm.controls.positionId.setValue(id, { emitEvent: false });
  }

  onUploadAvatarHandler(): void {
    const UploadInput = document.getElementById('avatarUpload');
    UploadInput.click();
  }

  onUploadAvatarEvent(files: FileList): void {
    for (const i of Object.keys(files)) {
      const File: File = files[i];

      if ((File.size / 1024) > 1024) {
        return this.notificationService.onDialog({
          type: DialogIncludesTypes.PopupErrorTemplate,
          header: 'Ошибка загрузки',
          content: 'Превышен размер загружаемого файла. Ограничение на загрузку - 1Mb.',
        });
      }

      this.accountService.uploadAvatar(files).subscribe(
        response => {
          FileToBase64(File).then((value: string) => {
            this.ProfileAvatar = value;
            this.cdr.markForCheck();
          })
        },
        error => {
          this.notificationService.onDialog({
            type: DialogIncludesTypes.PopupErrorTemplate,
            header: 'Ошибка загрузки',
            content: 'Не удалось загрузить аватар профиля. Попробуйте использовать другой файл.',
          });
        }
      );
    }
  }

  onUploadAvatarClear(): void {
    this.ProfileForm.controls['picPath'].setValue('');
    this.ProfileAvatar = null;
    this.cdr.markForCheck();
  }

  onAvatarError() {
    this.ProfileAvatar = null;
    this.cdr.markForCheck();
  }

  onCheckPhone(): void {
    this.ErrorsField.contactPhone.status = !this.onProfileContactPhoneValid;
  }

  onCheckFormattedDate(): void {
    const birthday = this.ProfileForm.controls.birthday.value || '';

    if (birthday === '') {
      return;
    }
    if (
      !moment(birthday, 'DD.MM.YYYY').isValid() ||
      moment(birthday, 'DD.MM.YYYY').year() > 2020 ||
      birthday.split('.').join('').length < 8
    ) {
      this.ErrorsField.birthday = {
        status: true,
        value: 'Неправильный формат даты'
      };
      this.cdr.detectChanges();
    }
  }

  onMaskPhone($event: any, backspace?: boolean): void {
    const contactsCtrl = <any>this.ProfileForm.controls['contacts'];
    const contactPhone: string = contactsCtrl.controls.contactPhone.value || null;

    if (!contactPhone || contactPhone === '') {
      return;
    }
    if (contactPhone.split('').length === 1) {
      if ($event.inputType === 'deleteContentBackward') {
        contactsCtrl.controls.contactPhone.setValue(contactPhone.split('').slice(1).join(''));
      } else {
        contactsCtrl.controls.contactPhone.setValue('7' + contactPhone);
      }
    }
  }

  onCheckSocial(type: string): void {
    if (type === 'vkUsername') {
      const ValueVK = this.onProfileContactsFormControl[type].value;

      if (ValueVK && ValueVK !== '') {
        this.ErrorsField[type].status = !VKLinkValidate(ValueVK)
      }
    }


    if (type === 'facebookUsername') {
      const ValueFB = this.onProfileContactsFormControl[type].value;

      if (ValueFB && ValueFB !== '') {
        this.ErrorsField[type].status = !FBLinkValidate(ValueFB);
      }
    }


    if (type === 'instagramUsername') {
      const ValueINST = this.onProfileContactsFormControl[type].value;

      if (ValueINST && ValueINST !== '') {
        this.ErrorsField[type].status = !INSTLinkValidate(ValueINST);
      }
    }
  }

  onGetCurrentDate(): Date {
    return new Date();
  }

  onSetBirthdayValue(value: string, formatted?: boolean): void {
    this.ProfileForm.controls.birthday.setValue(
      formatted ? moment(value).format('DD.MM.YYYY') : value
    );
  }

  onSetMessengerValue(key: string): void {
    if (!this.onProfileMessengerValid) {
      return;
    }
    const MessengerValue = this.onProfileContactsFormControl[key].value;

    this.onProfileContactsFormControl[key].patchValue(!MessengerValue);
  }

  onGetMessengerStatus(key: string): boolean {
    return this.onProfileContactsFormControl[key].value
  }

  onViewSearchCities(): void {
    this.dialogService.open(SearchCityComponent, {
      header: 'Выбрать город',
      width: _deviceType() === DeviceTypes.Mobile ? '90%' : '100%',
      styleClass: 'p-dialog__city-search',
      data: <DialogComponentModel>{
        includeType: DialogIncludesTypes.ComponentTemplate,
        includeData: {
          dataInitial: this.ProfileCityValue,
          dataSubject: this.ProfileCitySubject
        }
      }
    })
  }

  onViewSearchActivity(): void {
    this.dialogService.open(SearchActivityComponent, {
      header: 'Выбрать инструмент и должность',
      width: _deviceType() === DeviceTypes.Mobile ? '90%' : '100%',
      styleClass: 'p-dialog__activity-search',
      data: <DialogComponentModel>{
        includeType: DialogIncludesTypes.ComponentTemplate,
        includeData: {
          dataInitial: {
            position: this.ProfileActivityPosition,
            instruments: this.ProfileActivityInstruments
          },
          dataSubject: this.ProfileActivitySubject
        }
      }
    })
  }

  onToggleViewErrors(show: boolean): void {
    const FieldErrors = $('.__profile__errors');
    show ? FieldErrors.slideDown(150) : FieldErrors.slideUp(150);
  }

  onGetFormattedDate(date: string): string {
    return date ? moment(date, 'DD.MM.YYYY').toISOString() : ''
  }

  onSaveProfile(): void {
    if (!this.ProfileFormModify || this.ProfileLoading) {
      return;
    }

    /**
     * Validation of Errors Fields.
     */
    for (const field of Object.keys(this.ErrorsField)) {
      if (this.ErrorsField[field].status) {
        this.ErrorsText = 'Проверьте правильность заполненных полей!';
        return this.onToggleViewErrors(true);
      }
    }

    /**
     * Initial validation.
     */
    if (!this.ProfileForm.valid) {
      for (const key of Object.keys(this.ProfileForm.controls)) {
        if (this.ErrorsField.hasOwnProperty(key)) {
          this.ErrorsField[key].status = !this.ProfileForm.controls[key].valid;

          if (key === 'cityId' && this.ProfileForm.controls[key].value === 0) {
            this.ErrorsField[key].status = true;
          }
        }
      }
      this.onProfileLoading(false);

      /**
       * View errors description.
       */
      this.ErrorsText = 'Обязательные поля не могут быть пустыми!';
      return this.onToggleViewErrors(true);
    }

    /**
     * Social Link validation.
     */
    if (
      this.ErrorsField.vkUsername.status ||
      this.ErrorsField.facebookUsername.status ||
      this.ErrorsField.instagramUsername.status
    ) {
      this.onProfileLoading(false);

      /**
       * View errors description.
       */
      this.ErrorsText = 'Проверьте правильность ссылок на социальные сети!';
      return this.onToggleViewErrors(true);
    }

    /**
     * Account raw value.
     */
    const AccountRawForm = <IAccountUserInfo>this.ProfileForm.getRawValue();


    /**
     * Collection data of account profile.
     */
    const AccountUserInfo = <IAccountUserInfo>{
      id: AccountRawForm.id,
      name: AccountRawForm.name,
      lastName: AccountRawForm.lastName,
      username: AccountRawForm.username,
      email: AccountRawForm.email,
      picPath: AccountRawForm.picPath,
      cityId: AccountRawForm.cityId,
      emailConfirmed: AccountRawForm.emailConfirmed,
      birthday: this.onGetFormattedDate(AccountRawForm.birthday),
      contacts: {
        vkUsername: VKRequestFormatted(AccountRawForm.contacts.vkUsername),
        facebookUsername: FBRequestFormatted(AccountRawForm.contacts.facebookUsername),
        instagramUsername: AccountRawForm.contacts.instagramUsername,
        contactPhone: PhoneRequestFormatted(AccountRawForm.contacts.contactPhone),
        hasTelegram: AccountRawForm.contacts.hasTelegram,
        hasViber: AccountRawForm.contacts.hasViber,
        hasWhatsapp: AccountRawForm.contacts.hasWhatsapp
      }
    };
    const MusicianUserInfo = <IMusicianUpdateMyInfo>{
      instrumentIds: this.ProfileForm.getRawValue().instrumentIds,
      positionId: this.ProfileForm.getRawValue().positionId
    };

    /**
     * Loading.
     */
    this.onProfileLoading(true);

    /**
     * Update of account profile.
     */
    this.accountService.updateMyInfo(ObjectRequestFormatted(AccountUserInfo))
      .pipe(
        mergeMap(_ => {
          return this.musicianService.updateMyInfo(MusicianUserInfo)
        })
      ).subscribe(
      response => {
        /**
         * Reset loading spinner.
         */
        this.onProfileLoading(false);

        this.notificationService.onDialog({
          type: DialogIncludesTypes.PopupSuccessTemplate,
          header: 'Обновление профиля',
          content: 'Данные Вашего профиля успешно обновлены.',
          icons: 'pi pi-user-edit'
        });

        /**
         * Reset button state.
         */
        this.ProfileFormModify = false;

        /**
         * Update of profile data.
         */
        this.accountService.onIniGetMyInfo();
      },
      error => {
        /**
         * Reset loading spinner.
         */
        this.onProfileLoading(false);

        this.notificationService.onDialog({
          type: DialogIncludesTypes.PopupErrorTemplate,
          header: 'Ошибка',
          content: 'Не удалось обновить Ваш профиль',
        });

        /**
         * Reset button state.
         */
        this.ProfileFormModify = false;
      }
    );
  }

  get onProfileContactPhoneValid(): boolean {
    const contactsCtrl = <any>this.ProfileForm.controls['contacts'];
    const contactPhone: string = contactsCtrl.controls.contactPhone.value || '';

    return PhoneNumberValidate(contactPhone);
  }

  get onProfileMessengerValid(): boolean {
    const contactsCtrl = <any>this.ProfileForm.controls['contacts'];
    const contactPhone: string = contactsCtrl.controls.contactPhone.value || '';

    return contactPhone !== '' && PhoneNumberValidate(contactPhone);
  }

  get onProfileFormControl(): any {
    return this.ProfileForm.controls;
  }

  get onProfileContactsFormControl(): any {
    return this.onProfileFormControl['contacts'].controls;
  }
}
