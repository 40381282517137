<div class="pg-wrapper">
  <div class="sign-success__content">
    <div class="grid align-items-center"
      ngClass="{{(deviceValue | async) === DTypes.Mobile || (deviceValue | async) === DTypes.Tablet ? 'flex-column' : ''}}">

      <div class="col-7 col-md-12 col-sm-12 sign-success__description"
        ngClass="{{(deviceValue | async) === DTypes.Mobile || (deviceValue | async) === DTypes.Tablet ? 'order-1' : ''}}">

        <div class="row"
          ngClass="{{(deviceValue | async) === DTypes.Mobile || (deviceValue | async) === DTypes.Tablet ? 'text-center mt-4' : ''}}">

          <div class="col-12 col-sm-12">
            <h1>Спасибо за регистрацию</h1>
          </div>
        </div>

        <div class="row"
          ngClass="{{(deviceValue | async) === DTypes.Mobile || (deviceValue | async) === DTypes.Tablet ? 'text-center' : ''}}">
          <div class="col-8 col-md-8 col-sm-12"
            ngClass="{{(deviceValue | async) === DTypes.Tablet ? 'margin-auto' : ''}}">

            <p>Уважаемый пользователь, на вашу почту отправлено письмо для потдверждения регистрации. А пока можете перейти в свой личный кабинет.</p>
          </div>
        </div>

        <div class="row"
          ngClass="{{(deviceValue | async) === DTypes.Mobile || (deviceValue | async) === DTypes.Tablet ? 'text-center' : ''}}">

          <div class="col-4 col-md-5 col-sm-12"
            ngClass="{{(deviceValue | async) === DTypes.Tablet ? 'margin-auto' : ''}}">

            <button class="ui-buttons ui-buttons__blue-large no-shadow" (click)="onProfileLink()" pRipple>
              Личный кабинет
            </button>
          </div>
        </div>
      </div>
      <div class="col-5 col-md-12 col-sm-12"
        ngClass="{{(deviceValue | async) === DTypes.Mobile || (deviceValue | async) === DTypes.Tablet ? 'order-0' : ''}}">

        <div class="sign-success__img" ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-6' : ''}}">
          <img src="assets/images/template/success.png">
        </div>
      </div>
    </div>
  </div>
</div>
