import { Pipe, PipeTransform } from '@angular/core';
import { IBulletinDetail } from '@app/modules/bulletins-modules/bulletins.model';

@Pipe({
  name: 'bulletinAge'
})

export class BulletinAgePipe implements PipeTransform {
  transform(bulletin: IBulletinDetail): string {
    if (
      !bulletin.bandRequestViewModel.ageFrom &&
      !bulletin.bandRequestViewModel.ageTo
    ) {
      return 'Не важно';
    }

    return `от ${bulletin.bandRequestViewModel.ageFrom || '--'} до ${bulletin.bandRequestViewModel.ageTo || '--'}`;
  }
}
