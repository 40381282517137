import { Component, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TokenService } from '@app/core/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@app/modules/auth-modules/auth.service';

export type AuthEventBusTypes =
  'onAuthSignIn'    |
  'onAuthLogout'    |
  'onAuthNeed'      |
  'onAuthConfirmed' |
  'onOAuthSignIn'   |
  'onOAuthSignUp'   |
  'onOAuthSignError';

export type AuthEventBusData = any;

export interface AuthEventBus {
  event: AuthEventBusTypes;
  data?: AuthEventBusData;
}

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})

export class AuthStoryService {
  public AuthEventBus: Subject<AuthEventBus> = new Subject<AuthEventBus>();
  public AuthEventBus$ = this.AuthEventBus.asObservable();

  constructor(
    private tokenService: TokenService,
    private authService: AuthService
  ) {
    this.AuthEventBus$.pipe(untilDestroyed(this)).subscribe(
      emit => this.onAuthStoryListener(emit)
    );
  }

  onAuthStoryListener(emit: AuthEventBus): void {
    if (emit.event === 'onAuthLogout') {
      /**
       * Clear of Access Token.
       */
      this.tokenService.onAccessTokenClear();

      /**
       * Clear of Refresh Token.
       */
      this.tokenService.onRefreshTokenClear();

      /**
       * Clear of Expires Token.
       */
      this.tokenService.onExpiresClear();
    }

    if (emit.event === 'onAuthNeed') {
      this.authService.onAuthNeedPopup(<string>emit.data);
    }

    if (emit.event === 'onAuthConfirmed') {
      this.authService.onAuthConfirmedPopup(<string>emit.data);
    }
  }
}
