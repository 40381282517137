import { Pipe, PipeTransform } from '@angular/core';
import { IBulletinDetail } from '@app/modules/bulletins-modules/bulletins.model';

@Pipe({
  name: 'bulletinPerformer'
})

export class BulletinPerformerPipe implements PipeTransform {
  transform(bulletin: IBulletinDetail): string {
    if (bulletin.type === 'ArtistSearchBand') {
      return bulletin.musicianViewModel.name;
    }

    if (bulletin.type === 'BandSearchArtist') {
      return bulletin.performer;
    }
  }
}
