import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { URLLinkValidate, YTLinkValidate, FBLinkValidate, INSTLinkValidate, VKLinkValidate } from '@app/utils/validator';


@Injectable()

export class InputValidators {
  constructor() {
  }

  checkIdValue(control: AbstractControl): any {
    if (control.value === 0) {
      return { valid: false };
    }
  }

  checkURLValue(control: AbstractControl): any {
    if (control.value !== '' && !URLLinkValidate(control.value)) {
      return { valid: false }
    }
  }

  checkYTValue(control: AbstractControl): any {
    if (control.value !== '' && !YTLinkValidate(control.value)) {
      return { valid: false }
    }
  }

  checkVKValue(control: AbstractControl): any {
    if (control.value !== '' && !VKLinkValidate(control.value)) {
      return { valid: false }
    }
  }

  checkFBValue(control: AbstractControl): any {
    if (control.value !== '' && !FBLinkValidate(control.value)) {
      return { valid: false }
    }
  }

  checkINSTValue(control: AbstractControl): any {
    if (control.value !== '' && !INSTLinkValidate(control.value)) {
      return { valid: false }
    }
  }
}
