import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationService } from '@app/modules/notification-modules/notification.service';

/**
 * Components
 */
import { NotificationDialogComponent } from './notification-dialog/notification-dialog.component';
import { NotificationPushComponent } from './notification-push/notification-push.component';

@NgModule({
  declarations: [
    NotificationDialogComponent,
    NotificationPushComponent
  ],
  exports: [
    NotificationDialogComponent,
    NotificationPushComponent
  ],
  entryComponents: [
    NotificationDialogComponent,
    NotificationPushComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [
    NotificationService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})

export class NotificationModule {}
