import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { IAccountUserInfo } from './account.model';

export type AccountEventBusTypes = 'onAccountUpdate' | 'onAccountEmpty' | 'onAccountLogout';
export interface AccountEventBus {
  event: AccountEventBusTypes;
  data?: IAccountUserInfo;
}

@Injectable({
  providedIn: 'root'
})

export class AccountStoryService {
  public AccountEventBus: ReplaySubject<AccountEventBus> = new ReplaySubject<AccountEventBus>(1);
  public AccountEventBus$ = this.AccountEventBus.asObservable();
}
