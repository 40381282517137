import { environment } from '../environments/environment';

/**
 * Prefix of current application.
 */
export const AppPrefix = 'MR';

/**
 * Path of static sources/
 */
export const AppHeading = {
  'ru-RU': '',
  'en-US': ''
};

export const AppPROD = environment.production;
export const AppApiURL = environment.apiUrl;
export const AppStaticURL = environment.staticUrl;
export const AppDomainURL = environment.domainUrl;
