import { SeoPageModel } from '@app/core/services/seo/seo.model';

export const SignInSeoParams = <SeoPageModel>{
  title: 'Авторизация',
  description: 'Сервис по поиску музыкантов No1 в России и на Луне',
  image: '',
  url: '',
};

export const SignRemindSeoParams = <SeoPageModel>{
  title: 'Восстановление пароля',
  description: 'Сервис по поиску музыкантов No1 в России и на Луне',
  image: '',
  url: '',
};

export const SignUpSeoParams = <SeoPageModel>{
  title: 'Регистрация',
  description: 'Сервис по поиску музыкантов No1 в России и на Луне',
  image: '',
  url: '',
};
