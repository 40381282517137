<div class="notification-item__list" *ngIf="notificationType === NITypes.MusicianSearchAccepted"
  ngClass="{{NotificationItem.isRead ? '' : 'unread'}}" (mouseover)="onSetReadNotification()">

  <div class="notification-item__list-content">
    <a class="notification-item__list-heading"
      routerLink="/{{NIRouting.Bulletins}}/{{NIRouting.Detail}}/{{NotificationItem.itemId}}"
      target="{{onViewDetailNotification()}}"
      ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'pb-2' : 'pb-2'}}">

      <h5>Уведомление</h5>
    </a>

    <div class="notification-item__list-row">
      <p class="pt-1 pb-3">{{NotificationItem.message}}</p>
    </div>

    <div class="notification-item__list-row">
      <div class="col-auto notification-item__list-event__date">
        <span class="mr-icon mr-icon-clock"></span>
        <span>{{NotificationItem.created | formatDate}}</span>
      </div>
    </div>

  </div>
  <div class="grid align-items-center justify-content-center flex-column notification-item__list-event">
    <div class="col-auto">
      <div class="grid flex-column justify-content-center align-items-center">
        <div class="notification-item__percent">
          <div class="grid align-items-center justify-content-center">
            <div class="col-6 text-center">
              <span class="mr-icon mr-icon-bell"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="notification-item__list" *ngIf="notificationType === NITypes.MusicianSearchMatch"
  ngClass="{{NotificationItem.isRead ? '' : 'unread'}}" (mouseover)="onSetReadNotification()">

  <div class="notification-item__list-content">
    <a class="notification-item__list-heading"
      routerLink="/{{NIRouting.Bulletins}}/{{NIRouting.Detail}}/{{NotificationItem.itemId}}"
      target="{{onViewDetailNotification()}}"
      ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'pb-2' : 'pb-2'}}">

      <h5>УМНЫЙ ПОИСК</h5>
      <p class="pt-3 pb-1">{{NotificationItem.message}}</p>
    </a>

    <div class="notification-item__list-row">
      <div class="col-auto notification-item__list-event__date">
        <span class="mr-icon mr-icon-clock"></span>
        <span>{{NotificationItem.created | formatDate}}</span>
      </div>
    </div>

  </div>
  <div class="grid align-items-center justify-content-center flex-column notification-item__list-event">
    <div class="col-auto">
      <div class="grid flex-column justify-content-center align-items-center">
        <div class="notification-item__percent">
          <div class="grid align-items-start">
            <div class="col-6">
              <p class="percent-regular">совпадение</p>
              <p class="percent-regular">на</p>
              <p class="percent-line"></p>
            </div>
            <div class="col-6">
              <p class="percent-bold">
                {{NotificationItem?.additionalInfo?.percentMatch}}%
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
